import { css, SerializedStyles } from '@emotion/react';
import { ButtonSize } from '../button-size';
import styled from '@emotion/styled';
import Button from '../button-component';
import { ITheme } from 'apps/ui-components/src/types';
import {
    gapSet,
    paddingForIconSet,
    paddingForTextSet,
    sizeExtendSet,
} from './icon-button-config';
import { IconButtonMode } from './icon-button-types';
import { ButtonTextNormal } from '../../typograph/typograph-components';

export const IconLabelText = styled(ButtonTextNormal)`
    line-height: inherit;
    color: inherit;
`;

export interface IStyledIconButton {
    small?: boolean;
    large?: boolean;
    isRound?: boolean;
    mode: IconButtonMode;
    size: ButtonSize;
}

export interface IStyledIconButtonExtend extends IStyledIconButton {
    theme?: ITheme;
}

const SquareMode = () => css`
    aspect-ratio: 1/1;
`;

const RectangelMode = ({ size }: IStyledIconButtonExtend) => css`
    width: auto;

    gap: ${gapSet[size]}px;

    &:has(p + *) {
        padding-left: ${paddingForTextSet[size]}px;
        padding-right: ${paddingForIconSet[size]}px;
    }

    &:has(* + p) {
        padding-right: ${paddingForTextSet[size]}px;
        padding-left: ${paddingForIconSet[size]}px;
    }
`;

const RoundedMode = ({}: IStyledIconButtonExtend) => css`
    aspect-ratio: 1;
    border-radius: 50%;
`;

const modeSet: Record<
    IconButtonMode,
    (data: IStyledIconButtonExtend) => string | SerializedStyles
> = {
    [IconButtonMode.square]: SquareMode,
    [IconButtonMode.rectangel]: RectangelMode,
    [IconButtonMode.rounded]: RoundedMode,
};

export const StyledIconButton = styled(Button)<IStyledIconButton>`
    padding: 0;
    font-size: 16px;
    height: ${({ mode, size }) => sizeExtendSet[mode][size]}px;
    /* Bad fix for remove anywhere width fix */
    /* width: auto !important; */

    border-radius: ${({ theme }) => theme.borderRadius.medium};

    ${({ mode }) => modeSet[mode]}
    svg {
        height: 20px;
        aspect-ratio: 1/1;
    }
`;
