import styled from '@emotion/styled';
import clsx from 'clsx';
import { Button } from '../..';
import { ReactNode } from 'react';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import { OverrideButton } from './override-component';
import { isScreen } from './utilities';
import { css } from '@emotion/react';

const CopperBtn = styled(Button)`
    height: auto;
    padding: 10px 10px 10px 7px;
    justify-content: flex-start;
    align-items: center;
    gap: 2px;
    color: ${({ theme }) => theme.colors.textSecondary};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    &.active {
        color: ${({ theme }) => theme.colors.primaryColor};
        background: ${({ theme }) => theme.colors.primaryColor10};
    }

    &:hover {
        text-decoration: none;
    }
`;

const CropperContainer = styled.section`
    display: flex;
    gap: 30px;

    overflow-x: auto;
    padding: 10px 0;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 30px;

        padding: 0;
    }
`;

export interface ICrop {
    label: string;
    value: string;
    icon?: ReactNode;
}

export interface IImageCropProps {
    listCrop: ICrop[];
    crop?: ICrop;
    setCrop: (crop: ICrop) => void;
}

function ImageCrop({ crop, listCrop, setCrop }: IImageCropProps) {
    const screen = useCurrentBreakpoint();
    return (
        <CropperContainer>
            {listCrop.map(v =>
                isScreen(['l', 'xl'], screen) ? (
                    <CopperBtn
                        buttonType={'text'}
                        key={v.value}
                        className={clsx({ active: v.value === crop?.value })}
                        onClick={() => {
                            setCrop(v);
                        }}
                    >
                        {v.icon}
                        {v.label}
                    </CopperBtn>
                ) : (
                    <OverrideButton
                        buttonType={
                            v.value === crop?.value ? 'primary_light' : 'text'
                        }
                        border={v.value === crop?.value}
                        key={v.value}
                        css={css`
                            padding-left: 7px;
                            padding-right: 10px;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 2px;
                        `}
                        onClick={() => {
                            setCrop(v);
                        }}
                    >
                        {v.icon}
                        {v.label}
                    </OverrideButton>
                )
            )}
        </CropperContainer>
    );
}

export default ImageCrop;
