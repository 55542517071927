import PropTypes from 'prop-types';
import { components } from 'react-select';
import { useTranslate, useIsMobile } from '@mspecs/shared-utils';

const ValueContainer = props => {
    const { children, selectProps } = props;
    const { value, elementWidth } = selectProps;
    const { t } = useTranslate();
    const isSmall = useIsMobile();
    const valueLength = value && value.length;
    let selectedValue;

    const measureTextWidth = (text, font = '14px Inter') => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        return {
            width: metrics.width,
            avgTextWidth: metrics.width / text.length,
        };
    };

    if (valueLength > 1) {
        const totalText = value
            .filter(Boolean)
            .map(x => x && t(x.label))
            .join(', ');
        const textMeasurement = measureTextWidth(totalText);
        const selectTextareaWidth = elementWidth - 55;
        if (selectTextareaWidth > textMeasurement.width) {
            selectedValue = totalText;
        } else {
            const val = selectTextareaWidth / textMeasurement.avgTextWidth;
            const charCount = Math.floor(val - (isSmall ? 13 : 9));
            const dispalyText = totalText.slice(0, charCount).trim();
            const numberOfElements = dispalyText.split(', ');
            const hideElementCount = valueLength - numberOfElements.length;
            selectedValue = `${dispalyText}... ${
                hideElementCount > 0 ? `(+${hideElementCount})` : ''
            }`;
        }
    } else {
        selectedValue = children[0];
    }

    return (
        <components.ValueContainer {...props}>
            {selectedValue}
            {children[1]}
        </components.ValueContainer>
    );
};

ValueContainer.propTypes = {
    children: PropTypes.node,
    selectProps: PropTypes.object,
};

export default ValueContainer;
