import {
    and,
    formatIs,
    isStringControl,
    optionIs,
    or,
    rankWith,
} from '@jsonforms/core';
import { EmailInput } from '../../../input';
import { getCellProps, withInputControlHoc } from './cell-utils';

function EmailCell(props: ICellRendererProps) {
    const { path, handleChange } = props;

    const cellProps = getCellProps(props);
    const baseProps = {
        ...cellProps,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(path, e.target.value);
        },
        stackedLabel: true,
        errorMessage: cellProps.additionalErrors,
        invalid: !!cellProps.additionalErrors,
    };

    return <EmailInput {...baseProps} />;
}

export const tester = rankWith(
    3,
    and(isStringControl, or(formatIs('email'), optionIs('format', 'email')))
);

export default { renderer: withInputControlHoc(EmailCell), tester };
