import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { HeightTransition } from '../../animations';
import { MenuItemPropType } from '../utils';
import { useElementSize } from '@mspecs/shared-utils';
import { HighlightedBodyTextBold } from '../../typograph/typograph-components';
import { StatusButton } from '../../button/status-button';
import { StatusType } from '../../button/status-button/status-default-button';
import { getCheckedItems, getItemStatus } from './helpers';

const itemCss = theme => css`
    display: flex;
    align-items: center;
    height: 42px;
    padding: 16px 10px 16px 14px;
    gap: 15px;
    width: 100%;
    cursor: pointer;
`;

const MainItemNoLink = styled(Flex)`
    ${({ theme }) => itemCss(theme)}
`;

const NavItem = styled(NavLink)`
    ${({ theme }) => itemCss(theme)}

    &:hover {
        text-decoration: none;
        p,
        svg:not([data-icon='check']) {
            color: ${({ theme }) => theme.colors.textPrimary};
        }
    }
    :focus {
        box-shadow: none;
    }
`;

const MainItem = styled(Flex, {
    shouldForwardProp: prop => !['isExpanded'].includes(prop),
})`
    background: ${({ theme, isExpanded }) =>
        isExpanded && theme.colors.primaryColor5};

    p,
    svg:not([data-icon='check']) {
        color: ${({ theme, isExpanded }) =>
            isExpanded && theme.colors.textPrimary};
    }
    &:hover {
        background: ${({ theme }) => theme.colors.primaryColor15};
        p,
        svg:not([data-icon='check']) {
            color: ${({ theme }) => theme.colors.textPrimary};
        }
    }
`;

const SubItem = styled(NavItem, {
    shouldForwardProp: prop => !['isActive'].includes(prop),
})`
    background-color: ${({ theme: { colors }, isActive }) =>
        isActive ? colors.primaryColor15 : colors.primaryColor10};
    p {
        color: ${({ theme, isActive }) => isActive && theme.colors.textPrimary};
    }
`;

const MenuItem = styled(Flex)`
    flex-direction: column;
`;

const LabelText = styled(HighlightedBodyTextBold)`
    flex: 1;
    color: ${({ theme }) => theme.colors.textSecondary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 12px;
`;
const ToggleStateIcon = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => !['isExpanded'].includes(prop),
})`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-size: 16px;
    transform: rotate(${({ isExpanded }) => (isExpanded ? 180 : 0)}deg);
    transition: transform 0.4s ease;
`;
const IconWrapper = styled(Flex)`
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
`;

const TextEllipsis = styled.span`
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const LableWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 12px;
    width: 100%;
`;

function SidebarMenuItem({
    item,
    activePath,
    isExpanded = false,
    toggleExpanded,
    onClick,
    checkedItems,
    setCheckedItems,
    ...props
}) {
    const { t } = useTranslate();
    const [subItemsRef, { height: subItemsHeight }] = useElementSize();

    const toggleSubItems = e => {
        e.stopPropagation();
        if (item.subItems.length === 0) return onClick?.();
        toggleExpanded();
        onClick?.();
    };

    const MainItemComponent = item.path ? NavItem : MainItemNoLink;
    const mainItemProps = item.path
        ? { to: item.path }
        : { onClick: toggleSubItems };

    const onCheck = (status, id, parent) => {
        setCheckedItems(
            getCheckedItems({ status, id, parent, checkedItems, item })
        );
    };

    return (
        <MenuItem {...props}>
            <MainItem isExpanded={isExpanded}>
                <MainItemComponent {...mainItemProps}>
                    {item.icon && <IconWrapper>{item.icon}</IconWrapper>}
                    <LableWrapper>
                        {!item.hideStatus && (
                            <StatusButton
                                changeStatus={status =>
                                    onCheck(status, item.label)
                                }
                                currentStatus={checkedItems[item.label]?.status}
                            />
                        )}
                        <LabelText>{t(item.label)}</LabelText>
                    </LableWrapper>

                    {item.subItems?.length > 0 && (
                        <IconWrapper onClick={toggleSubItems}>
                            <ToggleStateIcon
                                icon={faCaretDown}
                                isExpanded={isExpanded}
                            />
                        </IconWrapper>
                    )}
                </MainItemComponent>
            </MainItem>
            {item.subItems?.length > 0 && (
                <HeightTransition
                    on={isExpanded}
                    height={`${subItemsHeight}px`}
                >
                    {transitionProps => (
                        <div {...transitionProps}>
                            <Flex flexDirection="column" ref={subItemsRef}>
                                {item.subItems.map(subItem => (
                                    <SubItem
                                        key={subItem.label}
                                        to={subItem.path}
                                        isActive={
                                            subItem.path === activePath ||
                                            (subItem.subItems ?? []).find(
                                                thirdLevel =>
                                                    thirdLevel.path ===
                                                    activePath
                                            )
                                        }
                                        onClick={subItem.onClick}
                                    >
                                        {subItem.icon && (
                                            <IconWrapper>
                                                {subItem.icon}
                                            </IconWrapper>
                                        )}
                                        <LableWrapper>
                                            <StatusButton
                                                changeStatus={status =>
                                                    onCheck(
                                                        status,
                                                        subItem.path,
                                                        item.label
                                                    )
                                                }
                                                currentStatus={
                                                    checkedItems[subItem.path]
                                                        ?.status
                                                }
                                            />
                                            <LabelText>
                                                <TextEllipsis>
                                                    {t(subItem.label)}
                                                </TextEllipsis>
                                            </LabelText>
                                        </LableWrapper>
                                    </SubItem>
                                ))}
                            </Flex>
                        </div>
                    )}
                </HeightTransition>
            )}
        </MenuItem>
    );
}

SidebarMenuItem.propTypes = {
    item: MenuItemPropType,
    activePath: PropTypes.string,
    isExpanded: PropTypes.bool,
    toggleExpanded: PropTypes.func,
};

export default SidebarMenuItem;
