import { useTranslate, formatMoney } from '@mspecs/shared-utils';

const MoneyCellRenderer = ({ value, column, row }) => {
    const { t } = useTranslate();
    return formatMoney(
        value,
        column.suffix?.(row) || `${t('Kr').toLowerCase()}`
    );
};

export default MoneyCellRenderer;
