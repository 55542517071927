import React from 'react';
import styled from '@emotion/styled';
import { createLayoutHoc } from './layout-utils';
import { translate, useTranslate } from '@mspecs/shared-utils';
import ToggableHelpTextHeader from '../components/toggable-help-text-header-component';

export const GroupHeader = styled.legend`
    padding: 0;
    margin-top: 0;
    ${noBottomMargin => (noBottomMargin ? '' : 'margin-bottom: 0.5rem')};
    font-weight: 500;
    line-height: 1.2;
    font-size: 1.1rem;
    color: ${({ theme }) => theme.colors.primaryColor}};
`;
const Fieldset = styled.fieldset`
    padding: 0;
    border: 0;
    margin: 0;
    min-width: 0;
    flex-grow: 1;
    flex-direction: column;
    gap: 10px 15px;
    display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
    margin-bottom: 10px;
`;

const GroupItemStyle = styled.div`
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;

const NoDiffLabel = styled.div`
    font-style: italic;
    color: #a9a9a9;
    font-size: 0.9rem;
    margin-top: 2rem;
    flex-grow: 0;
`;

const GroupItemLayout = ({
    t,
    showMissingFields,
    elementsWithNoDiff = [],
    children,
    ...rest
}) => {
    const noDiffLabel = React.useMemo(() => {
        if (!showMissingFields) {
            return '';
        }
        return elementsWithNoDiff.length > 0
            ? `${t('FORM_MERGER_MISSING_FIELDS')} ${elementsWithNoDiff
                  .map(element => t(element.label))
                  .join(', ')}`
            : '';
    }, [t, showMissingFields, elementsWithNoDiff]);

    return (
        <GroupItemStyle {...rest}>
            {children}
            {showMissingFields && noDiffLabel && (
                <NoDiffLabel key="missingFields">{noDiffLabel}</NoDiffLabel>
            )}
        </GroupItemStyle>
    );
};

export const MemoizedGroupItemLayout = React.memo(translate(GroupItemLayout));

export function GroupLayout({
    uischema,
    children,
    visible,
    label,
    className = '',
}) {
    const { t } = useTranslate();

    const newChildren = label
        ? React.Children.map(children, (child, idx) =>
              idx === 0
                  ? React.cloneElement(child, { showMissingFields: true })
                  : child
          )
        : children;

    const isHidden =
        visible === undefined || visible === null ? false : !visible;

    return (
        <Fieldset className={`${className} group-layout`} hidden={isHidden}>
            {label &&
                (uischema.helpText ? (
                    <ToggableHelpTextHeader
                        title={
                            <GroupHeader noBottomMargin>{t(label)}</GroupHeader>
                        }
                        helpText={t(uischema.helpText)}
                    />
                ) : (
                    <GroupHeader>{t(label)}</GroupHeader>
                ))}
            {uischema.description && (
                <p
                    dangerouslySetInnerHTML={{
                        __html: t(uischema.description),
                    }}
                />
            )}
            {newChildren}
        </Fieldset>
    );
}

export default createLayoutHoc(
    GroupLayout,
    MemoizedGroupItemLayout,
    'Group',
    'group-layout'
);
