import styled from '@emotion/styled';
import { useElementSize } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { LabelWrapper } from '../../../../../utils/hoc';
import Button, { BUTTON_TYPES } from '../../../../button/button-component';
import HeightTransition from '../../../../animations/height-animation-component';
import SearchInput from '../search-input-component';

const FlexFixed = styled(Flex)`
    position: fixed;
    z-index: 20;
`;

const FullscreenSearchContainer = styled(FlexFixed)`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.bgPrimary};
    padding-top: ${({ theme }) => theme.spacing.large};
    ${({ theme }) => theme.isAboveMobile()} {
        display: none;
    }
`;

const InputContainer = styled(Flex)`
    padding: 0 16px;
`;

const CancelButton = styled(Button)`
    padding: ${({ theme }) => theme.spacing.default};
    margin-left: ${({ theme }) => theme.spacing.small};
`;

const MobileFullscreenSearch = ({
    isVisible,
    onClose,
    label,
    inputProps = {},
    children,
    closeLabel = 'CANCEL',
}) => {
    const [setRef, { height }] = useElementSize();

    return (
        <HeightTransition on={isVisible} height={`${height}px`} appear={false}>
            {transitionProps => (
                <FlexFixed {...transitionProps}>
                    <FullscreenSearchContainer ref={setRef}>
                        <InputContainer>
                            <LabelWrapper label={label} stackedLabel>
                                <SearchInput autoFocus {...inputProps} />
                                <CancelButton
                                    buttonType={BUTTON_TYPES.TEXT}
                                    label={closeLabel}
                                    onClick={onClose}
                                />
                            </LabelWrapper>
                        </InputContainer>
                        {children}
                    </FullscreenSearchContainer>
                </FlexFixed>
            )}
        </HeightTransition>
    );
};

export default MobileFullscreenSearch;

MobileFullscreenSearch.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    inputProps: PropTypes.object,
    label: PropTypes.string,
    children: PropTypes.node,
};
