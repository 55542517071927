import styled from '@emotion/styled';
import { Button, BUTTON_TYPES } from '../../../button';
import ImageEditorToolbar from '../image-editor-toolbar-component';
import NavigationHeader, { HelpersLabel } from './navigation-header';
import { useTranslate } from '@mspecs/shared-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSliders } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { IImage } from '../type';
import { SwipeContainer } from '../../../swipe-handler';
import { INavigation } from '../image-editor-modal-component';

const Container = styled.section`
    height: 100%;
    display: grid;
    grid-template:
        'top' 52px
        'info' 130px
        'center'
        'bottom' 79px;
`;

const InfoContainer = styled.section`
    grid-area: info;
    display: flex;
    flex-direction: column;
`;

const TopContainer = styled.section`
    grid-area: top;
    display: flex;
    justify-content: flex-end;

    padding: 0px 7px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const BottomContainer = styled.section`
    grid-area: bottom;
    display: flex;
    align-items: center;
    gap: 16px;

    padding: 10px 16px 25px;
    border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
`;

const ImageSwipeContainer = styled(SwipeContainer)`
    grid-area: center;
    overflow: hidden;
`;

const StyledImage = styled.img`
    object-fit: contain;
    object-position: top;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const StyledButton = styled(Button)`
    padding-left: 10px;
    padding-right: 10px;
    & > svg {
        padding-right: 4px;
        font-size: 20px;
    }
`;

export interface IMibileImageView {
    image: IImage;
    isSubmitActive?: boolean;
    label?: string;
    navigation?: INavigation;
    onDelete?: () => void;
    onDownload?: () => void;
    onFullMode?: () => void;
    onBack?: () => void;
    setAdvancedEditing: () => void;
    setOptions: () => void;
}

function MobileImageView({
    image,
    isSubmitActive,
    label,
    navigation,
    onDelete,
    onDownload,
    onFullMode,
    onBack,
    setAdvancedEditing,
    setOptions,
}: IMibileImageView) {
    const { t } = useTranslate();
    return (
        <Container>
            <TopContainer>
                <ImageEditorToolbar
                    onSubmit={() => {}}
                    onClose={() => {}}
                    isSubmitActive={Boolean(isSubmitActive)}
                    onDelete={onDelete}
                    onDownload={onDownload}
                    onFullMode={onFullMode}
                />
            </TopContainer>
            <InfoContainer>
                <NavigationHeader onPrevious={onBack} label={label}>
                    <HelpersLabel>{image.title}</HelpersLabel>
                </NavigationHeader>
            </InfoContainer>
            <ImageSwipeContainer
                onSwipeLeft={navigation?.onNext}
                onSwipeRight={navigation?.onPrevious}
            >
                <StyledImage src={image.viewURI || image.originalURI} />
            </ImageSwipeContainer>
            <BottomContainer>
                <StyledButton
                    buttonType={BUTTON_TYPES.PRIMARY_LIGHT}
                    onClick={setOptions}
                    fluid
                    large
                >
                    <FontAwesomeIcon icon={faCircleInfo} />
                    {t('IMAGE_EDITOR_IMAGE_SETTINGS')}
                </StyledButton>
                <StyledButton
                    buttonType={BUTTON_TYPES.PRIMARY_LIGHT}
                    onClick={setAdvancedEditing}
                    fluid
                    large
                >
                    <FontAwesomeIcon icon={faSliders} />
                    {t('IMAGE_EDITOR_ADVANCED_EDITING')}
                </StyledButton>
            </BottomContainer>
        </Container>
    );
}

export default MobileImageView;
