import styled from '@emotion/styled';

import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useIsMobile } from '@mspecs/shared-utils';
import { KebabMenu } from '../../table';
import {
    Header1Text,
    HighlightedBodyText,
} from '../../typograph/typograph-components';

const HeaderWrapper = styled(Flex)`
    padding: ${({ theme }) => theme.spacing.default} 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`;
const HeaderTotalCountText = styled(HighlightedBodyText)`
    color: ${({ theme }) => theme.colors.textSecondary};
    padding-left: ${({ theme }) => theme.spacing.small};
`;

const ListHeaderLayout = ({ header, totalCount, settingsOptions }) => {
    const isSmall = useIsMobile();
    const showSettings = isSmall && settingsOptions;
    return (
        <Flex justifyContent="space-between" alignItems="center">
            <HeaderWrapper>
                <Flex alignItems="baseline">
                    <Header1Text>{header}</Header1Text>
                    {totalCount && (
                        <HeaderTotalCountText>
                            ({totalCount >= 1000 ? '1000+' : totalCount})
                        </HeaderTotalCountText>
                    )}
                </Flex>
                {showSettings && (
                    <KebabMenu
                        appendTo={document.body}
                        options={settingsOptions}
                        title="LIST_MENU_MOBILE_HEADER"
                    />
                )}
            </HeaderWrapper>
        </Flex>
    );
};

ListHeaderLayout.propTypes = {
    totalCount: PropTypes.number,
    header: PropTypes.string,
    settingsOptions: PropTypes.arrayOf(PropTypes.object),
};

export default ListHeaderLayout;
