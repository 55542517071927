import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { BUTTON_TYPES, Button } from '../index';

const CheckMarkAndTextButton = ({ children, ...props }) => {
    const theme = useTheme();
    return (
        <Button
            buttonType={BUTTON_TYPES.TEXT}
            css={css`
                svg {
                    color: ${theme.colors.primaryColor};
                }
                &:hover svg {
                    color: ${theme.colors.successColor};
                }
            `}
            {...props}
        >
            <FontAwesomeIcon
                css={css`
                    font-size: 16px;
                `}
                icon={faCheck}
            />
            <span
                css={css`
                    margin-left: 7px;
                `}
            >
                {children}
            </span>
        </Button>
    );
};

CheckMarkAndTextButton.propTypes = {
    children: PropTypes.node,
};

export default CheckMarkAndTextButton;
