import styled from '@emotion/styled';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { HelpingTextMedium } from '../typograph/typograph-components';

const MenuItem = styled(Flex)`
    width: 100%;
    height: 64px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${({ theme }) => theme.spacing.default};
    padding-right: ${({ theme }) => theme.spacing.large};
    margin-bottom: ${({ theme }) => theme.spacing.default};
    border-radius: ${({ theme }) => theme.spacing.default};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.borderPrimary};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: none;
    }
`;

const ContinueArrow = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const Label = styled(HelpingTextMedium)`
    font-size: 13px;
`;

const MobileMenuItem = ({ onClick, label, ...props }) => {
    return (
        <MenuItem onClick={onClick} {...props}>
            <Label>{label}</Label>
            <ContinueArrow icon={faChevronRight} size={'lg'} />
        </MenuItem>
    );
};

MobileMenuItem.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
};
export default MobileMenuItem;
