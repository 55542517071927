import styled from '@emotion/styled';
import { faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex } from '@rebass/grid/emotion';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button, { BUTTON_TYPES } from '../button/button-component';
import { ImageEditorModal } from './image-editor';
import { ImageProp } from './image.props';
import { useTranslate } from '@mspecs/shared-utils';
import { LoadingAnimation } from '@mspecs/shared-utils';

const IMAGE_SIZE = '170px';
const IMAGE_SIZE_INLINE = '50px';

const EmptyImageIcon = styled(FontAwesomeIcon)`
    color: #ddd;
`;

const ImageWrapper = styled(Flex)`
    background: #fff;
`;
const LoadingWrapper = styled(Flex)`
    div {
        width: 100%;
    }
    h5 {
        font-size: ${({ inline }) => (inline ? '14px' : '20px')};
    }
`;
const SmallButton = styled(Button)`
    width: 100%;
`;

const Image = styled('div')`
    background-size: contain;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    height: 100%;
`;

const DefaultImageRender = ({
    image,
    imageSize,
    width,
    inline,
    onEdit,
    ...props
}) => (
    <ImageWrapper
        justifyContent="center"
        alignItems="center"
        height={imageSize}
        width={width}
        {...props}
    >
        {image ? (
            <Image
                style={{
                    backgroundImage: `url(${image.thumbnailURI})`,
                }}
            />
        ) : (
            <EmptyImageIcon
                icon={faUserCircle}
                style={{ fontSize: inline ? '2rem' : '6rem' }}
            />
        )}
    </ImageWrapper>
);
DefaultImageRender.propTypes = {
    imageSize: PropTypes.string,
    image: ImageProp,
    inline: PropTypes.bool,
    width: PropTypes.string,
};

const DefaultToolbarRender = ({
    image,
    onRemove,
    width,
    onAdd,
    onEdit,
    inline,
}) => {
    return (
        <Flex width={!inline ? width : undefined}>
            {image ? (
                <>
                    <SmallButton
                        buttonType={BUTTON_TYPES.TEXT}
                        onClick={onEdit}
                        style={{
                            marginRight: inline ? '10px' : '',
                            marginLeft: inline ? '20px' : '',
                        }}
                        label="CHANGE"
                    />
                    <SmallButton
                        buttonType={BUTTON_TYPES.TEXT}
                        onClick={onRemove}
                        label="REMOVE"
                    />
                </>
            ) : (
                <SmallButton
                    buttonType={BUTTON_TYPES.TEXT}
                    onClick={onAdd}
                    style={{
                        marginLeft: inline ? '10px' : '',
                    }}
                    label="ADD_IMAGE"
                />
            )}
        </Flex>
    );
};

const ImageUpload = ({
    onUpload,
    image,
    onRemove,
    isLoading,
    inline = false,
    width = inline ? IMAGE_SIZE_INLINE : IMAGE_SIZE,
    ImageRenderer = DefaultImageRender,
    ToolbarRenderer = DefaultToolbarRender,
}) => {
    const inputRef = useRef();
    const [isDisplayingEditor, setIsDisplayingEditor] = useState(false);
    const { t } = useTranslate();

    const imageSize = inline ? IMAGE_SIZE_INLINE : IMAGE_SIZE;
    const onFileAdded = e => {
        const file = e.target.files?.[0] ?? null;
        if (file) {
            onUpload(file);
        }
    };

    const onEditSave = blob => {
        onUpload(blob);
        setIsDisplayingEditor(false);
    };

    const imageProps = {
        image,
        imageSize,
        width,
        inline,
        onEdit: () => setIsDisplayingEditor(true),
    };

    if (isLoading) {
        return (
            <LoadingWrapper
                justifyContent="center"
                width={width}
                height={imageSize}
                inline={inline}
            >
                <LoadingAnimation>{t('LOADING')}</LoadingAnimation>
            </LoadingWrapper>
        );
    }

    return (
        <>
            <Flex
                flexDirection={inline ? 'row' : 'column'}
                justifyContent="flex-start"
                flexWrap="wrap"
            >
                <ImageRenderer
                    {...{
                        ...imageProps,
                        DefaultImageRender,
                    }}
                />
                <ToolbarRenderer
                    {...{
                        ...imageProps,
                        onAdd: () => inputRef.current.click(),
                        onRemove,
                        DefaultToolbarRender,
                    }}
                />

                <input
                    ref={inputRef}
                    hidden
                    type="file"
                    accept="image/*"
                    onChange={onFileAdded}
                />
            </Flex>
            <ImageEditorModal
                isOpen={isDisplayingEditor}
                onClose={() => setIsDisplayingEditor(false)}
                image={image}
                onSave={onEditSave}
                hasSimpleEditing
            />
        </>
    );
};

ImageUpload.propTypes = {
    onRemove: PropTypes.func.isRequired,
    onUpload: PropTypes.func.isRequired,
    image: ImageProp,
    isLoading: PropTypes.bool,
    inline: PropTypes.bool,
    width: PropTypes.string,
    ImageRenderer: PropTypes.func,
    ToolbarRenderer: PropTypes.func,
};
export default ImageUpload;
