import BoolCellRenderer from './bool-cell-renderer';
import EnumCellRenderer from './enum-cell-renderer';
import FileCellRenderer from './file-cell-renderer';
import IdentifierVerifiedCellRenderer from './identifier-verified-cell-renderer';
import ImageCellRenderer from './image-cell-renderer';
import PersonNameCellRenderer from './person-name-cell-renderer';
import FieldsSeparatedByCommaCellRenderer from './fields-separeted.by-comma-cell-renderer';
import TextBadgeCellRenderer from './text-badge-cell-renderer';
import MoneyCellRenderer from './money-cell-renderer';
import TranslatedCellRenderer from './translated-cell-renderer';
import SettingsCellRenderer from './settings-cell-renderer';
import ProfileImageRenderer from './profile-image-renderer';
import SwitchCellRenderer from './switch-cell-renderer';
import CompareCellRenderer from './compare-cell-renderer';
import { formatDate } from '@mspecs/shared-utils';
import objectTypeIconRenderer from './deal/object-type-icon-renderer';
import StickyOverviewCellRenderer from './sticky/sticky-overview-cell-renderer';
import CheckboxCellRenderer from './checkbox-cell-renderer';
import RadioCellRenderer from './radio-cell-renderer';

const cellRenderers = {
    image: ImageCellRenderer,
    profileImage: ProfileImageRenderer,
    enum: EnumCellRenderer,
    file: FileCellRenderer,
    verified: IdentifierVerifiedCellRenderer,
    bool: BoolCellRenderer,
    checkbox: CheckboxCellRenderer,
    compare: CompareCellRenderer,
    date: ({ value }) => formatDate(value),
    datetime: ({ value }) => formatDate(value, true),
    default: ({ value }) => (value == null ? '' : String(value)),
    personName: PersonNameCellRenderer,
    radio: RadioCellRenderer,
    fieldsSeparatedByComma: FieldsSeparatedByCommaCellRenderer,
    textBadge: TextBadgeCellRenderer,
    money: MoneyCellRenderer,
    translated: TranslatedCellRenderer,
    settings: SettingsCellRenderer,
    stickyOverview: StickyOverviewCellRenderer,
    switch: SwitchCellRenderer,
    objectTypeIcon: objectTypeIconRenderer,
};

export const getCellRenderer = type =>
    cellRenderers[type] || cellRenderers.default;

export { cellRenderers as TableCellRenderers };
export { default as BoolCellRenderer } from './bool-cell-renderer';
