import { css, SerializedStyles } from '@emotion/react';
import { IconButtonMode } from './icon-button-types';
import Button from '../button-component';
import styled from '@emotion/styled';

export interface IStyledIconButton {
    small?: boolean;
    large?: boolean;
    isRound?: boolean;
    mode?: IconButtonMode;
}

const SquareMode = css`
    justify-content: center;
    align-items: center;

    padding: 0;
    aspect-ratio: 1/1;
`;

const modeSet: Partial<Record<IconButtonMode, string | SerializedStyles>> = {
    [IconButtonMode.square]: SquareMode,
};

export const StyledIconButton = styled(Button)<IStyledIconButton>`
    font-size: 16px;
    padding: ${({ theme }) => theme.spacing.default};
    width: ${({ small, large, isRound }) => {
        if (!isRound) return '';

        return small ? '30px' : large ? '42px' : '34px';
    }};

    border-radius: ${({ theme }) => theme.borderRadius.medium};

    ${({ mode = IconButtonMode.rectangel }) => modeSet[mode] || ''}

    svg {
        height: 20px;
        aspect-ratio: 1/1;
    }
`;
