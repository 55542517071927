import styled from '@emotion/styled';
import { useState } from 'react';
import { Button, BUTTON_TYPES, IconButton } from '../../button';
import { Header1Text, ZoomSlider } from '../..';
import { useImageUploadLocale } from './locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRotateLeft,
    faRotateRight,
    faHand,
} from '@fortawesome/pro-regular-svg-icons';
import { IconButtonTolBar } from './styled';

const Container = styled.section`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const TitleContainer = styled.section`
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    display: none;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderSecondary};
    margin-right: 10px;
    max-height: 64px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: flex;
    }
`;

const MenuContainer = styled.section`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
    padding: 10px 10px;
`;

const MobileDisableButton = styled.section`
    display: none;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: block;
    }
`;

export interface IImageHeaderBarProps {
    editorRef: any;
    onClose?: () => void;
    onSave?: () => void;
    disabledSave?: boolean;
}

function ImageHeaderBar({
    editorRef,
    disabledSave: disableSave,
    onClose = () => {
        console.log('Not overridden onClose to header bar');
    },
    onSave = () => {
        console.log('Not overridden onSave to header bar');
    },
}: IImageHeaderBarProps) {
    const [zoom, setZoom] = useState<number>(1);
    const translate = useImageUploadLocale();

    const executeInstance = () => {
        return editorRef?.current?.getInstance?.();
    };

    const handleUndo = () => {
        executeInstance()?.undo();
    };

    const handleRedo = () => {
        executeInstance()?.redo();
    };

    const handleHand = () => {
        const instance = executeInstance();
        instance.ui._buttonElements.hand.click();
    };

    const handleChangeZoom = (zoom: number) => {
        executeInstance()?.zoom({ x: 0, y: 0, zoomLevel: zoom });
        setZoom(zoom);
    };

    return (
        <Container>
            <TitleContainer>
                <Header1Text>{translate.Title}</Header1Text>
            </TitleContainer>
            <MenuContainer>
                <MobileDisableButton>
                    <ZoomSlider
                        setValue={handleChangeZoom}
                        value={zoom}
                        max={5}
                    />
                </MobileDisableButton>
                <IconButtonTolBar
                    icon={<FontAwesomeIcon fontSize={20} icon={faRotateLeft} />}
                    buttonType={BUTTON_TYPES.TEXT}
                    small={true}
                    hiddenLabel={translate.Undo}
                    onClick={handleUndo}
                />
                <IconButtonTolBar
                    icon={
                        <FontAwesomeIcon fontSize={20} icon={faRotateRight} />
                    }
                    buttonType={BUTTON_TYPES.TEXT}
                    small={true}
                    hiddenLabel={translate.Redo}
                    onClick={handleRedo}
                />
                <MobileDisableButton>
                    <IconButtonTolBar
                        icon={<FontAwesomeIcon fontSize={20} icon={faHand} />}
                        buttonType={BUTTON_TYPES.TEXT}
                        small={true}
                        hiddenLabel={translate.Hand}
                        onClick={handleHand}
                    />
                </MobileDisableButton>
                <MobileDisableButton>
                    <Button buttonType={null} onClick={onClose}>
                        {translate.Cancel}
                    </Button>
                </MobileDisableButton>
                <MobileDisableButton>
                    <Button
                        buttonType="primary"
                        onClick={onSave}
                        disabled={disableSave}
                    >
                        {translate.Save}
                    </Button>
                </MobileDisableButton>
            </MenuContainer>
        </Container>
    );
}

export default ImageHeaderBar;
