import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import { useState } from 'react';
import { Button, BUTTON_TYPES, IconButton } from '../button';
import { ConfirmDeleteModal } from '../modal';
import { StyledPopover } from '../popovers';
import EditImageIcon from './icons/edit-image-icon-component';
import UserImagePlaceholderIcon from './icons/user-image-placeholder-icon-component';
import ImageUpload from './image-upload-component';

const EmptyImageIcon = styled(UserImagePlaceholderIcon)`
    color: ${({ theme }) => theme.colors.gray80};
    height: 100%;
`;

const ImageWrapper = styled(Flex)`
    position: relative;
`;

const Image = styled.div`
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    border-radius: 100%;
`;
const ToolbarContainer = styled(Flex)`
    justify-content: center;
    padding-top: 10px;
`;

const EditButtonWrapper = styled.div`
    position: absolute;
    right: -10px;
    top: 10px;
`;

const StyledEditImageIcon = styled(EditImageIcon)`
    overflow: visible;
    margin-top: 3px; // Align icon in button
    margin-left: 2px;

    path {
        fill: ${({ theme }) => theme.colors.primaryColor};
    }
`;

const ImageRenderer = ({
    image,
    imageSize,
    width,
    inline,
    onEdit,
    ...props
}) => {
    const { t } = useTranslate();
    return (
        <ImageWrapper
            justifyContent="center"
            alignItems="center"
            height={imageSize}
            width={width}
            {...props}
        >
            {image ? (
                <>
                    <Image
                        style={{
                            backgroundImage: `url(${image.thumbnailURI})`,
                        }}
                    />
                    <StyledPopover content={t('IMAGE_EDIT')} placement="top">
                        <EditButtonWrapper>
                            <IconButton
                                icon={<StyledEditImageIcon />}
                                buttonType={BUTTON_TYPES.SECONDARY}
                                onClick={onEdit}
                                hiddenLabel="EDIT"
                            />
                        </EditButtonWrapper>
                    </StyledPopover>
                </>
            ) : (
                <EmptyImageIcon height={imageSize} width={width} />
            )}
        </ImageWrapper>
    );
};

const ToolbarRenderer = ({ image, onRemove, onAdd }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    return (
        <>
            <ToolbarContainer>
                {image && (
                    <Button
                        buttonType={BUTTON_TYPES.TEXT}
                        onClick={() => setIsConfirming(true)}
                        small
                        label="REMOVE"
                        style={{
                            marginRight: image ? '10px' : '',
                        }}
                    />
                )}
                <Button
                    buttonType={BUTTON_TYPES.PRIMARY}
                    small
                    onClick={onAdd}
                    label={image ? 'CHANGE_IMAGE' : 'SELECT_IMAGE'}
                />
            </ToolbarContainer>
            <ConfirmDeleteModal
                isOpen={isConfirming}
                headerText="DELETE_IMAGE_HEADER"
                infoText="DELETE_IMAGE_BODY"
                onCancel={() => setIsConfirming(false)}
                onConfirm={onRemove}
            />
        </>
    );
};

const UserImageUpload = props => {
    return (
        <ImageUpload
            {...props}
            ImageRenderer={ImageRenderer}
            ToolbarRenderer={ToolbarRenderer}
        />
    );
};

UserImageUpload.propTypes = {
    ...ImageUpload.propTypes,
};
export default UserImageUpload;
