import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { TEXT_STYLES } from '../../typograph/typograph-components';

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px 0;
    ${({ theme }) => TEXT_STYLES.header5Text(theme)};
    line-height: unset;
    &:focus {
        box-shadow: none;
    }
    &:hover {
        text-decoration: none;
    }
`;

const StyledIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;

const BigBackButton = ({ label, to, ...props }) => {
    return (
        <StyledLink to={to} {...props}>
            <StyledIconContainer>
                <StyledIcon icon={faChevronLeft} />
            </StyledIconContainer>
        </StyledLink>
    );
};

BigBackButton.propTypes = {
    label: PropTypes.string,
    to: PropTypes.string,
};

export default BigBackButton;
