interface IFooterGroup {
    getFooterGroupProps: () => { key: string };
    headers: {
        render: (arg: string) => any;
        headerCss: string;
        css: string;
        getFooterProps: () => { key: string };
    }[];
}

export default function TableFooter({
    footerGroups,
}: {
    footerGroups: IFooterGroup[];
}) {
    return (
        <div className="rt-tfoot">
            {footerGroups.map(footerGroup => (
                <div className="rt-tr" {...footerGroup.getFooterGroupProps()}>
                    {footerGroup.headers.map(column => (
                        <span
                            className="rt-th"
                            {...column.getFooterProps()}
                            css={column.headerCss || column.css}
                        >
                            {column.render('Footer')}
                        </span>
                    ))}
                </div>
            ))}
        </div>
    );
}
