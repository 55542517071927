import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Close = styled(FontAwesomeIcon)`
    font-size: 1.2rem;
    color: #a7a7a7;
    cursor: pointer;
`;

const CloseIcon = ({ onClose }) => <Close icon={faTimes} onClick={onClose} />;

CloseIcon.propTypes = {
    onClose: PropTypes.func,
};

export default CloseIcon;
