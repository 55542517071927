import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { useUniqueId } from '@mspecs/shared-utils';
import { useTranslate } from '@mspecs/shared-utils';
import {
    CheckboxTreeWrapper,
    CheckboxWrapper,
    ExpandIcon,
    Label,
    StyledCheckbox,
    StyledList,
    StyledListItem,
    TreeRow,
} from './checkboxtree-styles';

const ExpandCollapseButton = ({ isExpanded, ...rest }) => {
    return (
        <button
            aria-label={`${isExpanded ? 'Collapse' : 'Expand'} nodes`}
            title={`${isExpanded ? 'Collapse' : 'Expand'} nodes`}
            aria-expanded={!!isExpanded}
            css={css`
                margin-left: -21px;
                width: 21px;
                height: 21px;
                border: none;
                background: none;
                &:active {
                    color: inherit;
                }
            `}
            {...rest}
        >
            <ExpandIcon icon={isExpanded ? faMinus : faPlus} />
        </button>
    );
};

ExpandCollapseButton.propTypes = {
    isExpanded: PropTypes.bool,
};

const CheckboxTree = React.forwardRef(
    (
        {
            nodes,
            checked,
            expanded = [],
            onChecked,
            onExpanded,
            disableIndeterminate,
        },
        ref
    ) => {
        /**
         * Flatten nodes, adding parentValue to child nodes and childValues to parent nodes
         */
        const flattenedNodes = useMemo(
            () =>
                nodes.reduce(
                    (acc, { items, ...curr }) => [
                        ...acc,
                        {
                            ...curr,
                            ...(items
                                ? {
                                      childValues: items.map(c => c.value),
                                  }
                                : {}),
                        },
                        ...(items
                            ? items.map(c => ({
                                  ...c,
                                  parentValue: curr.value,
                              }))
                            : []),
                    ],
                    []
                ),
            [nodes]
        );

        const handleChecked = ({ target: { value } }) => {
            const selectedNode = flattenedNodes.find(n => n.value === value);

            // Checked leaf node checkbox is clicked, remove its value
            if (checked.includes(value)) {
                onChecked(checked.filter(v => v !== value));
            } else if (selectedNode.childValues) {
                // Checkbox with child values is clicked

                // Are all child nodes selected? - Remove them
                if (selectedNode.childValues.every(v => checked.includes(v))) {
                    onChecked(
                        checked.filter(
                            v => !selectedNode.childValues.includes(v)
                        )
                    );
                } else {
                    // Are only some checkboxes checked? - Check all
                    onChecked([
                        ...checked,
                        ...selectedNode.childValues.filter(
                            value => !checked.includes(value)
                        ),
                    ]);
                }
            } else {
                // Unchecked leaf node checkbox is clicked - add the value
                onChecked(checked.concat(value));
            }
        };

        const handleExpanded = value => {
            onExpanded(
                expanded.includes(value)
                    ? expanded.filter(v => v !== value)
                    : expanded.concat(value)
            );
        };

        return (
            <CheckboxTreeWrapper ref={ref}>
                <StyledList>
                    {nodes.map(node => (
                        <Node
                            key={node.value}
                            {...node}
                            checkedValues={checked}
                            expandedValues={expanded}
                            onExpanded={handleExpanded}
                            onChecked={handleChecked}
                            disableIndeterminate={disableIndeterminate}
                        />
                    ))}
                </StyledList>
            </CheckboxTreeWrapper>
        );
    }
);

CheckboxTree.propTypes = {
    nodes: PropTypes.array,
    checked: PropTypes.array,
    expanded: PropTypes.array,
    onChecked: PropTypes.func,
    onExpanded: PropTypes.func,
    disableIndeterminate: PropTypes.bool,
};

const Node = ({
    value,
    label,
    items = [],
    expandedValues = [],
    onExpanded,
    checkedValues = [],
    onChecked,
    disableIndeterminate,
}) => {
    const id = useUniqueId();
    const { t } = useTranslate();
    const isExpanded = expandedValues.includes(value);
    const hasItems = items.length > 0;
    const itemValues = items.map(c => c.value);

    const isValueChecked = useCallback(
        value => checkedValues.includes(value),
        [checkedValues]
    );
    /**
     * Select / Deselect the parent checkbox depending on the selected child nodes
     */
    const isAllChildrenSelected = itemValues.every(isValueChecked);
    const isIndeterminated =
        hasItems &&
        !isAllChildrenSelected &&
        itemValues.some(isValueChecked) &&
        !disableIndeterminate;

    const isChecked = hasItems ? isAllChildrenSelected : isValueChecked(value);

    return (
        <StyledListItem>
            <TreeRow>
                {hasItems && (
                    <ExpandCollapseButton
                        isExpanded={isExpanded}
                        onClick={() => onExpanded(value)}
                    />
                )}
                <CheckboxWrapper>
                    <StyledCheckbox
                        id={id}
                        value={value}
                        onChange={onChecked}
                        indeterminate={isIndeterminated}
                        checked={isChecked}
                    />
                </CheckboxWrapper>
                <Label htmlFor={id}>{t(label)}</Label>
            </TreeRow>

            {isExpanded && hasItems && (
                <StyledList>
                    {items.map(node => (
                        <Node
                            key={node.value}
                            {...node}
                            onChecked={onChecked}
                            checkedValues={checkedValues}
                        />
                    ))}
                </StyledList>
            )}
        </StyledListItem>
    );
};

Node.propTypes = {
    value: PropTypes.any,
    label: PropTypes.string,
    items: PropTypes.array,
    expandedValues: PropTypes.array,
    onExpanded: PropTypes.func,
    checkedValues: PropTypes.array,
    onChecked: PropTypes.func,
    flattenedNodes: PropTypes.array,
    disableIndeterminate: PropTypes.bool,
};

export default CheckboxTree;
