import { format, getHours, getMinutes } from 'date-fns';
import { sv, enGB } from 'date-fns/locale';
import { currentLanguage } from './services/i18n';

const locales = {
    sv,
    en: enGB,
};

export const DATE_FORMATS = {
    MONTH_NAME_SHORT: 'd MMM',
    MONTH_NAME_SHORT_WITH_TIME: 'd MMM HH:mm',
    MONTH_NAME_SHORT_WITH_YEAR: 'd MMM yyyy',
    STANDARD_DATE: 'yyyy-MM-dd',
    TIME: 'HH:mm',
    SLASH_DASH: 'dd/MM-yy',
    TIMESTAMP: 'yyyy-MM-dd HH:mm',
};

const formatWithError = formatter => {
    try {
        const formattedDate = formatter();
        return formattedDate;
    } catch {
        return 'Invalid date';
    }
};

export const sortDate = (arr, field, descending = false) => {
    if (descending) {
        return arr.sort((a, b) => new Date(b[field]) - new Date(a[field]));
    }
    // Ascending
    return arr.sort((a, b) => new Date(a[field]) - new Date(b[field]));
};

export const formatDate = (date, includeTime = false) => {
    if (!date) return '';

    return formatWithError(() =>
        format(
            new Date(date),
            `yyyy-MM-dd${includeTime ? ` ${DATE_FORMATS.TIME}` : ''}`
        )
    );
};

export const formatLocaleDate = (date, formatString, options = {}) =>
    formatWithError(() =>
        format(date, formatString, {
            ...options,
            locale: locales[currentLanguage()],
        })
    );
export const formatTime = date =>
    formatWithError(() => format(new Date(date), DATE_FORMATS.TIME));

export const hasDateTime = dateObject =>
    getHours(dateObject) || getMinutes(dateObject);
