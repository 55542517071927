import React, { useRef } from 'react';

export interface ISwipeContainer {
    children: React.ReactNode;
    className?: string;
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
    swipeThreshold?: number;
}

function SwipeContainer({
    children,
    className,
    onSwipeLeft,
    onSwipeRight,
    onSwipeUp,
    onSwipeDown,
    swipeThreshold = 50,
}: ISwipeContainer) {
    const touchStartX = useRef<number | null>(null);
    const touchStartY = useRef<number | null>(null);
    const touchEndX = useRef<number | null>(null);
    const touchEndY = useRef<number | null>(null);

    const handleTouchStart = (e: React.TouchEvent) => {
        touchStartX.current = e.touches[0].clientX;
        touchStartY.current = e.touches[0].clientY;
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        touchEndX.current = e.touches[0].clientX;
        touchEndY.current = e.touches[0].clientY;
    };

    const handleTouchEnd = () => {
        if (
            touchStartX.current !== null &&
            touchStartY.current !== null &&
            touchEndX.current !== null &&
            touchEndY.current !== null
        ) {
            const deltaX = touchStartX.current - touchEndX.current;
            const deltaY = touchStartY.current - touchEndY.current;

            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                if (deltaX > swipeThreshold) {
                    onSwipeLeft?.();
                } else if (deltaX < -swipeThreshold) {
                    onSwipeRight?.();
                }
            } else {
                if (deltaY > swipeThreshold) {
                    onSwipeUp?.();
                } else if (deltaY < -swipeThreshold) {
                    onSwipeDown?.();
                }
            }
        }

        touchStartX.current = null;
        touchStartY.current = null;
        touchEndX.current = null;
        touchEndY.current = null;
    };

    return (
        <div
            className={className}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            {children}
        </div>
    );
}

export default SwipeContainer;
