export enum ColorHueVariables {
    primaryColor = '--primary-color-hue',
    accent = '--accent-hue',
    green = '--green-hue',
    pending = '--pending-hue',
    highlightingBg = '--highlighting-bg-hue',
    red = '--red-hue',
    contrastBg = '--contrast-bg-hue',
    textPrimary = '--text-primary-hue',
    bgSecondary = '--bg-secondary-hue',
}

export const ColorsCssVariables = {
    '--bg-primary': 'hsl(0 0% calc(100% + calc(var(--light) * 4)))',
    '--bg-primary-semi-transparent': 'hsla(0 0% 100% / 0.75)',
    '--bg-secondary': 'hsl(0 0% calc(97.3% + calc(var(--light) * 1)))',
    '--bg-secondary-80': 'hsl(0 0% calc(98.4% + calc(var(--light) * 1)))',
    '--bg-secondary-120': 'hsl(0 0% calc(94.9% + calc(var(--light) * 1)))',
    '--bg-secondary-140': 'hsl(0 0% calc(91.8% + calc(var(--light) * 1)))',
    '--text-primary': 'hsl(var(--text-primary-hue) 3.1% 12.5%)',
    '--text-secondary': 'hsl(0 0% 44.7%)',
    '--contrast-bg-primary':
        'hsl(var(--contrast-bg-hue) 81.1% calc(10.4% + var(--light)))',
    '--contrast-bg-105':
        'hsl(var(--contrast-bg-hue) 82.2% calc(8.8% + var(--light)))',
    '--contrast-bg-95':
        'hsl(var(--contrast-bg-hue) 79.7% calc(13.5% + var(--light)))',
    '--contrast-bg-90':
        'hsl(var(--contrast-bg-hue) 78.8% calc(16.7% + var(--light)))',
    '--contrast-bg-85':
        'hsl(var(--contrast-bg-hue) 37.6% calc(24.5% + var(--light)))',
    '--contrast-bg-75':
        'hsl(var(--contrast-bg-hue) 34.5% calc(33.5% + var(--light)))',
    '--contrast-bg-65':
        'hsl(var(--contrast-bg-hue) 31.5% calc(42.9% + var(--light)))',
    '--contrast-bg-55':
        'hsl(var(--contrast-bg-hue) 23.4% calc(53.9% + var(--light)))',
    '--contrast-bg-50':
        'hsl(var(--contrast-bg-hue) 23.3% calc(64.7% + var(--light)))',
    '--primary-color':
        'hsl(var(--primary-color-hue) 79.2% calc(28.2% + var(--light)))',
    '--primary-color-90':
        'hsl(var(--primary-color-hue) 56.9% calc(35.5% + var(--light)))',
    '--primary-color-80':
        'hsl(var(--primary-color-hue) 41.9% calc(42.5% + var(--light)))',
    '--primary-color-60':
        'hsl(var(--primary-color-hue) 30.9% calc(56.9% + var(--light)))',
    '--primary-color-40':
        'hsl(var(--primary-color-hue) 31.5% calc(71.4% + var(--light)))',
    '--primary-color-20':
        'hsl(var(--primary-color-hue) 31.5% calc(85.7% + var(--light)))',
    '--primary-color-15':
        'hsl(var(--primary-color-hue) 30.9% calc(89.2% + var(--light)))',
    '--primary-color-10':
        'hsl(var(--primary-color-hue) 29.7% calc(92.7% + var(--light)))',
    '--primary-color-5':
        'hsl(var(--primary-color-hue) 33.3% calc(96.5% + var(--light)))',
    '--green-primary-color':
        'hsl(var(--green-hue) 76.9% 28.4%calc(96.5% + var(--light)))',
    '--green-80': 'hsl(var(--green-hue) 35.5% calc(46.1% + var(--light)))',
    '--green-60': 'hsl(var(--green-hue) 33.9% calc(56.9% + var(--light)))',
    '--green-40': 'hsl(var(--green-hue) 28.6% calc(70.2% + var(--light)))',
    '--green-20': 'hsl(var(--green-hue) 25.8% calc(82.5% + var(--light)))',
    '--green-15': 'hsl(var(--green-hue) 23.4% calc(89.4% + var(--light)))',
    '--green-7': 'hsl(var(--green-hue) 20.8% calc(94.9% + var(--light)))',
    '--accent-primary':
        'hsl(var(--accent-hue) 67.8% calc(54.5% + var(--light)))',
    '--accent-50': 'hsl(var(--accent-hue) 71.1% calc(78.4% + var(--light)))',
    '--accent-20': 'hsl(var(--accent-hue) 66.7% calc(89% + var(--light)))',
    '--red-primary': 'hsl(var(--red-hue) 80.7% calc(40.6% + var(--light)))',
    '--red-105': 'hsl(var(--red-hue) 81.5% calc(38.2% + var(--light)))',
    '--red-110': 'hsl(var(--red-hue) 81.5% calc(36.1% + var(--light)))',
    '--red-40': 'hsl(var(--red-hue) 55.4% calc(76.3% + var(--light)))',
    '--red-20': 'hsl(var(--red-hue) 54.1% calc(88% + var(--light)))',
    '--red-10': 'hsl(var(--red-hue) 54.8% calc(93.9% + var(--light)))',
    '--pending-primary':
        'hsl(var(--pending-hue) 69.4% calc(55.1% + var(--light)))',
    '--pending-40': 'hsl(var(--pending-hue) 69.6% calc(82% + var(--light)))',
    '--pending-20': 'hsl(var(--pending-hue) 69.6% calc(91% + var(--light)))',
    '--pending-10': 'hsl(var(--pending-hue) 65.2% calc(95.5% + var(--light)))',
    '--highlighting-bg-primary':
        'hsl(var(--highlighting-bg-hue) 100% calc(85.1% + var(--light)))',
    '--highlighting-bg-50':
        'hsl(var(--highlighting-bg-hue) 100% calc(92.5% + var(--light)))',
    '--highlighting-bg-25':
        'hsl(var(--highlighting-bg-hue) 100% calc(96.3% + var(--light)))',
};
