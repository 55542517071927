import React from 'react';
import { rankWith, optionIs } from '@jsonforms/core';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { EnumSelect } from '../../../input';
import { useTranslate } from '@mspecs/shared-utils';

export function CountryEnumCell(props) {
    const { path, schema, uischema, handleChange } = props;
    const t = useTranslate();
    const prioritizedLanguageCodes = ['SE', 'ES', 'PT', 'NO', 'DK'];

    const localizedRegionName = new Intl.DisplayNames([t.language], {
        type: 'region',
    });

    const langMapper = value => ({
        label: localizedRegionName.of(value),
        value,
    });

    const prioritizedLanguages = prioritizedLanguageCodes.map(langMapper);

    const restLanguages = schema.enum
        .filter(el => !prioritizedLanguageCodes.includes(el))
        .map(langMapper)
        .sort((a, b) => a.label.localeCompare(b.label));

    const enums = [
        { options: prioritizedLanguages },
        { options: restLanguages },
    ];

    const baseProps = {
        ...getCellProps(props),
        defaultValue: uischema.options?.defaultValue,
        onChange: e => handleChange(path, e),
        sort: false,
        stackedLabel: true,
    };

    return <EnumSelect enums={enums ?? []} {...baseProps} />;
}

CountryEnumCell.propTypes = CellPropType;

export const tester = rankWith(4, optionIs('countryEnum', true));

export default { renderer: withInputControlHoc(CountryEnumCell), tester };
