import React from 'react';
import PropTypes from 'prop-types';

import fpfilter from 'lodash/fp/filter';
import fpmap from 'lodash/fp/map';
import fpflow from 'lodash/fp/flow';
import fpkeys from 'lodash/fp/keys';
import fpstartCase from 'lodash/fp/startCase';
import {
    createDefaultValue,
    Helpers,
    Paths,
    Test,
    encode,
    and,
    optionIs,
} from '@jsonforms/core';
import {
    DispatchCell,
    JsonFormsDispatch,
    withJsonFormsArrayControlProps,
} from '@jsonforms/react';
import { withVanillaControlProps } from '@jsonforms/vanilla-renderers';
import { Flex, Box } from '@rebass/grid/emotion';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslate } from '@mspecs/shared-utils';
import { Button } from '../../../../button';
import IconButtonComponent from '../../../../button/icon-button/icon-button-component';

const StyledTable = styled.table`
    width: 100%;
`;
const StyledTableRow = styled.tr`
    visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
`;
const StyledTableHeader = styled.th`
    text-align: left;
`;

const StyledTableCell = styled.td``;

const StyledAddButton = styled(Button)`
    color: #235681;
    cursor: pointer;
    align-self: flex-start;
    background-color: transparent;
    border: none;
    padding: 0;
    font-size: 13px;
    text-transform: none;
    :hover,
    :focus,
    :focus:active {
        color: inherit;
        background-color: inherit;
        box-shadow: none;
    }
`;
const AddButton = ({ onClick }) => {
    const { t } = useTranslate();
    return (
        <StyledAddButton
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }}
        >
            <FontAwesomeIcon icon={faAdd} />
            &nbsp;{t('ADD')}
        </StyledAddButton>
    );
};
AddButton.propTypes = {
    onClick: PropTypes.func,
};

const { convertToValidClassName } = Helpers;

const { or, isObjectArrayControl, isPrimitiveArrayControl, rankWith } = Test;

export const tableArrayControlTester = rankWith(
    4,
    and(
        or(isObjectArrayControl, isPrimitiveArrayControl),
        optionIs('format', 'table')
    )
);

export function TableArrayControl(props) {
    const {
        addItem,
        uischema,
        schema = {},
        path,
        data,
        visible,
        errors,
        getStyleAsClassName,
        renderers,
        enabled,
    } = props;

    const { t } = useTranslate();

    const controlElement = uischema;
    const tableClass = getStyleAsClassName('array.table.table');
    const validationClass = getStyleAsClassName('array.table.validation');
    const controlClass = [
        getStyleAsClassName('array.table'),
        convertToValidClassName(controlElement.scope),
    ].join(' ');
    const createControlElement = key => ({
        type: 'Control',
        label: false,
        scope: schema.type === 'object' ? `#/properties/${key}` : '#',
    });
    const isValid = errors.length === 0;
    const divClassNames = [validationClass]
        .concat(
            isValid ? '' : getStyleAsClassName('array.table.validation.error')
        )
        .join(' ');

    const getUiSchemaElement = prop => {
        const scope = `#/properties/${encode(prop)}`;

        return uischema.options.detail.elements.find(el => el.scope === scope);
    };

    const propFilter = prop =>
        schema.properties[prop].type !== 'array' &&
        getUiSchemaElement(prop) &&
        !getUiSchemaElement(prop).hidden;

    return (
        <div className={controlClass} hidden={!visible}>
            <div className={divClassNames}>{!isValid ? errors : ''}</div>
            <StyledTable className={tableClass}>
                <thead>
                    <tr>
                        {schema.properties ? (
                            fpflow(
                                fpkeys,
                                fpfilter(propFilter),
                                fpmap(prop => {
                                    const uiElement = getUiSchemaElement(prop);
                                    return (
                                        <StyledTableHeader key={prop}>
                                            {uiElement?.label
                                                ? t(uiElement.label)
                                                : fpstartCase(prop)}
                                        </StyledTableHeader>
                                    );
                                })
                            )(schema.properties)
                        ) : (
                            <th>Items</th>
                        )}
                        <th>&nbsp;</th>
                    </tr>
                </thead>
                <tbody>
                    {(data || []).map((_child, index) => {
                        const childPath = Paths.compose(path, `${index}`);

                        return (
                            <StyledTableRow
                                key={childPath}
                                hidden={_child.hidden}
                            >
                                {schema.properties ? (
                                    fpflow(
                                        fpkeys,
                                        fpfilter(propFilter),
                                        fpmap(prop => {
                                            const childPropPath = Paths.compose(
                                                childPath,
                                                prop.toString()
                                            );
                                            return (
                                                <StyledTableCell
                                                    key={childPropPath}
                                                >
                                                    <JsonFormsDispatch
                                                        schema={schema}
                                                        uischema={{
                                                            ...getUiSchemaElement(
                                                                prop
                                                            ),
                                                            label: false,
                                                        }}
                                                        path={childPath}
                                                        key={childPath}
                                                        renderers={renderers}
                                                    />
                                                </StyledTableCell>
                                            );
                                        })
                                    )(schema.properties)
                                ) : (
                                    <td
                                        key={Paths.compose(
                                            childPath,
                                            index.toString()
                                        )}
                                    >
                                        <DispatchCell
                                            schema={schema}
                                            uischema={createControlElement()}
                                            path={childPath}
                                        />
                                    </td>
                                )}

                                <td>
                                    {enabled && (
                                        <Box width="40px">
                                            <IconButtonComponent
                                                icon={faMinus}
                                                hiddenLabel="REMOVE"
                                                onClick={() => {
                                                    props.removeItems(path, [
                                                        index,
                                                    ])();
                                                }}
                                            />
                                        </Box>
                                    )}
                                </td>
                            </StyledTableRow>
                        );
                    })}
                </tbody>
            </StyledTable>
            {enabled && (
                <Flex alignItems="center" ml="auto">
                    <AddButton
                        onClick={addItem(path, createDefaultValue(schema))}
                    />
                </Flex>
            )}
        </div>
    );
}

export default withVanillaControlProps(
    withJsonFormsArrayControlProps(TableArrayControl)
);
