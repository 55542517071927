import { css } from '@emotion/react';
import { Box, Flex } from '@rebass/grid/emotion';
import { useCallback, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Modal, ConfirmationModal } from '../../modal';
import ImageEditor from './image-editor-component';
import ImageEditorFileInfo from './image-editor-file-info-component';
import ImageEditorNavigationButtons from './navigation/image-editor-navigation-buttons';
import ImageEditorModalSimpleEditing from './image-editor-modal-simple-editing-component';
import { useTranslate, useKeydown, base64ToBlob } from '@mspecs/shared-utils';
import ModalBackButton from '../../modal/header/modal-back-button-component';
import ModalCloseButton from '../../modal/header/modal-close-button-component';
import ModalHeaderText from '../../modal/header/modal-header-text-component';
import { LoadingAnimation } from '../../animations';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import { isScreen } from './utilities';
import { Button, BUTTON_TYPES, IconButton } from '../../button';
import {
    faChevronLeft,
    faChevronRight,
    faCompress,
} from '@fortawesome/pro-regular-svg-icons';
import { StackContainer } from '../..';
import { IImage } from './type';
import { ITheme } from 'apps/ui-components/src/types';
import { NavigationHeader, MobileImageView } from './mobile';
import { SwipeContainer } from '../../swipe-handler';

const CONFIRMATION_CLOSE = 1;
const CONFIRMATION_NEXT = 2;
const CONFIRMATION_PREVIOUS = 3;

const MetadataContainer = styled(Flex)`
    min-width: 238px;
    background: ${({ theme }) => theme.colors.bgSecondary};
    padding-top: ${({ theme }) => theme.spacing.default};
`;

const EditorHeader = styled(ModalHeaderText)`
    display: none;
    min-height: 64px;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px ${({ theme }) => theme.colors.borderPrimary};
    margin: 0 ${({ theme }) => theme.spacing.default};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: flex;
    }
`;

const StyledImageEditor = styled(ImageEditorModalSimpleEditing)`
    height: 300px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        height: calc(100% - (64px + 55px));
    }
`;

const GalleryContainer = styled.section`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    gap: 10px;

    padding: 30px 6px;
    background-color: ${({ theme }) => theme.colors.textPrimary};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        padding: 90px 102px;
        gap: 20px;
    }
`;

const StyledSwipeContainer = styled(SwipeContainer)`
    flex-grow: 1;
`;

const GalleryImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

const CloseGalleryContainer = styled.div`
    position: absolute;
    top: 14px;
    right: 14px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        top: 34px;
        right: 34px;
    }
`;

const StyledIconBtn = styled(IconButton)`
    color: ${({ theme }) => theme.colors.white};
`;

export interface INavigation {
    current: number;
    total: number;
    onNext: () => void;
    onPrevious: () => void;
}

export interface IImageModal {
    isOpen: boolean;
    onClose: () => void;
    onSave: (blob: Blob) => void;
    image: IImage;
    isLoading?: boolean;
    children?: React.ReactNode;
    onDelete?: () => void;
    navigationProps?: INavigation;
    hasSimpleEditing?: boolean;
    subTitle?: string;
    [key: string]: any;
}

const ImageEditorModal = ({
    isOpen,
    onClose,
    onSave,
    image,
    isLoading,
    children,
    onDelete,
    navigationProps,
    hasSimpleEditing = false,
    subTitle,
    ...props
}: IImageModal) => {
    const editorRef = useRef<any>(null);
    const { t } = useTranslate();

    const [states, setStates] = useState({
        isAdvancedEditing: false,
        isConfirmingChanges: false,
        isGalleryMode: false,
        isOptions: false,
    });
    const [hasChanges, setHasChanges] = useState(false);
    const [confirmationReason, setConfirmationReason] =
        useState<number>(CONFIRMATION_CLOSE);
    const screen = useCurrentBreakpoint();

    const onGalleryMode = () => {
        setStates(prev => ({ ...prev, isGalleryMode: true }));
    };

    const doClose = useCallback(() => {
        if (states.isAdvancedEditing) {
            setStates(prev => ({ ...prev, isAdvancedEditing: false }));
        } else {
            onClose();
        }
    }, [states.isAdvancedEditing]);
    useKeydown(doClose, 'Escape');

    const onSubmit = () => {
        const editor = editorRef.current.getInstance();
        const blob = base64ToBlob(editor.toDataURL());
        onSave(blob);
    };

    const onDownload = async () => {
        const blob = await fetch(image.originalURI).then(res => res.blob());
        const anchor = document.createElement('a');
        anchor.href = URL.createObjectURL(blob);
        anchor.download = image.title || 'image';
        anchor.click();
    };

    const onAbort = () => {
        if (!hasChanges) {
            doClose();
            return;
        }
        setConfirmationReason(CONFIRMATION_CLOSE);
        setStates(prev => ({ ...prev, isAdvancedEditing: false }));
    };

    const onConfirmed = () => {
        const callback = {
            [CONFIRMATION_CLOSE]: doClose,
            [CONFIRMATION_NEXT]: navigationProps?.onNext,
            [CONFIRMATION_PREVIOUS]: navigationProps?.onPrevious,
        }[confirmationReason];
        callback?.();
        setHasChanges(false);
        setStates(prev => ({ ...prev, isConfirmingChanges: false }));
    };

    return (
        <Modal
            isOpen={Boolean(isOpen && image)}
            onClose={onClose}
            size={'full-size'}
            contentClass={(theme: ITheme) => css`
                background-color: ${theme.colors.bgSecondary};
                padding: 0;
            `}
            modalClass={css`
                border-radius: 0px;
            `}
            renderBackButton={() => null}
            renderCloseButton={
                isScreen(['l', 'xl'], screen) && !states.isGalleryMode
                    ? () => <ModalCloseButton onClose={onClose} />
                    : () => null
            }
            {...props}
        >
            {isLoading ? (
                <Flex m="0 auto" alignItems="center">
                    <LoadingAnimation />
                </Flex>
            ) : (
                <>
                    <ConfirmationModal
                        headerText={t('IMAGE_EDITOR_CLOSE_CONFIRMATION_HEADER')}
                        isOpen={states.isConfirmingChanges}
                        onConfirm={onConfirmed}
                        infoText={t(
                            confirmationReason === CONFIRMATION_CLOSE
                                ? 'IMAGE_EDITOR_CLOSE_CONFIRMATION_INFO_TEXT'
                                : 'IMAGE_EDITOR_NAVIGATION_CONFIRMATION_INFO_TEXT'
                        )}
                        onCancel={() =>
                            setStates(prev => ({
                                ...prev,
                                isConfirmingChanges: false,
                            }))
                        }
                    />
                    {/* Meta data */}
                    {isScreen(['l', 'xl'], screen) &&
                        !states.isAdvancedEditing &&
                        !states.isGalleryMode && (
                            <MetadataContainer flexDirection="column">
                                {children}
                                <ImageEditorFileInfo image={image} />
                            </MetadataContainer>
                        )}
                    <Modal
                        isOpen={states.isOptions}
                        renderBackButton={() => null}
                        contentClass={css`
                            flex-direction: column;
                            padding-bottom: 20px;
                        `}
                    >
                        <NavigationHeader
                            label={subTitle}
                            onPrevious={() =>
                                setStates(prev => ({
                                    ...prev,
                                    isOptions: false,
                                }))
                            }
                        />
                        <Box
                            css={css`
                                padding: 10px 16px;
                                flex-grow: 1;
                            `}
                        >
                            {children}
                            <ImageEditorFileInfo image={image} />
                        </Box>
                        <Box
                            css={css`
                                padding: 0px 16px;
                            `}
                        >
                            <Button
                                buttonType={BUTTON_TYPES.DEFAULT}
                                onClick={() =>
                                    setStates(prev => ({
                                        ...prev,
                                        isOptions: false,
                                    }))
                                }
                                large
                                fluid
                            >
                                CLOSE
                            </Button>
                        </Box>
                    </Modal>

                    {hasSimpleEditing && !states.isAdvancedEditing ? (
                        !states.isGalleryMode ? (
                            isScreen(['l', 'xl'], screen) ? (
                                <Flex flexDirection="column" width="100%">
                                    <EditorHeader text={'IMAGE_EDIT'} />
                                    <StyledImageEditor
                                        image={image}
                                        onDelete={onDelete}
                                        onDownload={onDownload}
                                        onFullMode={onGalleryMode}
                                        setIsAdvancedEditing={(
                                            state: boolean
                                        ) =>
                                            setStates(prev => ({
                                                ...prev,
                                                isAdvancedEditing: state,
                                            }))
                                        }
                                    />
                                    {navigationProps && (
                                        <ImageEditorNavigationButtons
                                            {...navigationProps}
                                            onNext={() => {
                                                if (hasChanges) {
                                                    setStates(prev => ({
                                                        ...prev,
                                                        isConfirmingChanges:
                                                            true,
                                                    }));
                                                    setConfirmationReason(
                                                        CONFIRMATION_NEXT
                                                    );
                                                } else {
                                                    navigationProps.onNext();
                                                }
                                            }}
                                            onPrevious={() => {
                                                if (hasChanges) {
                                                    setStates(prev => ({
                                                        ...prev,
                                                        isConfirmingChanges:
                                                            true,
                                                    }));
                                                    setConfirmationReason(
                                                        CONFIRMATION_PREVIOUS
                                                    );
                                                } else {
                                                    navigationProps.onPrevious();
                                                }
                                            }}
                                        />
                                    )}
                                </Flex>
                            ) : (
                                <MobileImageView
                                    image={image}
                                    label={subTitle}
                                    navigation={navigationProps}
                                    onDelete={onDelete}
                                    onDownload={onDownload}
                                    onFullMode={onGalleryMode}
                                    onBack={onAbort}
                                    setAdvancedEditing={() =>
                                        setStates(prev => ({
                                            ...prev,
                                            isAdvancedEditing: true,
                                        }))
                                    }
                                    setOptions={() =>
                                        setStates(prev => ({
                                            ...prev,
                                            isOptions: true,
                                        }))
                                    }
                                />
                            )
                        ) : (
                            <GalleryContainer>
                                <CloseGalleryContainer>
                                    <StyledIconBtn
                                        icon={faCompress}
                                        buttonType={BUTTON_TYPES.TEXT}
                                        hiddenLabel={'FULL_SCREEN'}
                                        onClick={() =>
                                            setStates(prev => ({
                                                ...prev,
                                                isGalleryMode: false,
                                            }))
                                        }
                                    />
                                </CloseGalleryContainer>
                                {navigationProps &&
                                    isScreen(['l', 'xl'], screen) && (
                                        <StackContainer alignItems="center">
                                            <StyledIconBtn
                                                icon={faChevronLeft}
                                                buttonType={BUTTON_TYPES.TEXT}
                                                onClick={
                                                    navigationProps.onPrevious
                                                }
                                                hiddenLabel={'PREVIOUS'}
                                            />
                                        </StackContainer>
                                    )}
                                <StyledSwipeContainer
                                    onSwipeLeft={navigationProps?.onNext}
                                    onSwipeRight={navigationProps?.onPrevious}
                                >
                                    <GalleryImage
                                        src={image.viewURI || image.originalURI}
                                    />
                                </StyledSwipeContainer>
                                {navigationProps &&
                                    isScreen(['l', 'xl'], screen) && (
                                        <StackContainer alignItems="center">
                                            <StyledIconBtn
                                                icon={faChevronRight}
                                                buttonType={BUTTON_TYPES.TEXT}
                                                onClick={navigationProps.onNext}
                                                hiddenLabel={'NEXT'}
                                            />
                                        </StackContainer>
                                    )}
                            </GalleryContainer>
                        )
                    ) : (
                        <ImageEditor
                            key={image?.originalURI}
                            image={image}
                            subTitle={subTitle}
                            ref={editorRef}
                            editorProps={{
                                onUndoStackChanged: (length: number) =>
                                    setHasChanges(length > 0),
                            }}
                            displayLoader
                            isFullscreen={states.isAdvancedEditing}
                            onClose={onAbort}
                            onSave={onSubmit}
                        >
                            {/* <ImageEditorToolbar
                                onSubmit={onSubmit}
                                onClose={onAbort}
                                onDelete={
                                    !isAdvancedEditing ? onDelete : undefined
                                }
                                isSubmitActive={hasChanges}
                            /> */}
                        </ImageEditor>
                    )}
                </>
            )}
        </Modal>
    );
};

export default ImageEditorModal;
