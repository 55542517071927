import styled from '@emotion/styled';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { IconButton, BUTTON_TYPES } from '../../../button';
import { useTranslate } from '@mspecs/shared-utils';

const Container = styled(Flex)`
    justify-content: center;
    align-items: center;
    padding: 10px;
    height: 55px;
`;

const Status = styled(Flex)`
    justify-content: center;
    padding: 0 5px;
`;

const NavigationButton = styled(IconButton)`
    font-size: 20px;
`;

const ImageEditorNavigationButtons = ({
    onNext,
    onPrevious,
    current,
    total,
}) => {
    return (
        <Container>
            <NavigationButton
                onClick={onPrevious}
                icon={faChevronLeft}
                buttonType={BUTTON_TYPES.TEXT}
                hiddenLabel={'PREVIOUS'}
            />
            <Status>{`${current} / ${total}`}</Status>
            <NavigationButton
                onClick={onNext}
                icon={faChevronRight}
                buttonType={BUTTON_TYPES.TEXT}
                hiddenLabel={'NEXT'}
            />
        </Container>
    );
};

ImageEditorNavigationButtons.propTypes = {
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    current: PropTypes.number,
    total: PropTypes.number,
};

export default ImageEditorNavigationButtons;
