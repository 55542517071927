import PropTypes from 'prop-types';

interface MainItem {
    label: string;
    icon?: Element;
    path?: string;
    element?: Element;
    subItems: SubItem[];
}
interface SubItem {
    label: string;
    path: string;
    icon?: Element;
    element?: Element;
    subItems?: SubItem[];
}

export const createMainItem = (
    label: string,
    {
        icon,
        path,
        element,
        subItems = [],
        onClick,
        hideStatus,
    }: {
        icon?: Element;
        path?: string;
        element?: Element;
        subItems?: SubItem[];
        onClick?: () => void;
        hideStatus?: Boolean;
    }
) => {
    return {
        label,
        icon,
        path,
        element,
        subItems,
        onClick,
        hideStatus,
    };
};
export const createSubItem = (
    label: string,
    path: string,
    {
        icon,
        element,
        subItems,
    }: { icon?: Element; element?: Element; subItems?: SubItem[] } = {}
): SubItem => {
    const subItem = {
        label,
        icon,
        path,
        element,
    };
    return {
        ...subItem,
        subItems: (subItems || []).map(thirdLevel => ({
            ...thirdLevel,
            parent: subItem,
        })),
    };
};

export const MenuItemPropType = PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.node,
    element: PropTypes.node,
    subItems: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            path: PropTypes.string,
            icon: PropTypes.node,
            element: PropTypes.node,
        })
    ),
});

interface Route {
    path: string;
    element?: Element;
}
export const convertMenuItemsToRoutes = (menuItems: MainItem[]) => {
    return menuItems.reduce((acc: Route[], item: MainItem) => {
        if (item.path) {
            acc.push({ path: item.path, element: item.element });
        }
        (item.subItems || []).forEach(subItem => {
            acc.push({ path: subItem.path, element: subItem.element });
            (subItem.subItems || []).forEach(thirdLevelItem => {
                acc.push({
                    path: thirdLevelItem.path,
                    element: thirdLevelItem.element,
                });
            });
        });
        return [...acc];
    }, []);
};

export const isMathchingDynamicPath = (path: string, itemPath: string) => {
    if (path === itemPath) return true;
    if (itemPath.endsWith('/:id')) {
        return path
            .split('/')
            .slice(0, -1)
            .join('/')
            .endsWith(itemPath.replace('/:id', ''));
    }

    return false;
};
