import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Text = styled.p`
    font-family: Inter;
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.textPrimary};
`;

export const TEXT_STYLES = {
    bodyText: theme => css`
        font-family: Inter;
        font-weight: 400;
        font-size: 13px;
        line-height: 21px;
        @media (min-width: ${theme.responsiveBreakpoints.m}) {
            font-size: 14px;
        }
    `,
    highlightedBodyText: theme => css`
        font-family: Inter;
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        @media (min-width: ${theme.responsiveBreakpoints.m}) {
            font-size: 14px;
        }
    `,
    subTextMedium: theme => css`
        font-family: Inter;
        font-size: 11px;
        font-weight: 500;
        line-height: 16.5px;
    `,
    helpingTextMedium: theme => css`
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 21px;
        @media (min-width: ${theme.responsiveBreakpoints.m}) {
            font-size: 13px;
        }
    `,
    helpingText: theme => css`
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        @media (min-width: ${theme.responsiveBreakpoints.m}) {
            font-size: 13px;
        }
    `,
    buttonTextSmall: theme => css`
        font-family: Inter;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.5px;
        letter-spacing: 0.26px;
    `,
    header3Text: theme => css`
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
    `,
    header5Text: theme => css`
        font-size: 16px;
        font-weight: 500;
        line-height: 19.52px;
    `,
};

export const BodyTextRegular = styled(Text)`
    ${({ theme }) => TEXT_STYLES.bodyText(theme)}
`;
export const HighlightedBodyText = styled(Text)`
    ${({ theme }) => TEXT_STYLES.highlightedBodyText(theme)}
`;
export const HighlightedBodyTextBold = styled(HighlightedBodyText)`
    font-weight: 500;
`;

export const ButtonTextNormal = styled(HighlightedBodyText)`
    letter-spacing: 0.28px;
`;
export const ButtonTextSmall = styled(Text)`
    ${({ theme }) => TEXT_STYLES.buttonTextSmall(theme)}
`;

export const SubTextMedium = styled(Text)`
    ${({ theme }) => TEXT_STYLES.subTextMedium(theme)}
`;

export const HelpingTextMedium = styled(Text)`
    ${({ theme }) => TEXT_STYLES.helpingTextMedium(theme)}
`;
export const HelpingText = styled(Text)`
    ${({ theme }) => TEXT_STYLES.helpingText(theme)}
`;

export const Header1Text = styled(Text)`
    font-size: 18.5px;
    line-height: 22.2px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        font-size: 24.8px;
        line-height: 29.76px;
    }
`;
export const Header3Text = styled(Text)`
    ${({ theme }) => TEXT_STYLES.header3Text(theme)}
`;

export const Header4Text = styled(Text)`
    font-size: 18.5px;
    font-weight: 400;
    line-height: 22.2px;
`;

export const Header5Text = styled(Text)`
    ${({ theme }) => TEXT_STYLES.header5Text(theme)}
`;
