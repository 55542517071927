import React from 'react';
import { css, Global } from '@emotion/react';

import { ColorsCssVariables } from '@mspecs/shared-utils';
import { datePickerStyles } from '@mspecs/ui-components';
import { colorsThemeSet } from '@mspecs/shared-utils/src/styles/theme-color-set';

export default function GlobalStyles() {
    const mainStyles = css`
        :root {
            --light: 0%;
            ${Object.entries(colorsThemeSet['default'])
                .map(([key, value]) => `${key}: ${value};`)
                .join('')}
            ${Object.entries(ColorsCssVariables)
                .map(([key, value]) => `${key}: ${value};`)
                .join('')}
        }
    `;

    return <Global styles={[mainStyles, datePickerStyles]} />;
}
