import React from 'react';
import styled from '@emotion/styled';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import { TopToolbar } from '../layout';

const StyledHeadline = styled.h1`
    margin: ${({ tableHeader }) => (tableHeader ? '0' : '0.75em 0 1em')};
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    padding-top: 10px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        margin: ${({ tableHeader }) => (tableHeader ? '0' : '0 0 1em')};
    }
`;

const StyledAddButton = styled(TopToolbar.AddButton)`
    margin-left: auto;
`;
const NavigationHeadline = ({ label, onAdd, ...rest }) => {
    const { t } = useTranslate();
    return (
        <Flex>
            <StyledHeadline {...rest}>{t(label)}</StyledHeadline>
            {onAdd && <StyledAddButton onClick={onAdd} label={t(label)} />}
        </Flex>
    );
};
export default NavigationHeadline;
