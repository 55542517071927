// @ts-nocheck
import { ac } from '@mspecs/shared-utils';
import { arrayToObj } from '@mspecs/shared-utils';
import { legacyApi } from '@mspecs/shared-utils';
import { displaySuccessToast, displayErrorToast } from '~/features/toast';

export const CUSTOMER_PAGE_ACTION_TYPES = {
    GET_FORM_DATA: 'GET_FORM_DATA',
    GET_ALL_FORM_DATA: 'GET_ALL_FORM_DATA',
    GET_FORM_INVITATION: 'GET_FORM_INVITATION',
    GET_FORM_INVITATIONS: 'GET_FORM_INVITATIONS',
    UPDATE_FORM_INVITATION: 'UPDATE_FORM_INVITATION',
    ADD_FORM_INVITATION: 'ADD_FORM_INVITATION',
    TOGGLE_FORM_INVITATION_CONTACT: 'TOGGLE_FORM_INVITATION_CONTACT',
    REMOVE_FORM_INVITATION_CONTACT: 'REMOVE_FORM_INVITATION_CONTACT',
    GENERATE_TOKEN: 'GENERATE_TOKEN',
    SET_LOADING: 'SET_LOADING',
    MERGE_FORM_DATA: 'MERGE_FORM_DATA',
    SET_SELECTED_FORM: 'SET_SELECTED_FORM',
    SET_SELECTED_FORM_INDEX: 'SET_SELECTED_FORM_INDEX',
};

const startLoading = () => ac(CUSTOMER_PAGE_ACTION_TYPES.SET_LOADING, true);

const successRemoveFormInvitationContact = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.REMOVE_FORM_INVITATION_CONTACT, payload);

const successToggleFormInvitationContact = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.TOGGLE_FORM_INVITATION_CONTACT, payload);

const successUpdateFormInvitation = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.UPDATE_FORM_INVITATION, {
        formInvitation: payload,
    });

const successAddedFormInvitation = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.ADD_FORM_INVITATION, payload);

const successGenerateToken = (payload, sellerId) =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.GENERATE_TOKEN, {
        [sellerId]: payload.loginUrl,
    });

const successGetFormInvitation = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.GET_FORM_INVITATIONS, payload);

const successGetFormInvitations = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.GET_FORM_INVITATION, {
        formInvitationContacts: payload.formInvitationContacts,
        formInvitations: payload.formInvitations,
        forms: payload.forms,
    });

const sucessGetFormData = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.GET_FORM_DATA, {
        formData: payload.formData,
    });

const sucessGetAllFormData = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.GET_ALL_FORM_DATA, payload);

export const setSelectedFormIndex = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.SET_SELECTED_FORM_INDEX, payload);

//mergForm
export const sucessMergeForm = payload =>
    ac(CUSTOMER_PAGE_ACTION_TYPES.MERGE_FORM_DATA, payload);

const apiV2 = (route, param) => legacyApi(`/v2/customerpage/${route}/${param}`);
const apiV3 = (route, param) => legacyApi(`/v3/customerPage/${route}/${param}`);
const invitationApi = param => legacyApi(`/formInvitations/${param}`);

const invitationContactApi = param =>
    legacyApi(`/formInvitationContacts/${param}`);

export const toggleViewMergedStatus =
    (formInvitationId, mergeStatus) => async dispatch => {
        const result = await apiV2('viewStatus', formInvitationId).put({
            mergeStatus: [mergeStatus],
        });

        dispatch(successUpdateFormInvitation(result));
    };

export const toggleFormInvitationContact = (
    seller,
    formInvitationContact,
    formInvitations,
    session,
    dealId
) => {
    let newFormInvitations = [];

    return async dispatch => {
        if (!seller.isChecked) {
            let brandNew;
            if (Object.keys(formInvitations).length === 0) {
                brandNew = await Promise.all(
                    seller.forms.map(form => {
                        return postNewFormInvitation(form, session, dealId);
                    })
                );
            }
            let formInvs = !!brandNew
                ? arrayToObj(brandNew, { key: 'formId' })
                : formInvitations;
            await Promise.all(
                Object.keys(formInvs).map(key => {
                    let formInvitation = formInvs[key];
                    let alreadyExitingInvite =
                        seller.formInvitationContact.find(
                            e => e.formInvitation == formInvitation.id
                        );
                    if (!alreadyExitingInvite) {
                        return invitationContactApi('')
                            .post({
                                createdByUserId: session.id,
                                contactId: seller.seller.contactId,
                                formInvitation: formInvitation.id,
                            })
                            .then(payload => {
                                newFormInvitations.push(payload);
                            });
                    }
                })
            );
            !!brandNew && dispatch(successGetFormInvitation(formInvs));
            dispatch(successToggleFormInvitationContact(newFormInvitations));
        } else {
            await Promise.all(
                seller.formInvitationContact.map(contact => {
                    return invitationContactApi(contact.id)
                        .delete()
                        .then(() => {
                            newFormInvitations.push(contact.id);
                        });
                })
            );

            dispatch(successRemoveFormInvitationContact(newFormInvitations));
        }
    };
};

export const putFormInvitation = (id, payload, dispatchFunc) => {
    return async dispatch => {
        const response = await invitationApi(id).put(payload);
        dispatch(dispatchFunc(response));
    };
};

export const addNewFormOntoggle = (sellerId, dealId, payload, dispatchFunc) => {
    return async dispatch => {
        return apiV2('toggleInvitationForDeal', `${dealId}/${sellerId}`)
            .post(payload)
            .then(response => dispatchFunc && dispatch(dispatchFunc(response)));
    };
};

export const postFormInvitation = async payload => {
    return await invitationApi('').post(payload);
};

export const updateFormInvitationStatus = (
    formInvitation,
    newStatus,
    resetMergedViews = false
) => {
    return putFormInvitation(
        formInvitation.id,
        {
            formStatus: newStatus,
            ...(resetMergedViews ? { viewsMerged: [] } : {}),
        },
        successUpdateFormInvitation
    );
};

export const postNewFormInvitation = async (form, session, dealId) => {
    return postFormInvitation({
        createdByUserId: session.id,
        dealId: dealId,
        formId: form.id,
        formStatus: 'ENUM_FORM_INVITATIONS_FORM_STATUS_OPEN',
        viewsMerged: [],
    });
};

export const toggleFormInvitation = (form, sellerId, dealId) => {
    if (!form.formInvitation.id) {
        return addNewFormOntoggle(
            sellerId,
            dealId,
            {
                isHidden: !form.formInvitation.isHidden,
                formId: form.form.id,
            },
            successAddedFormInvitation
        );
    }

    return putFormInvitation(
        form.formInvitation.id,
        {
            isHidden: !form.formInvitation.isHidden,
        },
        successUpdateFormInvitation
    );
};

export const toggleMultipleFormInvitations = async forms => {
    await Promise.all(
        forms.map(form => {
            return putFormInvitation(
                form.id,
                {
                    isHidden: !form.isHidden,
                },
                successUpdateFormInvitation
            );
        })
    );
};

export const getFormInvitations = dealId => {
    return async dispatch => {
        const payload = await apiV2('invitationsForDeal', dealId).get();
        dispatch(successGetFormInvitations(payload));
    };
};

export const generateToken = customerPageSeller => {
    return async dispatch => {
        const payload = await apiV2(
            'createToken',
            customerPageSeller.seller.id
        ).get();
        dispatch(successGenerateToken(payload, customerPageSeller.seller.id));
    };
};

export const getFormData = (data, index) => {
    return async dispatch => {
        dispatch(startLoading());
        // const formData = await apiV2(`${dealId}/form`, data.formId).get();

        dispatch(setSelectForm({ selectedFormIndex: index }));
        const formData = await legacyApi(
            `/v3/customerPage/compareData/${data.id}`
        ).get();
        dispatch(sucessGetFormData({ formData: formData }));
    };
};

export const getAllFormData = data => {
    return async dispatch => {
        dispatch(startLoading());
        const formData = await Promise.all(
            data.map(async form => {
                return legacyApi(
                    `/v3/customerPage/compareData/${form.formInvitation.id}`
                ).get();
            })
        );
        dispatch(sucessGetAllFormData(formData));
    };
};

export const setSelectedForm = subForm => {
    return async dispatch => {
        dispatch(
            setSelectForm({
                selectedSubForm: subForm,
            })
        );
    };
};

export const mergeForm = (dealId, form, formInvitation, data) => {
    return async dispatch => {
        dispatch(startLoading());
        await legacyApi(
            `/v3/customerpage/submit/${dealId}/form/${form.form.id}/${formInvitation.id}`
        ).put(data);
        dispatch(sucessMergeForm(form.form.id));
        window.PubSub.publish(CUSTOMER_PAGE_ACTION_TYPES.MERGE_FORM_DATA, {});

        // displaySuccess(
        //     `${t('ENUM_FORM_INVITATIONS_FORM_STATUS_MERGED').toLowerCase()}!`
        // )(dispatch);
        // return await getFormInvitations(dealId)(dispatch);
    };
};

export const sendInvitations = (dealId, sellerIds) => {
    return async dispatch => {
        const response = await apiV3('sendInvitations', dealId).post({
            sellerIds,
        });
    };
};

export const updateAndResendFormInvitation =
    formInvitationId => async dispatch => {
        try {
            const formInvitation = await apiV3('resend', formInvitationId).put(
                {}
            );
            dispatch(successUpdateFormInvitation(formInvitation));
            displaySuccessToast({
                title: 'SUCCESS',
                body: 'EMAIL_SENDING_SUCCESS',
            });
        } catch {
            displayErrorToast({
                title: 'ERROR',
                body: 'EMAIL_SENDING_FAILD',
            });
        }
    };
