import { compose, branch } from 'recompose';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { fromUnixTime, isValid } from 'date-fns';
import format from 'date-fns/format';
import { sv, enGB } from 'date-fns/locale';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import DatepickerInput from './date-picker-input-component';
import { useTranslate, usePortal } from '@mspecs/shared-utils';
import { withInputValidation, withFinalFormField } from '../../../../utils/hoc';
import { withValidationWrapper } from '../../../../utils/hoc/validation-wrapper-component';
import { faClock } from '@fortawesome/pro-regular-svg-icons';

const CONSTANTS = {
    DATE: 'date',
    STRING: 'string',
    DATE_TIME: 'datetime',
};

/**
 * Get what type the input value is. Used for formatting the value
 */
function getInputType(date) {
    if (!date) {
        return null;
    } else if (typeof date === 'string') {
        return CONSTANTS.STRING;
    } else if (Object.prototype.toString.call(date) === '[object Date]') {
        return CONSTANTS.DATE;
    }
}

function formatDate(date, schemaFormat, dateFormat) {
    if (!date) return null;
    if (schemaFormat) {
        return schemaFormat === 'datetime'
            ? date.toJSON()
            : format(date, 'yyyy-MM-dd');
    } else {
        return format(date, dateFormat);
    }
}

const PortalContainer = ({ children }) => {
    const portal = usePortal('datepicker-portal');
    return createPortal(children, portal);
};

function parseDate(value) {
    let date;
    if (typeof value === 'number') {
        date = fromUnixTime(value);
    } else if (typeof value === 'string') {
        date = new Date(value);
    }

    return isValid(date) ? date : null;
}

const locales = {
    sv,
    en: enGB,
};

const offsetModifiers = [
    {
        name: 'offset',
        options: {
            offset: [0, -10],
        },
    },
];

const onlyTimeModifiers = ref => [
    ...offsetModifiers,
    {
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
            if (!ref.current) return;
            state.styles.popper.width = `${ref.current.input.offsetWidth}px`;
        },
        phase: 'beforeWrite',
    },
];

export const DatePicker = ({
    value,
    onChange,
    schemaFormat,
    translateFormat,
    outputFormat,
    showTimeSelectOnly,
    timeIntervals,
    ...restProps
}) => {
    const { t, language } = useTranslate();
    const inputRef = useRef();

    const [selectedDate, setSelectedDate] = useState(parseDate(value));
    const format = outputFormat || getInputType(selectedDate);
    const locale = useMemo(
        () => locales[language] || locales.sv,
        [language, locales]
    );

    useEffect(() => {
        setSelectedDate(parseDate(value));
    }, [value]);

    /**
     * Get the format string
     */
    const dateFormat = translateFormat
        ? t(translateFormat, restProps.dateFormat).replace(/Y|D/g, x =>
              x.toLowerCase()
          )
        : restProps.dateFormat;

    function _onChange(date) {
        setSelectedDate(date);
        onChange && onChange(formatOutput(date));
    }

    function formatOutput(date) {
        switch (format) {
            case CONSTANTS.STRING: {
                return formatDate(date, schemaFormat, dateFormat);
            }
            case CONSTANTS.DATE: {
                return date;
            }
            default: {
                return date;
            }
        }
    }

    return (
        <ReactDatePicker
            {...(schemaFormat === CONSTANTS.DATE_TIME
                ? { showTimeSelect: true, timeFormat: 'HH:mm' }
                : {})}
            showTimeSelectOnly={showTimeSelectOnly}
            popperContainer={PortalContainer}
            popperModifiers={
                showTimeSelectOnly
                    ? onlyTimeModifiers(inputRef)
                    : offsetModifiers
            }
            {...restProps}
            preventOpenOnFocus
            onChange={_onChange}
            locale={locale}
            timeCaption={t('TIME')}
            dateFormat={dateFormat}
            selected={selectedDate}
            timeIntervals={timeIntervals}
            ref={inputRef}
            customInput={
                <DatepickerInput
                    {...restProps}
                    dateIcon={showTimeSelectOnly ? faClock : undefined}
                />
            }
        />
    );
};

DatePicker.propTypes = {
    onChange: PropTypes.func,
    dateFormat: PropTypes.string,
    outputFormat: PropTypes.oneOf([CONSTANTS.STRING, CONSTANTS.DATE]),
    value: PropTypes.string,
    schemaFormat: PropTypes.string,
    translateFormat: PropTypes.string,
    timeIntervals: PropTypes.number,
};

DatePicker.defaultProps = {
    dateFormat: 'yyyy-MM-dd',
    outputFormat: 'string',
};

export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(DatePicker);
