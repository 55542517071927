import React from 'react';
import PropTypes from 'prop-types';
import {
    faLockKeyhole,
    faLockKeyholeOpen,
} from '@fortawesome/pro-regular-svg-icons';
import styled from '@emotion/styled';
import IconButton from './icon-button-component';

const PadlockButton = styled(IconButton)`
    background-color: ${({ theme }) => theme.colors.blue};
    color: ${({ theme }) => theme.colors.bgPrimary};
`;

const LockButton = ({ locked, setLocked, ...rest }) => {
    const handleToggle = e => {
        e.stopPropagation();
        setLocked(lockState => !lockState);
    };

    return (
        <PadlockButton
            icon={locked ? faLockKeyhole : faLockKeyholeOpen}
            onClick={handleToggle}
            hiddenLabel
            {...rest}
        />
    );
};

LockButton.propTypes = {
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func,
};

export default LockButton;
