import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faRotateLeft,
    faRotateRight,
    faSliders,
} from '@fortawesome/pro-regular-svg-icons';
import { ImageProp } from '../image.props';
import IconButton from '../../button/icon-button/icon-button-component';
import { Button, BUTTON_TYPES } from '../../button';
import ImageEditorToolbar from './image-editor-toolbar-component';
import { useTranslate } from '@mspecs/shared-utils';
const ROTATION_VALUE = 90;

const Container = styled(Flex)`
    position: relative;
    width: 100%;

    flex-direction: column;
    justify-content: flex-end;
`;

const StyledImageEditorToolbar = styled(ImageEditorToolbar)`
    padding: 10px 0px;
`;

const SimpleImageContainer = styled(Flex)`
    overflow: hidden;
    padding: 10px;
    justify-content: center;
    height: 100%;
`;

const SimpleImage = styled.img`
    object-fit: contain;
    max-width: ${({ rotation }) =>
        [ROTATION_VALUE, ROTATION_VALUE * 3].some(r => r === rotation)
            ? `calc(100vh - 230px)`
            : `100%`};
    overflow: hidden;
    transform: rotate(${({ rotation }) => rotation}deg);
`;

const RotateButton = styled(IconButton)`
    margin-right: 5px;
`;

const AdvancedButton = styled(Button)`
    display: flex;
    gap: 6px;
    padding-left: 12px;
    padding-right: 20px;
`;

const ContainerButton = styled(Flex)`
    gap: 10px;
`;

// Temp flag before implementing funcitonality
const DISPLAY_ROTATION_BUTTONS = false;

const ImageEditorModalSimpleEditing = ({
    image,
    onDelete,
    onDownload,
    onFullMode,
    setIsAdvancedEditing,
    ...props
}) => {
    const { t } = useTranslate();
    const [rotation, setRotation] = useState(0);

    const onRotateLeft = () => {
        if (rotation === 0) {
            setRotation(360 - ROTATION_VALUE);
        } else {
            const newRotation =
                rotation - ROTATION_VALUE <= 0 ? 0 : rotation - ROTATION_VALUE;
            setRotation(newRotation);
        }
    };
    const onRotateRight = () => {
        const newRotation =
            rotation + ROTATION_VALUE >= 360 ? 0 : rotation + ROTATION_VALUE;
        setRotation(newRotation);
    };

    return (
        <Container {...props}>
            <StyledImageEditorToolbar
                onSubmit={() => {}}
                onClose={() => {}}
                onDelete={onDelete}
                onFullMode={onFullMode}
                isSubmitActive={rotation !== 0}
                onDownload={onDownload}
            >
                <ContainerButton>
                    {DISPLAY_ROTATION_BUTTONS && (
                        <>
                            <RotateButton
                                icon={faRotateLeft}
                                onClick={onRotateLeft}
                            />
                            <RotateButton
                                icon={faRotateRight}
                                onClick={onRotateRight}
                            />
                        </>
                    )}

                    <AdvancedButton
                        buttonType={BUTTON_TYPES.PRIMARY}
                        small
                        onClick={() => setIsAdvancedEditing(true)}
                    >
                        <FontAwesomeIcon icon={faSliders} />

                        {t('EDIT')}
                    </AdvancedButton>
                </ContainerButton>
            </StyledImageEditorToolbar>

            <SimpleImageContainer>
                <SimpleImage
                    src={image.viewURI || image.originalURI}
                    rotation={rotation}
                />
            </SimpleImageContainer>
        </Container>
    );
};

ImageEditorModalSimpleEditing.propTypes = {
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    image: ImageProp,
    setIsAdvancedEditing: PropTypes.func,
};

export default ImageEditorModalSimpleEditing;
