/* eslint-disable */
export const white = 'hsl(0 0% calc(100% + calc(var(--light))))'; // #fff
export const black = 'hsl(0 0% 0%)'; //#000

export const gray120 = 'hsl(0 0% 73.3%)'; //#bbb
export const gray100 = 'hsl(0 0% 80%)'; //#ccc
export const gray80 = 'hsl(0 0% 86.7%)'; //#ddd

export const bgPrimary = 'var(--bg-primary)'; // #fff
export const bgPrimarySemiTransparent = 'var(--bg-primary-semi-transparent)'; //rgba(255, 255, 255, 0.75)
export const bgSecondary = 'var(--bg-secondary)'; //#f8f8f8
export const bgSecondary80 = 'var(--bg-secondary-80)'; //#fbfbfb
export const bgSecondary120 = 'var(--bg-secondary-120)'; //#F2F2F2
export const bgSecondary140 = 'var(--bg-secondary-140)'; //#EAEAEA
export const borderPrimary = gray80; //gray80
export const borderSecondary = gray100; //gray100
export const textPrimary = 'var(--text-primary)'; //#211F20
export const textSecondary = 'var(--text-secondary)'; //#727272
export const contrastBgPrimary = 'var(--contrast-bg-primary)'; //#051C30
export const contrastBg105 = 'var(--contrast-bg-105)'; //#041929 base 206
export const contrastBg95 = 'var(--contrast-bg-95)'; //#07243E
export const contrastBg90 = 'var(--contrast-bg-90)'; //#092C4C base 209
export const contrastBg85 = 'var(--contrast-bg-85)'; //#274056
export const contrastBg75 = 'var(--contrast-bg-75)'; //#385773
export const contrastBg65 = 'var(--contrast-bg-65)'; //#4B7090
export const contrastBg55 = 'var(--contrast-bg-55)'; //#6E8BA5
export const contrastBg50 = 'var(--contrast-bg-50)'; //#90A6BA base 209

export const primaryColor = 'var(--primary-color)'; //#0F4C81
export const primaryColor90 = 'var(--primary-color-90)'; //#275E8E
export const primaryColor80 = 'var(--primary-color-80)'; // #3F709A
export const primaryColor60 = 'var(--primary-color-60)'; // #6F94B3 base 207
export const primaryColor40 = 'var(--primary-color-40)'; // #9FB7CD base 209
export const primaryColor20 = 'var(--primary-color-20)'; // #CFDBE6 base 209
export const primaryColor15 = 'var(--primary-color-15)'; // #DBE4EC
export const primaryColor10 = 'var(--primary-color-10)'; // #E7EDF2 base 207
export const primaryColor5 = 'var(--primary-color-5)'; // #F3F6F9 base 210

export const greenPrimaryColor = 'var(--green-primary-color)'; // #0F813D base 144
export const green80 = 'var(--green-80)'; // #609D50
export const green60 = 'var(--green-60)'; // #7DB36F
export const green40 = 'var(--green-40)'; // #A8CD9F
export const green20 = 'var(--green-20)'; // #CFE3CA
export const green15 = 'var(--green-15)'; // #DEECDB base 109
export const green7 = 'var(--green-7)'; // #F0F6EE base 105

export const accentPrimary = 'var(--accent-primary)'; // #3CA2DB base 202
export const accent50 = 'var(--accent-50)'; // #9DD1ED
export const accent20 = 'var(--accent-20)'; // #CEE8F6

export const redPrimary = 'var(--red-primary)'; // #BB1414
export const red105 = 'var(--red-105)'; // #B11212
export const red110 = 'var(--red-110)'; // #A71111
export const red40 = 'var(--red-40)'; // #E4A1A1
export const red20 = 'var(--red-20)'; // #F1D0D0
export const red10 = 'var(--red-10)'; // #F8E7E7

export const pendingPrimary = 'var(--pending-primary)'; // #DC793D
export const pending40 = 'var(--pending-40)'; // #F1C9B1
export const pending20 = 'var(--pending-20)'; // #F8E4D8
export const pending10 = 'var(--pending-10)'; // #FBF2EC base 24

export const highlightingBgPrimary = 'var(--highlighting-bg-primary)'; // #FFFFB3
export const highlightingBg50 = 'var(--highlighting-bg-50)'; // #FFFFD9
export const highlightingBg25 = 'var(--highlighting-bg-25)'; // #FFFFEC

export const errorColor = redPrimary; //for: errors like input border, red alert bottom of the page, error icon'
export const warningColor = pendingPrimary; //for: warnings, to mark field in filter, bid text in deal/estate overview
export const successColor = green60; //for: success alert, success alert bottom of the page, check ok icon
export const linkColor = primaryColor;
export const boxShadowColor = 'hsla(0 0% 0% / 0.1)'; //rgb(0 0 0 / 10%)
