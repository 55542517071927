import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-regular-svg-icons';
import { useTheme } from '@emotion/react';
import FileUploadDropzone from './file-upload-dropzone-component';
import { useTranslate } from '@mspecs/shared-utils';
import { AnimatedCloseButton } from '../../button';
const CenteredFullHeightFlex = styled(Flex)`
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const DropzoneOverlay = styled(CenteredFullHeightFlex)`
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(230, 236, 243, 0.95);
    z-index: 100;
`;

const DropzoneArea = styled(CenteredFullHeightFlex)``;

const OverlayIconContainer = styled(CenteredFullHeightFlex)`
    max-height: 200px;
    max-width: 200px;
    background-color: rgba(255, 255, 255, 0.6);
    border: dashed 1px ${({ theme }) => theme.colors.borderSecondary};
`;

const OverlayIcon = styled(FontAwesomeIcon)`
    font-size: 3.5rem;
    color: #888;
`;
const LinkText = styled.span`
    color: ${({ theme }) => theme.colors.primaryColor};
    text-transform: lowercase;
    white-space: pre;
`;

const ClosedButton = styled(AnimatedCloseButton)`
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 101;
`;

const DropText = () => {
    const { t } = useTranslate();
    return (
        <p>
            {`${t('DRAG_AND_DROP_IMAGES_OR')} `}
            <LinkText>{t('CLICK_HERE')}</LinkText>
        </p>
    );
};

const ToggableFullscreenFileUploadDropzone = ({ onDrop, ...props }) => {
    const [isOverlay, setIsOverlay] = useState(false);
    const theme = useTheme();
    return (
        <>
            <FileUploadDropzone
                {...props}
                onDrop={onDrop}
                onClick={() => setIsOverlay(true)}
                dropText={<DropText />}
            />
            {isOverlay && (
                <Dropzone
                    {...props}
                    onDrop={files => {
                        setIsOverlay(false);
                        onDrop(files);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <DropzoneOverlay>
                            <ClosedButton
                                onClose={() => setIsOverlay(false)}
                                color={theme.colors.textSecondary}
                            />
                            <DropzoneArea {...getRootProps()}>
                                <input {...getInputProps()} />
                                <OverlayIconContainer>
                                    <OverlayIcon icon={faImage} />
                                </OverlayIconContainer>
                            </DropzoneArea>
                        </DropzoneOverlay>
                    )}
                </Dropzone>
            )}
        </>
    );
};

ToggableFullscreenFileUploadDropzone.propTypes = {
    ...FileUploadDropzone.propTypes,
};

export default ToggableFullscreenFileUploadDropzone;
