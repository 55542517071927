import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { rem } from '@mspecs/shared-utils';

const CloseButton = styled.button`
    position: absolute;
    top: 6px;
    padding: 0;
    right: 10px;
    border: none;
    outline: none;
    display: inline-block;
    background-color: inherit;
    cursor: pointer;
    :focus {
        box-shadow: none !important;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    font-size: ${rem(26)};
    color: ${({ theme }) => theme.colors.gray80} !important;
    :hover {
        color: ${({ theme }) => theme.colors.gray100} !important;
    }
`;
const CrossButton = props => {
    return (
        <CloseButton {...props}>
            <Icon icon={faTimes} />
        </CloseButton>
    );
};

export default CrossButton;
