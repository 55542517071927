import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import PropTypes from 'prop-types';
import {
    BodyTextRegular,
    Header3Text,
} from '../typograph/typograph-components';
import Modal from './modal-component';
import { useTranslate } from '@mspecs/shared-utils';
import { BUTTON_TYPES, Button, ButtonToolbar } from '../button';

const contentClass = css`
    display: flex;
    flex-direction: column;
`;
const HeaderText = styled(Header3Text)`
    width: 100%;
    text-align: left;
`;

const InfoContent = styled(BodyTextRegular)`
    flex-grow: 1;
    padding: ${({ theme }) => `${theme.spacing.small} 0`};
`;

const StyledButton = styled(Button)`
    width: 100%;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        width: unset;
    }
`;

const ConfirmationModal = props => {
    const {
        headerText,
        infoText,
        isOpen,
        onCancel,
        onConfirm,
        size = 's',
        buttonType = BUTTON_TYPES.PRIMARY,
        label = 'CONFIRM_YES', // confirmLabel
        children,
        headerIcon,
        renderOptionalContent,
        cancelLabel = 'CANCEL',
    } = props;
    const { t } = useTranslate();

    return (
        <Modal
            size={size}
            headerText={<HeaderText>{t(headerText)}</HeaderText>}
            contentClass={contentClass}
            isOpen={isOpen}
            onClose={onCancel}
            headerIcon={headerIcon}
        >
            {infoText ? <InfoContent>{t(infoText)}</InfoContent> : children}
            {renderOptionalContent?.()}
            <ButtonToolbar>
                <StyledButton label={cancelLabel} onClick={onCancel} large />
                <StyledButton
                    label={label}
                    onClick={onConfirm}
                    large
                    buttonType={buttonType}
                />
            </ButtonToolbar>
        </Modal>
    );
};

export default ConfirmationModal;

ConfirmationModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    infoText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    headerText: PropTypes.string,
    children: PropTypes.node,
    label: PropTypes.string,
    buttonType: PropTypes.string,
    headerIcon: PropTypes.object,
    renderOptionalContent: PropTypes.func,
    cancelLabel: PropTypes.string,
};

ConfirmationModal.defaultProps = {
    onCancel: x => x,
    onConfirm: x => x,
    headerText: 'CONFIRMATION_HEADER_TEXT',
};
