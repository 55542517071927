import styled from '@emotion/styled';
import { Box, Flex } from '@rebass/grid/emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import PinnedIcon from './pinned-icon-component';
import { useSidebarContext } from '../../../layout/main-layout/sidebar-state-context';
import {
    Header3Text,
    Header5Text,
    HelpingText,
} from '../../../typograph/typograph-components';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import { StyledSidebarHeader } from './inset-sidebar-component';
import { CrossButton } from '../../../button';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

const SidebarSidePropType = PropTypes.oneOf(['right', 'left']);

const Header = styled(Box)`
    height: 64px;
    text-align: center;
    margin: 0 ${({ theme }) => theme.spacing.default};
    display: flex;
    flex-direction: ${({ side }) => (side === 'left' ? 'row' : 'row-reverse')};
    justify-content: space-between;
    position: relative;
    border-bottom: ${({ theme }) => `1px solid  ${theme.colors.borderPrimary}`};
`;

const HeaderText = styled(Header3Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const HeaderTextContainer = styled(Flex)`
    align-items: center;
    overflow: hidden;
`;
const ButtonWrapper = styled(Flex)`
    width: 3rem;
    justify-content: center;
    align-items: center;
`;

const HeaderIconWrapper = styled.span`
    padding-right: ${({ theme }) => theme.spacing.default};
`;

const MobileHeaderWrapper = styled(Flex)`
    flex-direction: column;
    padding: 0 16px 5px 16px;
`;

const MobileBackTextContainer = styled(Flex)`
    align-items: center;
    height: 44px;
`;

const BackIconContainer = styled(Flex)`
    width: 40px;
    height: 100%;
    align-items: center;
    cursor: pointer;
    z-index: 2;
`;
const BackIcon = styled(FontAwesomeIcon)`
    font-size: 16px;
`;
const MobileHeaderText = styled(Header5Text)`
    text-align: center;
    width: 100%;
    margin-left: -40px; // width of back button
`;
const MobileSubHeaderText = styled(HelpingText)`
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const DefaultSidebarHeader = ({
    side,
    pinned,
    isPinnable,
    header,
    onClose,
    isPinned,
    headerIcon,
    ...props
}) =>
    header && (
        <Header {...props} side={side}>
            <ButtonWrapper>
                {!pinned && !isPinned && <CrossButton onClick={onClose} />}
            </ButtonWrapper>
            <HeaderTextContainer>
                <HeaderText>
                    {headerIcon && (
                        <HeaderIconWrapper>{headerIcon}</HeaderIconWrapper>
                    )}
                    {header}
                </HeaderText>
            </HeaderTextContainer>
            <ButtonWrapper>
                {!pinned && isPinnable && <PinComponent side={side} />}
            </ButtonWrapper>
        </Header>
    );
DefaultSidebarHeader.propTypes = {
    side: SidebarSidePropType,
    header: PropTypes.string,
    onClose: PropTypes.func,
    isPinned: PropTypes.bool,
    isPinnable: PropTypes.bool,
    pinned: PropTypes.bool,
    headerIcon: PropTypes.object,
};

export const MobileSidebarHeader = ({
    onBack,
    children,
    subHeader,
    ...props
}) => {
    const currentBreakPoint = useCurrentBreakpoint();
    const onBackClick = onBack || props.onClose;

    if (currentBreakPoint !== 'xs') {
        return <StyledSidebarHeader {...props} />;
    }

    const { header, ...rest } = props;

    return (
        <MobileHeaderWrapper {...rest}>
            <MobileBackTextContainer>
                <BackIconContainer onClick={onBackClick}>
                    <BackIcon icon={faChevronLeft} />
                </BackIconContainer>
                <MobileHeaderText>{header}</MobileHeaderText>
            </MobileBackTextContainer>
            {subHeader && (
                <MobileSubHeaderText>{subHeader}</MobileSubHeaderText>
            )}
            {children}
        </MobileHeaderWrapper>
    );
};
MobileSidebarHeader.propTypes = {
    ...DefaultSidebarHeader.propTypes,
    onBack: PropTypes.func,
    subHeader: PropTypes.string,
    children: PropTypes.node,
};

const PinComponent = ({ side }) => {
    const [state, { toggle }] = useSidebarContext();

    return (
        <PinnedIcon
            handlePinSidebar={() =>
                toggle({
                    pinned: !state[side].pinned,
                    side,
                })
            }
            isPinned={state[side].pinned}
        />
    );
};

PinComponent.propTypes = {
    side: SidebarSidePropType,
};
