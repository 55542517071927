import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import React from 'react';
import { useFlexLayout, useTable } from 'react-table';
import { useDraggableRow } from '../../utils';
import SubTableDndContent from './sub-table-dnd-content-component';
import SubTableRow from './sub-table-row-component';

const SubTableWrapper = styled.div`
    background: ${({ theme, skipConnectionLine }) =>
        skipConnectionLine ? theme.colors.bgPrimary : theme.colors.bgSecondary};
    padding-bottom: ${({ rows, skipConnectionLine }) =>
        rows.length > 0 && !skipConnectionLine ? '10px' : 0};
    .rt-td {
        transition-property: none;
    }
`;

const SubTable = ({
    columns,
    data,
    tableStyles,
    renderChildren,
    getRowProps,
    isRowDragEnabled = false,
    droppableProps,
    skipConnectionLine,
    ...restProps
}) => {
    const hooks = [useFlexLayout, isRowDragEnabled && useDraggableRow].filter(
        Boolean
    );

    const { getTableProps, rows, prepareRow } = useTable(
        {
            columns,
            data,
            ...restProps,
        },
        ...hooks
    );

    const rowProps = {
        prepareRow,
        renderChildren,
        getRowProps,
    };

    return (
        <SubTableWrapper
            css={[tableStyles]}
            {...getTableProps()}
            rows={rows}
            skipConnectionLine={skipConnectionLine}
        >
            {isRowDragEnabled ? (
                <SubTableDndContent
                    rows={rows}
                    rowProps={rowProps}
                    droppableProps={droppableProps}
                />
            ) : (
                rows.map((row, index) => (
                    <div key={row.id}>
                        <SubTableRow
                            {...rowProps}
                            skipConnectionLine={skipConnectionLine}
                            row={row}
                            isFirst={index === 0}
                        />
                    </div>
                ))
            )}
        </SubTableWrapper>
    );
};

SubTable.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array.isRequired,
    tableStyles: PropTypes.string,
    renderChildren: PropTypes.func,
    getRowProps: PropTypes.func,
    isRowDragEnabled: PropTypes.bool,
    droppableProps: PropTypes.shape({
        droppableId: PropTypes.string.isRequired,
        isCombineEnabled: PropTypes.bool,
        isDropDisabled: PropTypes.bool,
    }),
    skipConnectionLine: PropTypes.bool,
};

export default React.memo(SubTable);
