import React from 'react';
import { withProps } from 'recompose';
import PropTypes from 'prop-types';
import { InputField, NumberInput } from '.';
import { FormInputField } from '../form';

const MoneyInput = ({
    numeric = false,
    isFormField = false,
    value,
    ...props
}) => {
    const Component = numeric
        ? NumberInput
        : isFormField
        ? FormInputField
        : InputField;

    return (
        <Component
            name="money"
            value={value ? value.toString() : ''}
            {...props}
            errorMessage="MONEY_INPUT_VALIDATION_ERROR_MSG"
            mask="money"
            isFormField={isFormField}
        />
    );
};
export const NumericMoneyInput = withProps({
    numeric: true,
    fallbackValue: null,
})(MoneyInput);

MoneyInput.propTypes = {
    numeric: PropTypes.bool,
};

export default MoneyInput;
