import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { BodyTextRegular } from '../../typograph/typograph-components';
import { isMathchingDynamicPath, MenuItemPropType } from '../utils';
import { StatusButton } from '../../button/status-button';
import { getCheckedItems } from '../sidebar-menu/helpers';

const Container = styled(Flex)`
    flex-direction: column;
    flex: 1;
`;
const IconWrapper = styled(Flex)`
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;
`;

const StyledMobileMenuItem = styled(Flex)`
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgSecondary140};
    height: 44px;
    align-items: center;
    justify-content: space-between;
`;
const LabelText = styled(BodyTextRegular)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    line-height: 24px;
`;

const ItemWrapper = styled(Flex)`
    gap: ${({ theme }) => theme.spacing.default};
    align-items: center;
`;

const MenuItem = ({
    label,
    icon,
    checkedItems,
    setCheckedItems,
    path,
    menuItems,
    ...props
}) => {
    const onCheck = (status, id) => {
        setCheckedItems(
            getCheckedItems({
                status,
                id,
                parent: path ? path.split('/')[0].toUpperCase() : null,
                checkedItems,
                item: {
                    label: label?.toUpperCase(),
                    subItems: path ? menuItems : props.subItems,
                },
            })
        );
    };

    return (
        <StyledMobileMenuItem {...props}>
            <ItemWrapper>
                {icon && <IconWrapper>{icon}</IconWrapper>}
                {!icon && (
                    <StatusButton
                        changeStatus={status => {
                            onCheck(status, path || label.toUpperCase());
                        }}
                        currentStatus={
                            checkedItems[path || label.toUpperCase()]?.status
                        }
                    />
                )}
                <LabelText>{label}</LabelText>
            </ItemWrapper>
            <IconWrapper>
                <FontAwesomeIcon icon={faChevronRight} />
            </IconWrapper>
        </StyledMobileMenuItem>
    );
};

function MobileFullpageNavigation({ items, routes = [], children, navState }) {
    const { t } = useTranslate();
    const navigate = useNavigate();
    const location = useLocation();
    const [checkedItems, setCheckedItems] = useState({});
    const [navStack, setNavStack] = navState;
    const menuItems = useMemo(
        () =>
            !navStack.length
                ? items
                : navStack[navStack.length - 1].subItems ?? [],
        [navStack, items]
    );

    const isInChildRoute = useMemo(() => {
        const flatItems = items
            .reduce((acc, item) => {
                const subItems = item.subItems || [];
                const thirdLevelItems = item.subItems.reduce(
                    (subAcc, subItem) => [
                        ...subAcc,
                        ...(subItem?.subItems ?? []),
                    ],
                    []
                );
                return [
                    ...acc,
                    item.path,
                    ...[...subItems, ...thirdLevelItems].map(sub => sub.path),
                ];
            }, [])
            .filter(Boolean);
        return flatItems.some(
            path =>
                location.pathname.endsWith(path) ||
                isMathchingDynamicPath(location.pathname, path)
        );
    }, [items, location.pathname]);

    const onMenuItemClick = item => () => {
        if (item.path) {
            navigate(item.path);
        }
        if (item.onClick) {
            return item.onClick();
        }
        setNavStack(prev => [...prev, item]);
    };

    return (
        <Container>
            {children}
            {!isInChildRoute &&
                menuItems.map(item => (
                    <MenuItem
                        key={item.label}
                        {...item}
                        label={t(item.label)}
                        onClick={onMenuItemClick(item)}
                        checkedItems={checkedItems}
                        setCheckedItems={setCheckedItems}
                        menuItems={menuItems}
                    />
                ))}
            <Routes>
                {routes.map(item => (
                    <Route
                        key={item.path}
                        path={item.path}
                        element={item.element}
                    />
                ))}
            </Routes>
        </Container>
    );
}

export default MobileFullpageNavigation;

MobileFullpageNavigation.propTypes = {
    backRoute: PropTypes.string,
    items: PropTypes.arrayOf(MenuItemPropType),
    children: PropTypes.node,
    routes: PropTypes.arrayOf(
        PropTypes.shape({ path: PropTypes.string, element: PropTypes.node })
    ),
};
