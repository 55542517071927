import React from 'react';
import styled from '@emotion/styled';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const CopyIcon = styled(FontAwesomeIcon)`
    cursor: pointer;
`;

const Copy = props => {
    const { size, ...restProps } = props;

    return (
        <CopyToClipboard {...restProps}>
            <CopyIcon icon={faCopy} size={size} />
        </CopyToClipboard>
    );
};

export default Copy;

Copy.propTypes = {
    size: PropTypes.string,
    onCopy: PropTypes.func,
    text: PropTypes.string.isRequired,
};

Copy.defaultProps = {
    size: 'lg',
};
