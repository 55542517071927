import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import useStack from './hooks/use-stack';
import { useTranslate } from '@mspecs/shared-utils';

const CrumbItem = styled('span', { shouldForwardProp: isPropValid })`
    color: ${({ theme: { colors }, isLast }) =>
        isLast ? colors.primaryColor : colors.textSecondary};
    cursor: ${props => (props.isLast ? 'auto' : 'pointer')};
    font-weight: ${props => (props.isLast ? '500' : '400')};
    font-size: 14px;
    line-height: 21.76px;

    :hover {
        text-decoration: ${({ isLast }) => !isLast && 'underline'};
    }
`;

const Separator = styled.span`
    padding: 0 5px;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const StyledBreadCrumbs = styled.div`
    border-radius: 0;
    margin-bottom: 0;
    padding: 0;
    padding-bottom: 10px;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const Crumb = ({ title, isLast, onClick }) => {
    const { t } = useTranslate();

    return (
        <>
            <CrumbItem isLast={isLast} onClick={onClick}>
                {t(title)}
            </CrumbItem>
            {!isLast && <Separator>/</Separator>}
        </>
    );
};

Crumb.propTypes = {
    title: PropTypes.string,
    t: PropTypes.func,
    isLast: PropTypes.bool,
    onClick: PropTypes.func,
};

const BreadCrumbs = () => {
    const { t } = useTranslate();
    const { stack, navigateStack } = useStack();

    if (stack.length <= 1) {
        return null;
    }

    return (
        <StyledBreadCrumbs>
            {stack.map((stackItem, index) => {
                const isLast = index === stack.length - 1;

                return (
                    <Crumb
                        key={index}
                        isLast={isLast}
                        title={t(stackItem)}
                        onClick={() =>
                            !isLast && navigateStack(stack.length - 1 - index)
                        }
                    />
                );
            })}
        </StyledBreadCrumbs>
    );
};

export default BreadCrumbs;
