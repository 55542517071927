import styled from '@emotion/styled';
import {
    Header5Text,
    HelpingText,
    HighlightedBodyText,
} from '../../../typograph/typograph-components';
import { IconButton, BUTTON_TYPES } from '../../../button';
import { ReactNode } from 'react';
import { useTranslate } from '@mspecs/shared-utils';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

const Container = styled.section`
    padding: 0 16px;
`;

const Navigation = styled.section`
    display: grid;
    grid-template-columns: 44px 1fr 44px;
    column-gap: 2px;
    align-items: center;

    min-height: 44px;
`;

const StyledNavigationBtn = styled(IconButton)<{ isLeft?: boolean }>`
    ${({ isLeft }) => (isLeft ? 'padding-left: 0px;' : 'padding-right: 0px;')}
    justify-content: ${({ isLeft }) => (isLeft ? 'flex-start' : 'flex-end')};
    font-size: 20px;
`;

const Title = styled(Header5Text)`
    text-align: center;
    grid-column: 2;
`;

const Label = styled(HelpingText)`
    padding: 0;
    margin: 0;
    color: ${({ theme }) => theme.colors.textSecondary};
`;

export const HelpersLabel = styled(HighlightedBodyText)`
    padding-top: 10px;
    color: ${({ theme }) => theme.colors.textPrimary};
`;

export interface INavigationHeader {
    title?: string;
    label?: string;
    onNext?: () => void;
    onPrevious?: () => void;
    children?: ReactNode;
}

function NavigationHeader({
    title,
    label,
    onNext,
    onPrevious,
    children,
}: INavigationHeader) {
    const { t } = useTranslate();
    return (
        <Container>
            <Navigation>
                {onPrevious && (
                    <StyledNavigationBtn
                        isLeft
                        buttonType={BUTTON_TYPES.TEXT}
                        icon={faChevronLeft}
                        hiddenLabel={'PREVIOUS'}
                        onClick={onPrevious}
                    />
                )}
                <Title>{t(title || 'IMAGE_EDIT')}</Title>
                {onNext && (
                    <StyledNavigationBtn
                        buttonType={BUTTON_TYPES.TEXT}
                        icon={faChevronRight}
                        hiddenLabel={'NEXT'}
                        onClick={onNext}
                    />
                )}
            </Navigation>
            <Label>{label}</Label>
            {children}
        </Container>
    );
}

export default NavigationHeader;
