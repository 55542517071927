import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

const CONNECTION_LINE_SPACE = 50;
const CONNECTION_LINE_WIDTH = CONNECTION_LINE_SPACE / 2;
const CONNECTION_GROUP_MARGIN = 10;

export const SubTableRowWrapper = styled.div`
    padding-top: ${({ isFirst, isDragging, skipConnectionLine }) =>
        isFirst && !isDragging && !skipConnectionLine
            ? CONNECTION_GROUP_MARGIN
            : 0}px;
    padding-left: ${({ isDragging, skipConnectionLine }) =>
        !isDragging && !skipConnectionLine ? CONNECTION_LINE_SPACE : 0}px;
    position: relative;
    &::before {
        display: ${({ isDragging, skipConnectionLine }) =>
            !isDragging && !skipConnectionLine ? 'block' : 'none'};
        content: '';
        position: absolute;
        border-width: 0 0 2px 2px;
        border-color: ${({ theme }) => theme.colors.borderPrimary};
        border-style: dashed;
        width: ${CONNECTION_LINE_WIDTH}px;
        height: ${props =>
            props.isFirst
                ? CONNECTION_LINE_WIDTH + CONNECTION_GROUP_MARGIN
                : props.previousSibling?.clientHeight ?? 0}px;
        left: ${CONNECTION_LINE_WIDTH}px;
        top: ${props =>
            props.isFirst
                ? 0
                : `-${
                      props.previousSibling?.clientHeight -
                          CONNECTION_GROUP_MARGIN * 2 ?? 0
                  }`}px;
   
`;
const SubTableRowCells = styled.div`
    display: flex;
    background: #fff;
`;

const SubTableRow = ({
    row,
    prepareRow,
    isFirst = false,
    getRowProps,
    renderChildren = () => null,
    cellProps = {},
    isDragging = false,
    isRowDragEnabled = false,
    skipConnectionLine = false,
    ...props
}) => {
    const rowRef = useRef(null);
    prepareRow(row);
    const rowProps =
        row?.getRowProps?.({
            ...(getRowProps?.(row) || {}),
        }) ?? {};
    const [previousSibling, setPreviousSibling] = useState(null);
    const [indetion, setIndetion] = useState(1);
    useEffect(() => {
        if (rowRef.current) {
            let _indention = 1;
            let offsetParent = rowRef.current.offsetParent;
            while (offsetParent?.className?.includes?.('SubTable')) {
                _indention += 1;
                offsetParent = offsetParent?.offsetParent;
            }
            setIndetion(_indention);
        }

        setPreviousSibling(rowRef.current?.parentElement?.previousSibling);
    }, [rowRef.current]);

    const getCellStyle = (cell, index) => {
        const cellStyle = cell.getCellProps().style;
        if (skipConnectionLine) {
            const defaultPadding = 10;
            return {
                width:
                    index === 0
                        ? `calc(${cellStyle.width} + ${20 + defaultPadding}px)`
                        : cellStyle.width,
                paddingLeft:
                    index === 0
                        ? 20 + defaultPadding * 2
                        : cellStyle.paddingLeft,
            };
        }
        if (isRowDragEnabled && index === 0) {
            return {
                flex: 'auto',
            };
        }

        if ((isRowDragEnabled && index === 1) || index === 0) {
            return {
                width: `calc(${cellStyle.width} - ${
                    indetion * CONNECTION_LINE_SPACE
                }px)`,
            };
        }

        return {};
    };

    return (
        <SubTableRowWrapper
            {...props}
            isFirst={isFirst}
            previousSibling={previousSibling}
            ref={rowRef}
            isDragging={isDragging}
            skipConnectionLine={skipConnectionLine}
        >
            <SubTableRowCells {...rowProps} className="rt-tr">
                {row.cells.map((cell, index) => (
                    <div
                        className="rt-td"
                        {...cell.getCellProps()}
                        key={cell.getCellProps().key}
                        style={{
                            ...cell.getCellProps().style,
                            ...getCellStyle(cell, index),
                            paddingRight: !skipConnectionLine && 0,
                            position: 'relative',
                        }}
                    >
                        {cell.render('Cell', cellProps)}
                    </div>
                ))}
            </SubTableRowCells>
            <div style={{ display: isDragging ? 'none' : 'block' }}>
                {renderChildren(row.original.id)}
            </div>
        </SubTableRowWrapper>
    );
};

SubTableRow.propTypes = {
    row: PropTypes.object.isRequired,
    prepareRow: PropTypes.func.isRequired,
    isFirst: PropTypes.bool,
    renderChildren: PropTypes.func,
    getRowProps: PropTypes.func,
    cellProps: PropTypes.object,
    isDragging: PropTypes.bool,
    isRowDragEnabled: PropTypes.bool,
    skipConnectionLine: PropTypes.bool,
};

export default SubTableRow;
