import React from 'react';
import {
    and,
    optionIs,
    rankWith,
    isStringControl,
    or,
    formatIs,
} from '@jsonforms/core';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { DatePicker } from '../../../input';

function DateCell(props) {
    const { path, handleChange } = props;

    const baseProps = {
        ...getCellProps(props),
        onChange: value => handleChange(path, value),
        stackedLabel: true,
    };

    return <DatePicker {...baseProps} />;
}

DateCell.propTypes = CellPropType;

export const tester = rankWith(
    3,
    and(isStringControl, or(formatIs('date'), optionIs('format', 'date')))
);
export default { renderer: withInputControlHoc(DateCell), tester };
