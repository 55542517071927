import React, { useState } from 'react';
import { css } from '@emotion/react';
import { branch, wrapDisplayName } from 'recompose';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { omit } from 'lodash-es';
import { useTranslate } from '@mspecs/shared-utils';
import { useUniqueId } from '@mspecs/shared-utils';
import { TooltipPopover } from '../../components/popovers';
import {
    HighlightedBodyTextBold,
    SubTextMedium,
    TEXT_STYLES,
} from '../../components/typograph/typograph-components';
import { CharacterCount } from '../../components/input/basic/input-field-component';
import { InputHelpTextWrapper } from '../../components/input/basic/input-field-component.styles';

export const labelPropKeys = [
    'required',
    'hideRequiredHint',
    'partiallyRequired',
    'subLabel',
    'stackedLabel',
    'htmlFor',
    'label',
    'innerlabel',
    'reverseLabel',
    'stackedLabel',
    'offset',
    'labelCss',
    'css',
    'noWidth',
    'noLabelWidth',
    'allowLabelLineBreak',
    'hoverText',
    'maxLength',
];

const stackedLabelCss = css`
    padding-right: 0;
    padding-left: 10px;
    padding-bottom: 5px;
    width: 100%;
    min-width: 100%;
`;

const AbsoluteRequired = styled(HighlightedBodyTextBold)`
    position: absolute;
    left: -15px;
    top: 10px;
    display: none;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        display: block;
    }
`;
const PartiallyRequiredSpan = styled(AbsoluteRequired)`
    left: -21px;
`;

const RequiredSpan = styled.span`
    display: inline-block;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        ${({ stackedLabel }) => !stackedLabel && `display: none`}
    }
`;

const LabelContainer = styled.div`
    ${({ reverseLabel, theme }) =>
        reverseLabel
            ? 'padding-left: 7px;'
            : `padding-right: 16px; padding-left: ${theme.spacing.default}`};

    ${({ noLabelWidth, noWidth, stackedLabel }) =>
        !noWidth &&
        !noLabelWidth &&
        !stackedLabel &&
        `width: 39%;min-width: 39%;`}
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    align-self: flex-start;
    color: ${({ theme }) => theme.colors.textPrimary};
    ${({ theme }) => TEXT_STYLES.highlightedBodyText(theme)}
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ stackedLabel, theme }) =>
        stackedLabel
            ? stackedLabelCss
            : `@media (min-width: ${theme.responsiveBreakpoints.s}){min-height: 34px;}`}

    ${({ allowLabelLineBreak }) =>
        allowLabelLineBreak && `white-space: pre-line;`}

    @media (max-width: ${({ theme }) =>
        theme.responsiveBreakpoints.s}) and (orientation: portrait) {
        ${props => !props.noWidth && stackedLabelCss}
    }
    @media (min-width: 1680px) {
        ${props =>
            !props.noWidth &&
            !props.stackedLabel &&
            `min-width: auto; max-width: 250px;`}
    }

    label {
        margin-bottom: 0; // css in forms.less in legacy adds unwanted margin
    }
`;

export const InputContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${({ noWidth, stackedLabel }) => !noWidth && !stackedLabel && 'width: 60%;'}
    margin-bottom: 0;
    ${props => props.offset && 'margin-left: 39%;'}
    order: ${props => props.reverseLabel && '-1'};
    position: relative;

    ${({ stackedLabel }) =>
        stackedLabel &&
        `
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px;  // need space below for error text
    `}

    @media (max-width: ${({ theme }) =>
        theme.responsiveBreakpoints.s}) and (orientation: portrait) {
        ${({ noWidth }) => !noWidth && `max-width: 100%;width: 100%;`}
    }
`;

const SubLabel = styled(SubTextMedium)`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const wrapperCss = css`
    flex: 1;
`;

const sameRowCss = theme => css`
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px; // need space below for error text
    width: 100%;
    flex-direction: column;
`;

const offsetStyle = theme => css`
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        margin-left: 39%;
    }
`;

export const LabelWrapper = props => {
    const {
        children,
        className,
        htmlFor,
        label,
        subLabel,
        innerLabel,
        stackedLabel,
        reverseLabel,
        labelClassName,
        offset,
        useDBTranslation,
        noWidth,
        noLabelWidth,
        css,
        labelCss,
        labelWrapperCss,
        allowLabelLineBreak,
        hoverText,
        required,
        hideRequiredHint,
        partiallyRequired,
        maxLength,
        value,
        hideCharacterCount,
        labelPostfix,
    } = props;

    const { t } = useTranslate({ useDBTranslation });

    const [isTooltipVisible, setIsTooltipVisible] = useState(false);

    const togglePopover = isVisible => () => {
        if (!hoverText) return;
        setIsTooltipVisible(isVisible);
    };
    const showMaxLength =
        !hideCharacterCount &&
        maxLength &&
        (maxLength < 5 || String(value || '').length > maxLength * 0.8);

    if (!label && !innerLabel) return { children };

    return (
        <div
            css={[
                !stackedLabel && sameRowCss,
                offset && offsetStyle,
                wrapperCss,
                labelWrapperCss,
            ]}
        >
            {label && (
                <LabelContainer
                    className={labelClassName}
                    css={labelCss}
                    stackedLabel={stackedLabel}
                    reverseLabel={reverseLabel}
                    noWidth={noWidth}
                    noLabelWidth={noLabelWidth}
                    allowLabelLineBreak={allowLabelLineBreak}
                >
                    <TooltipPopover
                        text={hoverText}
                        primary
                        isTooltipVisible={hoverText && isTooltipVisible}
                    >
                        <label
                            htmlFor={htmlFor}
                            onMouseOver={togglePopover(true)}
                            onMouseLeave={togglePopover(false)}
                            onClick={togglePopover(!isTooltipVisible)}
                            style={{ display: 'flex', alignItems: 'center' }}
                        >
                            {t(label, { postProcess: 'capitalize' })}
                            {(required || partiallyRequired) &&
                                !hideRequiredHint && (
                                    <RequiredSpan stackedLabel={stackedLabel}>
                                        {partiallyRequired ? '**' : '*'}
                                    </RequiredSpan>
                                )}
                            {subLabel && <SubLabel>{t(subLabel)}</SubLabel>}
                            {labelPostfix}
                        </label>
                    </TooltipPopover>
                </LabelContainer>
            )}
            <InputContainer
                css={css}
                className={className}
                stackedLabel={stackedLabel}
                reverseLabel={reverseLabel}
                noWidth={noWidth}
            >
                {required && !hideRequiredHint && !stackedLabel && (
                    <AbsoluteRequired>*</AbsoluteRequired>
                )}
                {partiallyRequired &&
                    !hideRequiredHint &&
                    !required &&
                    !stackedLabel && (
                        <PartiallyRequiredSpan>**</PartiallyRequiredSpan>
                    )}
                {children}
                {showMaxLength && (
                    <InputHelpTextWrapper>
                        <CharacterCount value={value} maxLength={maxLength} />
                    </InputHelpTextWrapper>
                )}
            </InputContainer>
        </div>
    );
};

LabelWrapper.propTypes = {
    children: PropTypes.node,
    t: PropTypes.func,
    label: PropTypes.string,
    subLabel: PropTypes.string,
    innerLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    reverseLabel: PropTypes.bool,
    stackedLabel: PropTypes.bool,
    noWidth: PropTypes.bool,
    noLabelWidth: PropTypes.bool,
    css: PropTypes.object,
    labelCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    labelWrapperCss: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    allowLabelLineBreak: PropTypes.bool,
    hoverText: PropTypes.string,

    hideRequiredHint: PropTypes.bool,
    required: PropTypes.bool,
    partiallyRequired: PropTypes.bool,
    invalid: PropTypes.bool,
    maxLength: PropTypes.number,
    value: PropTypes.any,
    hideCharacterCount: PropTypes.bool,
};

LabelWrapper.defaultProps = {
    label: '',
    t: x => x,
    stackedLabel: false,
};

const withLabelWrapper = Component => {
    function WithLabelWrapper(props) {
        const componentProps = omit(props, labelPropKeys);

        const id = useUniqueId(props.id);

        return (
            <LabelWrapper htmlFor={id} {...props}>
                <Component {...componentProps} id={id} />
            </LabelWrapper>
        );
    }
    WithLabelWrapper.displayName = wrapDisplayName(
        Component,
        'withLabelWrapper'
    );

    return WithLabelWrapper;
};

export default branch(
    // Use HOC only if label or innerLabel is passed
    ({ label, innerLabel }) => label || innerLabel,
    withLabelWrapper
);
