import styled from '@emotion/styled';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import { OverrideButton } from './override-component';
import { isScreen } from './utilities';
import { BodyTextRegular } from '../../typograph/typograph-components';
import { Slider } from '../../sliders';

const Label = styled(BodyTextRegular)<{ align?: 'start' | 'end' | 'center' }>`
    text-align: ${({ align }) => align || 'start'};
`;

const RotateContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;
    width: 100%;
`;

const ButtonContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
`;

const SliderContainer = styled.div`
    padding: 10px 0;
`;

const sliderOptions = {
    step: 5,
    max: 180,
    min: -180,
};

export interface IRotateSet {
    rotate: {
        label: string;
        value: number;
    };
}

export interface IImageRotateProps {
    rotateSet: IRotateSet;
    changeRotate: (set: IRotateSet) => void;
}

function ImageRotate({ changeRotate, rotateSet }: IImageRotateProps) {
    const screen = useCurrentBreakpoint();

    return (
        <RotateContainer>
            {isScreen(['l', 'xl'], screen) ? (
                <section>
                    <Label>{rotateSet.rotate.label}</Label>
                    <Slider
                        setValue={value => {
                            changeRotate({
                                rotate: { ...rotateSet.rotate, value },
                            });
                        }}
                        value={rotateSet.rotate.value}
                        step={sliderOptions.step}
                        min={sliderOptions.min}
                        max={sliderOptions.max}
                    />
                    <Label align="end">{rotateSet.rotate.value}</Label>
                </section>
            ) : (
                <section>
                    <SliderContainer>
                        <Slider
                            setValue={value => {
                                changeRotate({
                                    rotate: { ...rotateSet.rotate, value },
                                });
                            }}
                            value={rotateSet.rotate.value || 0}
                            step={sliderOptions.step}
                            min={sliderOptions.min}
                            max={sliderOptions.max}
                        />
                    </SliderContainer>
                    <ButtonContainer>
                        <OverrideButton
                            buttonType={'primary_light'}
                            small={true}
                            border
                            onClick={() => {}}
                        >
                            {rotateSet.rotate.label}
                        </OverrideButton>
                        <Label align="end">{rotateSet.rotate.value}</Label>
                    </ButtonContainer>
                </section>
            )}
        </RotateContainer>
    );
}

export default ImageRotate;
