import { useState } from 'react';
import { useCurrentBreakpoint } from '@mspecs/shared-utils';
import styled from '@emotion/styled';
import { isScreen } from './utilities';
import { OverrideButton } from './override-component';
import { BodyTextRegular } from '../../typograph/typograph-components';
import { Slider } from '../../sliders';

const Label = styled(BodyTextRegular)`
    color: ${({ theme }) => theme.colors.textSecondary};
`;

const FilterContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;
    width: 100%;
`;

const ButtonContainer = styled.section`
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
`;

const SliderContainer = styled.div`
    padding: 10px 0;
`;

const sliderOptions = {
    step: 0.2,
};

export interface IFilter {
    label: string;
    value: number;
}
export interface IFilterSet {
    [key: string]: IFilter;
}
export interface ImageFilterProps {
    filter: IFilterSet;
    changeFilter: (filter: IFilterSet) => void;
}

function ImageFilter({ filter, changeFilter }: ImageFilterProps) {
    const screen = useCurrentBreakpoint();
    const [style, setStyle] = useState<string>(Object.keys(filter)[0]);

    return (
        <FilterContainer>
            {isScreen(['l', 'xl'], screen) ? (
                Object.keys(filter).map(key => (
                    <section key={key}>
                        <Label>{filter[key].label}</Label>
                        <Slider
                            setValue={value => {
                                changeFilter({
                                    [key]: { ...filter[key], value },
                                });
                            }}
                            value={filter[key].value}
                            step={sliderOptions.step}
                            min={-50}
                            max={50}
                        />
                    </section>
                ))
            ) : (
                <section>
                    <SliderContainer>
                        <Slider
                            setValue={value => {
                                changeFilter({
                                    [style]: { ...filter[style], value },
                                });
                            }}
                            value={filter[style]?.value || 0}
                            step={sliderOptions.step}
                            min={-50}
                            max={50}
                        />
                    </SliderContainer>
                    <ButtonContainer>
                        {Object.keys(filter).map(v => (
                            <OverrideButton
                                buttonType={
                                    v === style ? 'primary_light' : 'text'
                                }
                                key={v}
                                border={v === style}
                                small={true}
                                onClick={() => {
                                    setStyle(v);
                                }}
                            >
                                {filter[v].label}
                            </OverrideButton>
                        ))}
                    </ButtonContainer>
                </section>
            )}
        </FilterContainer>
    );
}

export default ImageFilter;
