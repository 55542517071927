export enum ThemePalette {
    default = 'default',
    indigo = 'indigo',
    lacricia = 'lacricia',
}

export enum ThemeMode {
    dark = 'dark',
    light = 'light',
}

export enum ColorHue {
    green = 108,
    mediumSeaGreen = 135,
    blue = 208,
    mediumBlue = 240,
    mediumSlateBlue = 230,
    accent = 201,
    mediumCyan = 186,
    orange = 23,
    lightYellow = 60,
    //what to do if multiple colors have the some hue?
    red = 0,
    white = 0,
    black = 0,
    gray = 0,
}
