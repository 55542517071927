// @ts-ignore
import ToastImageEditor from '@toast-ui/react-image-editor';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import './image-editor-style.css';
import { useImageUploadLocale } from './locale';
import { white } from './themes';
import { LoadingAnimation } from '@mspecs/shared-utils';
import ImageHeaderBar from './image-header-bar';
import ImagaEditorBar from './image-editor-bar';
import styled from '@emotion/styled';
import { debounce } from 'lodash';
import { Button } from '../../button';
import { NavigationHeader } from './mobile';
import { IImage } from './type';
import { HelpersLabel } from './mobile/navigation-header';

const EditorContainer = styled('section')`
    position: relative;
    width: 100%;
    height: 100%;

    display: grid;

    grid-template:
        'head head head' 52px
        'subBar subBar subBar' 128px
        'editor editor editor' 2fr
        'menu menu menu' 233px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        grid-template:
            'menu head head' 120px
            'menu editor editor'
            'menu editor editor';
        grid-template-columns: 238px 1fr;
        gap: 0 10px;
    }
`;

const GridElementHeader = styled('div')`
    grid-area: head;
    overflow: hidden;
    border-bottom: 1px solid ${({ theme }) => theme.colors.bgSecondary140};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        border-bottom: 0;
    }
`;

const GridElementSubBar = styled('div')`
    grid-area: subBar;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: none;
    }
`;

const GridElementBar = styled('div')`
    grid-area: menu;
    overflow: hidden;
    border-top: 1px solid ${({ theme }) => theme.colors.bgSecondary140};
    padding: 10px 16px 25px 16px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        padding: 77px 10px 35px 10px;
        border-top: 0;
        background: ${({ theme }) => theme.colors.bgSecondary};
    }
`;

const GridElementEditor = styled('div')`
    grid-area: editor;
    position: relative;

    margin-bottom: 20px;
    margin-top: 6px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        margin: 0;
    }
`;

const TuiContainer = styled.div`
    position: absolute;
    top: 16px;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 100%;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        position: static;
    }
`;

const ActionButton = styled.section`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: none;
    }
`;

export interface IImageEditorProps {
    image: IImage;
    editorProps?: Record<string, any>;
    isFullscreen?: boolean;
    children?: React.ReactNode;
    displayLoader?: boolean;
    subTitle?: string;

    onBack?: () => void;
    onClose?: () => void;
    onSave?: () => void;
}

const ImageEditor = forwardRef<any, IImageEditorProps>(
    (
        {
            image,
            editorProps = {},
            isFullscreen = false,
            children,
            displayLoader,
            subTitle,
            onBack = () => {
                console.log('Not overridden onBack');
            },
            onClose = () => {
                console.log('Not overridden onClose');
            },
            onSave = () => {
                console.log('Not overridden onSave');
            },
        },
        editorRef: any
    ) => {
        const containerRef = useRef<HTMLDivElement | null>(null);
        const [size, setSize] = useState<
            { maxWidth: number; maxHeight: number } | undefined
        >();
        const [isLoading, setIsLoading] = useState<boolean>(false);
        const [isLoaded, setIsLoaded] = useState<boolean>(false);
        const translate = useImageUploadLocale();
        const [hasChanges, setHasChanges] = useState<boolean>(false);

        useEffect(() => {
            const width = containerRef.current?.offsetWidth || 0;
            const height = containerRef.current?.offsetHeight || 0;
            setSize({ maxWidth: width, maxHeight: Math.max(height, 400) });
        }, [containerRef.current, isFullscreen]);

        useEffect(() => {
            const handleResize = debounce(() => {
                const instance = editorRef?.current?.getInstance?.();

                if (!instance || !containerRef.current) {
                    return;
                }

                instance.resizeCanvasDimension({
                    width: containerRef.current.offsetWidth,
                    height: containerRef.current.offsetHeight,
                });
            }, 400);
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }, []);

        useEffect(() => {
            if (!displayLoader) return;

            const editor = (
                editorRef as React.RefObject<any>
            )?.current?.getInstance?.();
            if (editor && !isLoading && !isLoaded) {
                setIsLoading(true);
                editor
                    .loadImageFromURL(
                        image.originalURI,
                        image.title || image.originalURI
                    )
                    .then(() => {
                        editor.ui.initializeImgUrl = image.originalURI;
                        editor.clearUndoStack();
                        editor.ui.activeMenuEvent();
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setIsLoaded(true);
                    });
            }
        }, [size, editorRef?.current]);

        const onStackChanged = (length: number) => {
            editorProps?.onUndoStackChanged?.(length);
            setHasChanges(length > 0);
        };

        return (
            <EditorContainer
                onContextMenu={e => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                <GridElementHeader>
                    <ImageHeaderBar
                        editorRef={editorRef}
                        onClose={onClose}
                        onSave={onSave}
                        disabledSave={!hasChanges}
                    />
                </GridElementHeader>

                <GridElementSubBar>
                    <NavigationHeader onPrevious={onClose} label={subTitle}>
                        <HelpersLabel>{image.title}</HelpersLabel>
                        <ActionButton>
                            <Button buttonType={null} onClick={onClose}>
                                {translate.Cancel}
                            </Button>
                            <Button
                                buttonType="primary"
                                disabled={!hasChanges}
                                onClick={onSave}
                            >
                                {translate.Save}
                            </Button>
                        </ActionButton>
                    </NavigationHeader>
                </GridElementSubBar>

                <GridElementBar>
                    <ImagaEditorBar editorRef={editorRef} />
                </GridElementBar>

                <GridElementEditor ref={containerRef}>
                    {isLoading ? (
                        <LoadingAnimation>{translate.Loading}</LoadingAnimation>
                    ) : (
                        size && (
                            <TuiContainer>
                                <ToastImageEditor
                                    ref={editorRef}
                                    includeUI={{
                                        loadImage: {
                                            path: image.originalURI,
                                            name:
                                                image.title ||
                                                image.originalURI,
                                        },
                                        locale: translate,
                                        theme: white,
                                        menu: [
                                            'crop',
                                            'filter',
                                            'rotate',
                                            'resize',
                                        ],
                                        // menuBarPosition: 'left',
                                        // does not should menu, but still adds it to the DOM
                                        initMenu: false,
                                        // menu: [],
                                    }}
                                    usageStatistics={false}
                                    cssMaxWidth={size.maxWidth}
                                    cssMaxHeight={size.maxHeight}
                                    {...editorProps}
                                    onUndoStackChanged={onStackChanged}
                                />
                            </TuiContainer>
                        )
                    )}
                    {children}
                </GridElementEditor>
            </EditorContainer>
        );
    }
);

export default ImageEditor;
