import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { ConfirmationModal } from '../../modal';
import { useTranslate } from '@mspecs/shared-utils';
import { IconButton, Button, BUTTON_TYPES } from '../../button';
import {
    faExpandWide,
    faFileArrowDown,
    faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { IconButtonTolBar } from './styled';

const Toolbar = styled(Flex)`
    position: relative;
    margin: 0 ${({ theme }) => theme.spacing.default};
`;

const ToolbarButtons = styled(Flex)`
    justify-content: flex-end;
    gap: 10px;
`;

const SaveButton = styled(Button)`
    margin-left: auto;
`;

const ImageEditorToolbar = ({
    onSubmit,
    onDelete,
    onDownload,
    onFullMode,
    isSubmitActive,
    ...rest
}) => {
    const { t } = useTranslate();
    const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

    return (
        <Toolbar {...rest}>
            <ToolbarButtons
                width="100%"
                justifyContent="center"
                alignItems="center"
            >
                {Boolean(onDelete) && (
                    <IconButtonTolBar
                        icon={faTrash}
                        buttonType={BUTTON_TYPES.TEXT}
                        hiddenLabel={'REMOVE'}
                        onClick={() => setIsConfirmingDeletion(true)}
                    />
                )}
                {Boolean(onDownload) && (
                    <IconButtonTolBar
                        icon={faFileArrowDown}
                        buttonType={BUTTON_TYPES.TEXT}
                        hiddenLabel={'DOWNLOAD'}
                        onClick={onDownload}
                    />
                )}

                {Boolean(onFullMode) && (
                    <IconButtonTolBar
                        icon={faExpandWide}
                        buttonType={BUTTON_TYPES.TEXT}
                        hiddenLabel={'FULL_SCREEN'}
                        onClick={onFullMode}
                    />
                )}

                {isSubmitActive && (
                    <SaveButton
                        onClick={onSubmit}
                        buttonType={BUTTON_TYPES.PRIMARY}
                    >
                        SAVE
                    </SaveButton>
                )}
                {rest.children}
            </ToolbarButtons>
            <ConfirmationModal
                headerText={t('DELETE_IMAGE_HEADER')}
                isOpen={isConfirmingDeletion}
                onConfirm={() => {
                    onDelete();
                    setIsConfirmingDeletion(false);
                }}
                infoText={t('DELETE_IMAGE_BODY')}
                onCancel={() => setIsConfirmingDeletion(false)}
            />
        </Toolbar>
    );
};

ImageEditorToolbar.propTypes = {
    isSubmitActive: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onDownload: PropTypes.func,
    children: PropTypes.node,
};
export default ImageEditorToolbar;
