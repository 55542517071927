import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
    faLockKeyhole,
    faLockKeyholeOpen,
} from '@fortawesome/pro-regular-svg-icons';
import { compose, branch } from 'recompose';
import PropTypes from 'prop-types';
import { withInputValidation, withFinalFormField } from '../../../utils/hoc';
import IconButton from '../../button/icon-button/icon-button-component';
import InputField from './input-field-component';
import { BUTTON_TYPES } from '../../button';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledInput = styled(InputField)`
    color: ${({ theme, isLocked }) =>
        isLocked ? theme.colors.textSecondary : theme.colors.textPrimary};
`;

const StyledIconButton = styled(IconButton)`
    border-radius: 8px;
    display: flex;
    margin-left: 10px;
    margin-top: 10px;
`;

const LockableInput = ({ displayLock, isVisited, ...props }) => {
    const { value } = props;
    const [isLocked, setIsLocked] = useState(false);

    useEffect(() => {
        // value prop can be sent as empty value at first render
        // Only change the lock value if field is not visited
        if (!isVisited && value) {
            setIsLocked(true);
        }
    }, [value, isVisited]);

    const handleLockToggle = () => {
        setIsLocked(!isLocked);
    };

    return (
        <Wrapper>
            <StyledInput {...props} disabled={displayLock && isLocked} />
            {displayLock && (
                <StyledIconButton
                    icon={isLocked ? faLockKeyhole : faLockKeyholeOpen}
                    onClick={handleLockToggle}
                    hiddenLabel={'LOCK'}
                    buttonType={BUTTON_TYPES.PRIMARY}
                />
            )}
        </Wrapper>
    );
};

LockableInput.propTypes = {
    value: PropTypes.string,
    displayLock: PropTypes.bool,
    isVisited: PropTypes.bool,
};

export default compose(
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    )
)(LockableInput);
