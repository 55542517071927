import React from 'react';
import { rankWith, isStringControl } from '@jsonforms/core';
import { getCellProps, withInputControlHoc } from './cell-utils';
import { CellPropType } from './cell-prop-types';
import { TextInput } from '../../../input';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

// Textarea has a unwanted margin bottom with both label-wrapper and input field
const StyledTextInput = styled(TextInput)`
    ${({ maxLength }) =>
        maxLength &&
        maxLength > 90 &&
        css`
            margin-bottom: 0;
        `}
`;

function StringCell(props) {
    const { path, data, handleChange, schema } = props;

    const baseProps = {
        ...getCellProps(props),
        maxLength: schema.maxLength,
        value: data || '',
        onChange: e => handleChange(path, e.target.value),
    };

    return <StyledTextInput {...baseProps} autoComplete="off" stackedLabel />;
}
StringCell.propTypes = CellPropType;

export const tester = rankWith(2, isStringControl);

export default { renderer: withInputControlHoc(StringCell), tester };
