import React from 'react';
import styled from '@emotion/styled';
import { faPlus, faLayerPlus } from '@fortawesome/pro-regular-svg-icons';
import { BUTTON_TYPES } from '../button-component';
import IconButton from './icon-button-component';
import { MenuPopover } from '../../popovers';

const StyledAddButton = styled(IconButton)``;

const AddButton = ({ options = undefined, hiddenLabel, ...rest }) => {
    return options ? (
        <MenuPopover options={options} placement="bottom-start" smallMenu>
            <span tabIndex={-1}>
                <StyledAddButton
                    hiddenLabel={hiddenLabel || 'ADD_BUTTON'}
                    isRound
                    icon={faLayerPlus}
                    {...rest}
                />
            </span>
        </MenuPopover>
    ) : (
        <StyledAddButton
            hiddenLabel={hiddenLabel || 'ADD_BUTTON'}
            isRound
            icon={faPlus}
            buttonType={BUTTON_TYPES.PRIMARY}
            {...rest}
        />
    );
};

export default AddButton;
