import React from 'react';
import PropTypes from 'prop-types';
import { compose, branch } from 'recompose';
import Textarea from 'react-textarea-autosize';
import { omit } from 'lodash-es';
import {
    withInputValidation,
    withFinalFormField,
    withOnBlurChange,
} from '../../../utils/hoc';
import { withValidationWrapper } from '../../../utils/hoc/validation-wrapper-component';
import withLabel from '../../../utils/hoc/with-label-hoc';
import withErrorWrapper from '../../../utils/hoc/with-error-wrapper-hoc';
import styled from '@emotion/styled';
import { Input } from './input-field-component.styles';

const StyledTextArea = styled(Input.withComponent(Textarea))`
    max-width: 100%;
    line-height: 21px;
    resize: none;
    background: ${({ theme }) => theme.colors.bgPrimary};
`;

export const TextArea = props => {
    const { invalid, id, value, ...restProps } = props;

    const componentProps = omit(restProps, [
        't',
        'useDBTranslation',
        'subLabel',
        'hoverText',
    ]);

    return (
        <StyledTextArea
            {...componentProps}
            css={theme =>
                invalid && `border-color: ${theme.colors.errorColor}}`
            }
            value={String(value)}
            key={id}
            minRows={2}
            isValid={!invalid}
        />
    );
};

export default compose(
    withValidationWrapper,
    branch(
        ({ isFormField }) => isFormField,
        withFinalFormField,
        withInputValidation
    ),
    withLabel,
    withErrorWrapper,
    withOnBlurChange
)(TextArea);

TextArea.propTypes = {
    id: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    invalid: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
};

TextArea.defaultProps = {
    value: '',
};
