import { useEffect, useMemo, useState } from 'react';
import TabBar, { IBarButton } from './tab-bar';
import RotateIcon from '../icons/rotate-icon';
import { IconButton, Button } from '../..';
import styled from '@emotion/styled';
import ImageFilter, { IFilterSet } from './image-filter';
import ImageCrop, { ICrop } from './image-crop';
import { Flex } from '@rebass/grid/emotion';
import ImageRotate, { IRotateSet } from './image-rotate';
import { debounce } from 'lodash';
import { useImageUploadLocale } from './locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCropSimple, faSliders } from '@fortawesome/pro-solid-svg-icons';

const EditorContainer = styled.section`
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 16px;

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        justify-content: flex-start;
        gap: 45px;
    }
`;

const DekstopBarContainer = styled.div`
    display: none;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: block;
    }
`;

const MobileBarContainer = styled.section`
    display: flex;
    gap: 16px;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        display: none;
    }
`;

const CropperContainer = styled.section`
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-grow: 1;
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.l}) {
        flex-direction: column;
    }
`;

const OverrideIconButton = styled(IconButton)<{ border: boolean }>`
    height: 44px;
    padding: 0;
    margin: 0;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    border-color: ${({ theme, border }) =>
        border ? theme.colors.primaryColor20 : 'none'};
`;

enum Menu {
    rotate = 'rotate',
    filter = 'filter',
    crop = 'crop',
}

export interface IImagaEditorBarProps {
    editorRef: any;
}

function ImagaEditorBar({ editorRef }: IImagaEditorBarProps) {
    const locale = useImageUploadLocale();

    const menuList: IBarButton[] = [
        {
            keyTranslate: locale.Filter,
            type: Menu.filter,
            icon: <FontAwesomeIcon fontSize={20} icon={faSliders} />,
        },
        {
            keyTranslate: locale.Crop,
            type: Menu.crop,
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            keyTranslate: locale.Rotate,
            type: Menu.rotate,
            icon: <RotateIcon />,
        },
    ];

    const [menu, setMenu] = useState<IBarButton>(menuList[0]);

    const [crop, setCrop] = useState<ICrop>();

    const cropperSet: ICrop[] = [
        {
            label: locale.Square,
            value: '1/1',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            label: '3/2',
            value: '3/2',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            label: '4/3',
            value: '4/3',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            label: '5/4',
            value: '5/4',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            label: '7/5',
            value: '7/5',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
        {
            label: '16/9',
            value: '16/9',
            icon: <FontAwesomeIcon fontSize={20} icon={faCropSimple} />,
        },
    ];

    const [filter, setFilter] = useState<IFilterSet>({
        brightness: { label: locale.Brightness, value: 0 },
        contrast: { label: locale.Contrast, value: 0 },
        saturation: { label: locale.Saturation, value: 0 },
    });

    const [rotateSet, setRotateSet] = useState<IRotateSet>({
        rotate: { label: locale.Range, value: 0 },
    });

    const handleChangeFilter = (change: IFilterSet) => {
        setFilter(prev => ({ ...prev, ...change }));
        Object.entries(change).forEach(([key, filterValue]) => {
            applyFilterDebounced(key, (filterValue as { value: number }).value);
        });
    };

    const applyFilterDebounced = useMemo(
        () =>
            debounce((key: string, value: number) => {
                const editor = editorRef?.current?.getInstance?.();
                if (!editor) return;

                switch (key) {
                    case 'brightness':
                        editor.applyFilter('brightness', {
                            brightness: value / 100,
                        });
                        break;
                    case 'contrast':
                        editor.applyFilter('contrast', {
                            contrast: value / 100,
                        });
                        break;
                    case 'shadow':
                        editor.applyFilter('shadow', {
                            shadow: value / 100,
                        });
                        break;
                    case 'saturation':
                        editor.applyFilter('saturation', {
                            saturation: value / 100,
                        });
                        break;
                    case 'warmth':
                        editor.applyFilter('warmth', {
                            warmth: value / 100,
                        });
                        break;
                }
            }, 300),
        [editorRef]
    );

    const handleChangeRotate = (change: IRotateSet) => {
        setRotateSet(prev => ({ ...prev, ...change }));
    };

    const handleApplyRotate = () => {
        const editor = editorRef?.current?.getInstance?.();
        if (!editor) {
            return;
        }

        editor.setAngle(rotateSet.rotate.value).then(async (status: any) => {
            setRotateSet({ ...rotateSet });
            const size = editor.getCanvasSize();
            editor.ui.resizeEditor({
                uiSize: { width: size.width, height: size.height },
                imageSize: {
                    newWidth: size.width,
                    newHeight: size.height,
                },
            });
        });
    };

    useEffect(() => {
        const editor = editorRef?.current?.getInstance?.();
        if (editor && menu.type !== Menu.crop) {
            editor.stopDrawingMode();
            return;
        }
        if (editor && crop) {
            editor.stopDrawingMode();
            const [numerator, denominator] = crop.value.split('/').map(Number);
            const aspect = numerator / denominator;

            if (aspect) {
                editor.startDrawingMode('CROPPER');
                editor.setCropzoneRect(aspect);
            }
        } else {
            editor?.stopDrawingMode();
        }
    }, [crop, menu]);

    const handleStopDrawingMode = () => {
        const editor = editorRef?.current?.getInstance?.();
        editor?.stopDrawingMode();
    };

    const handleApplyCrop = () => {
        const editor = editorRef?.current?.getInstance?.();
        if (!editor) {
            return;
        }

        const rect = editor.getCropzoneRect();
        editor.stopDrawingMode();
        editor.crop(rect);
    };

    return (
        <EditorContainer>
            <DekstopBarContainer>
                <TabBar
                    activeTab={menu}
                    setActiveTab={setMenu}
                    tabs={menuList}
                />
            </DekstopBarContainer>

            {menu.type === Menu.filter && (
                <ImageFilter
                    filter={filter}
                    changeFilter={handleChangeFilter}
                />
            )}
            {menu.type === Menu.rotate && (
                <CropperContainer>
                    <ImageRotate
                        rotateSet={rotateSet}
                        changeRotate={handleChangeRotate}
                    />
                    <Flex flexDirection="row" justifyContent="flex-end">
                        <Button
                            buttonType="primary"
                            onClick={handleApplyRotate}
                        >
                            {locale.Apply}
                        </Button>
                    </Flex>
                </CropperContainer>
            )}

            {menu.type === Menu.crop && (
                <CropperContainer>
                    <ImageCrop
                        crop={crop}
                        setCrop={setCrop}
                        listCrop={cropperSet}
                    />

                    <Flex flexDirection="row" justifyContent="space-between">
                        <Button
                            buttonType={null}
                            onClick={handleStopDrawingMode}
                        >
                            {locale.Cancel}
                        </Button>
                        <Button buttonType="primary" onClick={handleApplyCrop}>
                            {locale.Apply}
                        </Button>
                    </Flex>
                </CropperContainer>
            )}

            <MobileBarContainer>
                {menuList.map(value => (
                    <OverrideIconButton
                        key={value.type}
                        icon={value.icon}
                        small={true}
                        border={value.type === menu.type}
                        buttonType={'primary_light'}
                        hiddenLabel={value.keyTranslate}
                        onClick={() => {
                            setMenu(value);
                        }}
                    />
                ))}
            </MobileBarContainer>
        </EditorContainer>
    );
}

export default ImagaEditorBar;
