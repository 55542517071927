import { ButtonSize } from '../button-size';
import { IconButtonMode } from './icon-button-types';

export const sizeSet: Record<ButtonSize, number> = {
    [ButtonSize.Small]: 30,
    [ButtonSize.Medium]: 34,
    [ButtonSize.Large]: 42,
};

export const sizeExtendSet: Record<
    IconButtonMode,
    Record<ButtonSize, number>
> = {
    [IconButtonMode.rectangel]: sizeSet,
    // Sizes from Figma
    // [IconButtonMode.square]: {
    //     [ButtonSize.Small]: 28,
    //     [ButtonSize.Medium]: 42,
    //     [ButtonSize.Large]: 52,
    // },
    // [IconButtonMode.rounded]: {
    //     [ButtonSize.Small]: 34,
    //     [ButtonSize.Medium]: 42,
    //     [ButtonSize.Large]: 52,
    // },
    [IconButtonMode.square]: sizeSet,
    [IconButtonMode.rounded]: sizeSet,
};

export const paddingForIconSet: Record<ButtonSize, number> = {
    [ButtonSize.Small]: 7,
    [ButtonSize.Medium]: 12,
    [ButtonSize.Large]: 24,
};

export const paddingForTextSet: Record<ButtonSize, number> = {
    [ButtonSize.Small]: 10,
    [ButtonSize.Medium]: 20,
    [ButtonSize.Large]: 32,
};

export const gapSet: Record<ButtonSize, number> = {
    [ButtonSize.Small]: 3,
    [ButtonSize.Medium]: 6,
    [ButtonSize.Large]: 8,
};
