import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import styled from '@emotion/styled';
import { css, ClassNames } from '@emotion/react';
import { Flex } from '@rebass/grid/emotion';
import { TEXT_STYLES } from '../typograph/typograph-components';
import ModalHeaderText from './header/modal-header-text-component';
import ModalBackButton from './header/modal-back-button-component';
import ModalCloseButton from './header/modal-close-button-component';

const FlexContainer = styled.div`
    outline-style: none;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    flex-grow: 1;
    max-width: 100vw;
    overflow: ${({ allowOverflow }) => (allowOverflow ? 'visible' : 'hidden')};
`;

const StyledHeaderText = styled(ModalHeaderText, {
    shouldForwardProp: prop => !['size'].includes(prop),
})`
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        ${({ size, theme }) => {
            if (size !== 's') return '';
            return TEXT_STYLES.header3Text(theme);
        }}
    }
`;

const HeaderContainer = styled(Flex)`
    align-items: center;
    justify-content: center;
    padding: ${({ theme: { spacing } }) => `50px 0 ${spacing.large} 0`};
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        padding: ${({ theme: { spacing } }) =>
            `${spacing.default} 0 ${spacing.large} 0`};
    }
`;

const modalContentClass = theme => css`
    overflow: auto;
    display: flex;
    flex-grow: 1;
    word-break: break-word;
    width: 100%;
`;

const smallClass = theme => css`
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        max-width: 500px;
    }
    min-height: 250px;
`;
const mediumClass = css`
    max-width: 700px;
    min-height: 400px;
`;
const largeClass = css`
    max-width: 900px;
    min-height: 500px;
`;

const extraLargeClass = css`
    max-width: 1200px;
    min-height: 500px;
`;

const fullSizeClass = theme => css`
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        max-width: 95vw;
        height: 90vh;
    }
`;
const fullScreenClass = theme => css`
    max-width: 100vw;
    height: 100vh;
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        height: 100vh;
    }
`;

const overLayClass = css`
    max-height: 100%;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    transition: all 0.5s ease 0s;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    z-index: 9005 !important; // 9005 is used for the modals to appear above menu and header
    .ReactModal__Content {
        display: flex;
        border: none;
    }
`;

const _modalClass = theme => css`
    overflow: hidden;
    outline: none;
    padding: 20px 20px 50px 20px;
    background-color: ${theme.colors.bgPrimary};
    position: relative;
    height: 100%;
    max-height: 100%;
    transition: height 250ms ease-in-out;
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        padding: 30px;
        background-color: ${theme.colors.bgPrimary};
        height: unset;
        border-radius: ${theme.borderRadius.medium};
    }
`;
const fullWidthClass = css`
    width: 100%;
`;
const noPaddingClass = theme => css`
    padding: 0;
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        padding: 0;
    }
`;

const AbsoluteBackButton = styled(ModalBackButton)`
    position: absolute;
    left: 20px;
    top: 20px;
    padding-bottom: 10px;
`;

const _Modal = props => {
    const {
        size,
        contentClass,
        modalClass,
        headerText,
        headerIcon,
        headerClass,
        onClose,
        renderBackButton = () => <AbsoluteBackButton onClick={onClose} />,
        renderCloseButton = () => <ModalCloseButton onClose={onClose} />,
        overlayClassNames,
    } = props;
    const cssClasses = React.useMemo(
        () =>
            [
                _modalClass,
                size === 's' && smallClass,
                size === 'm' && mediumClass,
                size === 'l' && largeClass,
                size === 'xl' && extraLargeClass,
                size === 'full-size' && fullSizeClass,
                size === 'fullscreen' && fullScreenClass,
                !headerText && noPaddingClass,
                fullWidthClass,
                modalClass,
            ].filter(Boolean),
        [size, headerText, modalClass]
    );

    const cssContentClasses = React.useMemo(
        () => [modalContentClass, contentClass],
        [contentClass]
    );

    return (
        <ClassNames>
            {({ css, theme }) => (
                <Modal
                    isOpen={props.isOpen}
                    ariaHideApp={false}
                    css={cssClasses}
                    overlayClassName={css(
                        overLayClass,
                        ...overlayClassNames?.({ css, theme })
                    )}
                    onRequestClose={onClose}
                >
                    <FlexContainer allowOverflow={props.allowOverflow}>
                        {renderBackButton()}
                        {(headerText || headerIcon) && (
                            <HeaderContainer css={[headerClass]}>
                                {headerIcon}
                                {typeof headerText === 'string' ? (
                                    <StyledHeaderText
                                        text={headerText}
                                        size={size}
                                    />
                                ) : (
                                    headerText
                                )}
                            </HeaderContainer>
                        )}
                        {renderCloseButton()}
                        <div css={cssContentClasses}>{props.children}</div>
                    </FlexContainer>
                </Modal>
            )}
        </ClassNames>
    );
};

_Modal.propTypes = {
    isOpen: PropTypes.bool,
    headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    contentClass: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    modalClass: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    size: PropTypes.oneOf(['s', 'm', 'l', 'xl', 'full-size', 'fullscreen']),
    children: PropTypes.node,
    headerIcon: PropTypes.object,
    renderCloseButton: PropTypes.func,
    renderBackButton: PropTypes.func,
    overlayClassNames: PropTypes.func,
    headerClass: PropTypes.func,
};

_Modal.defaultProps = {
    size: 'm',
    isOpen: false,
    headerText: '',
    headerIcon: null,
    contentClass: null,
    modalClass: null,
    onClose: () => {},
    overlayClassNames: () => [],
    headerClass: () => {},
};

export default _Modal;
