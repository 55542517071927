import PropTypes from 'prop-types';
import { formatDate } from '../../../../shared-utils/src/date-utils';

// TODO: Add localisation
export default function DisplayDateTIme(props) {
    if (!props.children) {
        return null;
    }
    return <span>{formatDate(props.children, true)}</span>;
}

DisplayDateTIme.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
};
