import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,
    faCircleExclamation,
    faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { BodyTextRegular } from '../typograph/typograph-components';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

export const INFO_MESSAGE_TYPES = {
    ERROR: 'error',
    INFO: 'info',
    WARNING: 'warning',
};

const { ERROR, INFO, WARNING } = INFO_MESSAGE_TYPES;

const InfoMessageWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 300px;
    background: ${({ theme: { colors }, type }) => {
        switch (type) {
            case 'info':
                return colors.primaryColor10;
            case 'warning':
                return colors.pending10;
            case 'error':
                return colors.red10;
            default:
                return colors.primaryColor10;
        }
    }};
    padding: ${({ theme: { spacing } }) => spacing.default};
    border-radius: ${({ theme: { borderRadius } }) => borderRadius.default};
    margin-bottom: ${({ theme: { spacing } }) => spacing.default};
`;

const InfoMessageIcon = styled(FontAwesomeIcon)`
    width: 20px;
    height: 20px;
    padding-right: ${({ theme: { spacing } }) => spacing.default};
    color: ${({ theme: { colors }, type }) => {
        switch (type) {
            case 'info':
                return colors.primaryColor;
            case 'warning':
                return colors.pendingPrimary;
            case 'error':
                return colors.redPrimary;
            default:
                return colors.primaryColor;
        }
    }};
`;

const CloseIcon = styled(FontAwesomeIcon)`
    font-size: ${({ theme }) => theme.fontSizeMedium};
    margin-left: auto;
    cursor: pointer;
    align-self: flex-start;
    padding-left: ${({ theme: { spacing } }) => spacing.default};
`;

const INFO_MESSAGE_ICONS = {
    [INFO]: faCircleInfo,
    [ERROR]: faCircleExclamation,
    [WARNING]: faTriangleExclamation,
};

const InfoMessage = ({ type, text, onClose, useHtml = false, ...props }) => {
    return (
        <InfoMessageWrapper type={type} {...props}>
            <InfoMessageIcon icon={INFO_MESSAGE_ICONS[type]} type={type} />
            {useHtml ? (
                <BodyTextRegular
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
            ) : (
                <BodyTextRegular>{text}</BodyTextRegular>
            )}
            <CloseIcon
                data-testid={`${type}-icon`}
                icon={faTimes}
                onClick={onClose}
            />
        </InfoMessageWrapper>
    );
};

InfoMessage.propTypes = {
    type: PropTypes.oneOf(['info', 'warning', 'error']).isRequired,
    text: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    useHtml: PropTypes.bool,
};

export default InfoMessage;
