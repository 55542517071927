import styled from '@emotion/styled';
import {
    faCompress,
    faExpandWide,
    faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@mspecs/shared-utils';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Header5Text } from '../../../typograph/typograph-components';
import MultiFileUploadListItem from './multi-file-upload-list-item-component';
import { FileUploadPropType } from './multi-file-upload-prop-types';

const Container = styled(Flex)`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 10px;
    min-height: 40px;
    max-height: 317px;
    max-width: 696px;
    overflow-y: auto;
    z-index: 999999;
    margin-right: auto;
    margin-left: auto;
    background: ${({ theme }) => theme.colors.bgPrimary};
    box-shadow: ${({ theme }) => theme.boxShadows.defaultHover};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    flex-direction: column;
    padding: ${({ theme }) =>
        `${theme.spacing.default} ${theme.spacing.large}`};

    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.s}) {
        width: 387px;
        left: unset;
        right: 16px;
        bottom: 16px;
    }
    @media (min-width: ${({ theme }) => theme.responsiveBreakpoints.m}) {
        right: 35px;
        bottom: 35px;
    }
`;

const FileList = styled(Flex)`
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
`;

const CloseContainer = styled.div`
    position: absolute;
    right: 5px;
    top: 0;
    height: 60px;
    width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.textSecondary};
    cursor: pointer;
    z-index: 2;

    :hover {
        color: ${({ theme }) => theme.colors.textPrimary};
    }
`;
const CloseIcon = styled(FontAwesomeIcon)`
    font-size: 22.5px;
`;
const ExpandButton = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => !['expanded'].includes(prop),
})`
    font-size: 22.5px;
    position: absolute;
    right: ${({ expanded }) => (expanded ? '75px' : '20px')};
    top: 18px;
    z-index: 2;
`;

const Header = styled.div`
    position: relative;
    padding: ${({ theme }) => theme.spacing.default} 0;
    text-align: ${({ expanded }) => expanded && 'center'};
`;

const HeaderText = styled(Header5Text)``;

const MultiFileUploadIndicator = ({ files, onAbortRequest }) => {
    const { t } = useTranslate();
    const [expanded, setExpanded] = useState(true);
    const onRemoveFile = file => {
        file.onRemove();
        onAbortRequest(file);
    };

    const allFilesUploaded = files.every(f => !f.isLoading && f.isSuccess);
    const uploadedFiles = files.filter(f => f.isSuccess);

    if (files.length === 0 || (allFilesUploaded && !expanded)) {
        return null;
    }

    return (
        <Container>
            <ExpandButton
                icon={expanded ? faCompress : faExpandWide}
                onClick={() => setExpanded(prev => !prev)}
                expanded={expanded}
            />
            {expanded && (
                <CloseContainer onClick={() => files.map(onRemoveFile)}>
                    <CloseIcon icon={faXmark} />
                </CloseContainer>
            )}
            <Header expanded={expanded}>
                <HeaderText>
                    {expanded
                        ? t('IMAGE_UPLOAD_HEADER_TEXT')
                        : t('IMAGE_UPLOAD_HEADER_COMPACT_TEXT', {
                              current: uploadedFiles.length,
                              total: files.length,
                          })}
                </HeaderText>
            </Header>
            {expanded && (
                <FileList>
                    {files.map(file => (
                        <MultiFileUploadListItem
                            file={file}
                            key={file.identifier}
                            onRemove={() => onRemoveFile(file)}
                        />
                    ))}
                </FileList>
            )}
        </Container>
    );
};

MultiFileUploadIndicator.propTypes = {
    files: PropTypes.arrayOf(FileUploadPropType),
    onAbortRequest: PropTypes.func,
};

export default MultiFileUploadIndicator;
