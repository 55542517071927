import styled from '@emotion/styled';
import { Button } from '../..';

export const OverrideButton = styled(Button)<{ border?: boolean }>`
    height: 30px;
    padding: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: ${({ theme, border }) =>
        border ? theme.colors.primaryColor20 : 'transparent'};

    &:hover {
        text-decoration: none;
    }
`;
