import styled from '@emotion/styled';
import clsx from 'clsx';
import { TEXT_STYLES } from '../..';

const TabBarContainerStyle = styled('nav')`
    display: flex;
    flex-direction: row;
    height: 70px;
`;

const Button = styled('button')`
    position: relative;
    width: 100%;

    padding: 0 6px;
    padding-top: 10px;
    padding-bottom: 15px;

    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: center;
    justify-content: center;

    background: none;
    color: ${({ theme }) => theme.colors.textSecondary};
    border: none;

    cursor: pointer;

    ${({ theme }) => TEXT_STYLES.buttonTextSmall(theme)}

    &.active {
        color: ${({ theme }) => theme.colors.primaryColor};
    }

    &::before {
        position: absolute;
        content: '';
        height: 2px;
        width: 100%;
        bottom: 0;
        left: 0;
        right: 0;
        background: ${({ theme }) => theme.colors.bgSecondary140};
    }

    &.active::before {
        background: ${({ theme }) => theme.colors.primaryColor};
    }

    &:hover:before {
        background: ${({ theme }) => theme.colors.primaryColor};
    }
`;

export interface IBarButton {
    keyTranslate: string;
    type: string;
    icon?: React.ReactNode;
}

export interface ITabBarProps {
    activeTab: IBarButton;
    setActiveTab: (active: IBarButton) => void;
    tabs: IBarButton[];
}

function TabBar({ activeTab, setActiveTab, tabs }: ITabBarProps) {
    return (
        <TabBarContainerStyle>
            {tabs.map(tab => (
                <Button
                    key={tab.keyTranslate}
                    className={clsx(
                        activeTab.type === tab.type ? 'active' : ''
                    )}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab.icon}
                    {tab.keyTranslate}
                </Button>
            ))}
        </TabBarContainerStyle>
    );
}

export default TabBar;
