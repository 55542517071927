import {
    and,
    formatIs,
    isStringControl,
    optionIs,
    or,
    rankWith,
} from '@jsonforms/core';
import { TelephoneInput } from '../../../input';
import { getCellProps, withInputControlHoc } from './cell-utils';

function PhoneCell(props: ICellRendererProps) {
    const { path, handleChange } = props;

    const cellProps = getCellProps(props);
    const baseProps = {
        ...cellProps,
        onChange: (value: string) => {
            handleChange(path, value);
        },
        stackedLabel: true,
        errorMessage: cellProps.additionalErrors,
        invalid: !!cellProps.additionalErrors,
    };

    return <TelephoneInput {...baseProps} />;
}

// The onFocus and onBlur causes re-render that makes the phone country select impossible to use
const containerProps = {
    onFocus: () => {},
    onBlur: () => {},
};

export const tester = rankWith(
    3,
    and(isStringControl, or(formatIs('phone'), optionIs('format', 'phone')))
);

export default {
    renderer: withInputControlHoc(PhoneCell, containerProps),
    tester,
};
