import React from 'react';
import styled from '@emotion/styled';
import { faCopy, faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInputField } from '../../input/form';
import IconButton, {
    StyledIconButton,
} from '../../button/icon-button/icon-button-component';

const CopyButton = styled(StyledIconButton)`
    margin-left: 2px;
`;

const RefreshButton = styled(IconButton)`
    margin-left: 2px;
`;

const CopyRefreshButtons = ({ value, onRefresh }) => {
    return (
        <Flex flex={1}>
            <FormInputField value={value} disabled />
            <CopyToClipboard text={value}>
                <CopyButton small hiddenLabel="COPY">
                    <FontAwesomeIcon icon={faCopy} size="sm" />
                </CopyButton>
            </CopyToClipboard>
            <RefreshButton
                icon={faRefresh}
                onClick={onRefresh}
                hiddenLabel="REFRESH"
            />
        </Flex>
    );
};

CopyRefreshButtons.propTypes = {
    value: PropTypes.string,
    onRefresh: PropTypes.func,
};

export default CopyRefreshButtons;
