import React, { useCallback, useEffect, useMemo } from 'react';
import { ScrollSync } from 'react-scroll-sync';
import {
    useTable,
    useSortBy,
    useRowSelect,
    useExpanded,
    usePagination,
    useFlexLayout,
    useColumnOrder,
} from 'react-table';
import { useTheme, css } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { useSticky } from 'react-table-sticky';

import { useDraggableRow, useSelectable, useRowMenu } from '../utils';
import styles from '../table-styles';
import { TableColumPropType } from '../table-prop-types';
import TableBody from './table-body/table-body-component';
import TableHeader from './table-header-component';
import TablePagination from './table-pagination-component';
import TableFooter from './table-footer-component';
import SubTable from './sub-table/sub-table-wrapper-component';
import { useTranslate } from '@mspecs/shared-utils';
import useTableOverflowing from '../hooks/use-table-overflowing';

const noop = x => x;

export const TableWrapper = styled('div', {
    shouldForwardProp: prop => !['loading'].includes(prop),
})`
    overflow: hidden;
    .sticky {
        .rt-tbody {
            overflow: hidden;
        }
        .rt-thead {
            display: flex;
            flex-direction: ${({ loading }) => loading && 'column'};
        }
        .rt-tr-group {
            > div {
                display: flex;
            }
        }

        [data-sticky-td] {
            position: sticky;
        }
    }
`;

const Table = ({
    loading = false,
    fetchingMore = false,
    error = undefined,
    localisation = {},
    selectable = false,
    droppableProps = {},
    useCustomDragAndDropContext = false,
    isRowDragEnabled = false,
    isRowsClickable = false,
    columns = [],
    disableSort = false,
    initialSelectedValues = [],
    defaultSorted = [],
    showPagination = false,
    defaultPageSize = 10,
    className = undefined,
    onSelectedValueChange = noop,
    data = [],
    keyField = undefined,
    getRowProps = noop,
    checkboxPositionIndex = 0,
    totalCount = undefined,
    onSortingChange = noop,
    tableStyles = noop,
    showFooter = false,
    itemSize = 65,
    onReorderData = noop,
    isColumnDragEnabled = false,
    onColumnOrderChange = noop,
    columnOrder = undefined,
    hooks = noop,
    rowMenuOptions = [],
    rowMenuFirst = false,
    nestedData = {},
    nestedTableProps = {},
    maxNestingLevel = undefined,
    infiniteScroll = false,
    loadMoreItems = noop,
    defaultItemSize = undefined,
    noTableHeader = false,
    searchTerm = undefined,
    hasExpand = false,
    disableDisplaySelectableCheckbox = false,
    ...restProps
}) => {
    const { t } = useTranslate();
    const theme = useTheme();

    const _localisation = {
        previousText: t('TABLE_PREVIOUS'),
        loadingText: t('TABLE_LOADING'),
        noDataText: t('TABLE_NO_DATA'),
        nextText: t('TABLE_NEXT'),
        rowsText: t('TABLE_ROWS'),
        pageText: t('TABLE_PAGE'),
        ofText: t('TABLE_OF'),
        ...localisation,
    };

    const getRowId = React.useCallback(
        (row, relativeIndex, parent) => {
            return parent
                ? [parent.id, relativeIndex].join('.')
                : row._newRow || row[keyField] || row.id || `${relativeIndex}`;
        },
        [keyField]
    );

    const isRowMenuEnabled = !!rowMenuOptions.length;
    const initialTableState = useMemo(() => {
        return {
            ...(selectable
                ? {
                      selectedRowIds: initialSelectedValues.reduce(
                          (acc, curr) => ({
                              ...acc,
                              [curr]: true,
                          }),
                          {}
                      ),
                  }
                : {}),
            hiddenColumns: columns
                .filter(col => col.hide)
                .map(col => col.accessor),
            pageSize: defaultPageSize,
            sortBy: defaultSorted,
            columnOrder,
        };
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups, // TODO: headerGroups.headers.render('Header') -> React no-op, maybe missing somekind of default value or something, somewhere?
        footerGroups,
        rows,
        prepareRow,
        state: { sortBy, pageIndex, pageSize, selectedRowIds },
        page,
        pageCount,
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        setColumnOrder,
        setHiddenColumns,
    } = useTable(
        {
            columns,
            data,
            initialState: initialTableState,
            onReorderData,
            getRowId,
            manualSortBy: onSortingChange === noop ? false : true,
            ...restProps,
        },
        useColumnOrder,
        useFlexLayout,
        useSticky,
        !disableSort ? useSortBy : () => {},
        showPagination ? usePagination : () => {},
        hasExpand ? useExpanded : () => {},
        selectable ? useRowSelect : () => {},
        selectable && !disableDisplaySelectableCheckbox
            ? useSelectable(checkboxPositionIndex)
            : () => {},
        isRowDragEnabled ? useDraggableRow : () => {},
        isRowMenuEnabled ? useRowMenu(rowMenuFirst, rowMenuOptions) : () => {},
        hooks
    );

    useEffect(() => {
        setHiddenColumns(
            columns.filter(col => col.hide).map(col => col.accessor)
        );
    }, [columns]);

    /* Drag row order */
    /* Reload cache when sorting changes */
    useEffect(() => {
        onSortingChange({ sortBy });
    }, [sortBy]);

    /* Emit selected rows when selection changes */
    useEffect(() => {
        if (selectable && selectedRowIds) {
            onSelectedValueChange(Object.keys(selectedRowIds));
        }
    }, [selectable, selectedRowIds]);

    const { isTableOverflowing, isTableRowsFillingBody, overflowRef } =
        useTableOverflowing({
            hasBodyWrapper: infiniteScroll || isRowDragEnabled,
            itemsCount: data?.length ?? 0,
        });

    const isItemLoaded = useCallback(
        index => index < rows.length,
        [rows?.length]
    );

    const findNestingLevel = useCallback(
        id => {
            let nestingLevel = 0;
            let parentId = id;
            while (parentId) {
                let [parent = null] =
                    Object.entries(nestedData).find(([, values]) =>
                        values.find(n => n.id === parentId)
                    ) ?? [];
                parentId = parent;
                nestingLevel++;
            }

            return nestingLevel;
        },
        [nestedData]
    );

    const NestedTable = ({ id, getRowProps }) => {
        const nestingLevel = findNestingLevel(id);
        return (
            <SubTable
                getRowProps={getRowProps}
                isRowDragEnabled={isRowDragEnabled}
                columns={columns}
                data={nestedData?.[id] ?? []}
                renderChildren={nestedRowId =>
                    nestedData[nestedRowId] && (
                        <NestedTable
                            id={nestedRowId}
                            getRowProps={getRowProps}
                        />
                    )
                }
                droppableProps={{
                    droppableId: id,
                    isDropDisabled:
                        maxNestingLevel && nestingLevel > maxNestingLevel,
                }}
                {...nestedTableProps}
            />
        );
    };

    const isSticky = columns.some(col => col.sticky);
    const hasNestedData = Object.keys(nestedData).length > 0;

    return (
        <TableWrapper
            className={`ReactTable ${className || ''}`}
            css={[styles, tableStyles]}
            loading={loading}
        >
            <ScrollSync vertical={false}>
                <div
                    className={`rt-table ${
                        isRowsClickable ? ' --clickable-rows' : ''
                    } ${isSticky ? ' sticky' : ''} ${
                        isTableOverflowing.x ? '--is-overflowing-x' : ''
                    }${isTableOverflowing.y ? ' --is-overflowing-y' : ''}${
                        isTableRowsFillingBody ? ' --is-filling-body' : ''
                    }${restProps.isListView ? ' --list-view' : ''}${
                        hasNestedData ? ' --has-nested-data' : ''
                    }${isRowDragEnabled ? ' --is-row-drag-enabled' : ''}
                    `}
                    {...getTableProps()}
                >
                    {!noTableHeader && (
                        <TableHeader
                            theme={theme}
                            loading={loading}
                            disableSort={disableSort}
                            headerGroups={headerGroups}
                            setColumnOrder={setColumnOrder}
                            onColumnOrderChange={onColumnOrderChange}
                            isColumnDragEnabled={isColumnDragEnabled}
                            columns={columns}
                        />
                    )}
                    <TableBody
                        page={page}
                        rows={rows}
                        data={data}
                        error={error}
                        loading={loading}
                        fetchingMore={fetchingMore}
                        itemSize={itemSize}
                        prepareRow={prepareRow}
                        totalCount={totalCount}
                        getRowProps={row => {
                            const rowProps = getRowProps?.(row) ?? {};
                            rowProps.children = row.original.children;
                            if (hasNestedData) {
                                rowProps.children = (
                                    <NestedTable
                                        id={row.id}
                                        getRowProps={getRowProps}
                                    />
                                );
                            }
                            return rowProps;
                        }}
                        isItemLoaded={isItemLoaded}
                        onReorderData={onReorderData}
                        getTableBodyProps={getTableBodyProps}
                        isRowDragEnabled={isRowDragEnabled}
                        infiniteScroll={infiniteScroll}
                        loadMoreItems={loadMoreItems}
                        defaultItemSize={defaultItemSize}
                        droppableProps={droppableProps}
                        useCustomDragAndDropContext={
                            useCustomDragAndDropContext
                        }
                        ref={overflowRef}
                        // Need pass down searchTerm and sortBy to InfiniteScroll-tables (to reset cache on change)
                        // Would be nice to not proxy all props by TableWrapper, but for now it's the easiest way
                        sortBy={sortBy}
                        searchTerm={searchTerm}
                        hasNestedData={hasNestedData}
                    />
                    {showFooter && <TableFooter footerGroups={footerGroups} />}
                </div>
            </ScrollSync>
            {showPagination && (
                <TablePagination
                    gotoPage={gotoPage}
                    pageSize={pageSize}
                    nextPage={nextPage}
                    pageCount={pageCount}
                    pageIndex={pageIndex}
                    setPageSize={setPageSize}
                    canNextPage={canNextPage}
                    previousPage={previousPage}
                    _localisation={_localisation}
                    canPreviousPage={canPreviousPage}
                />
            )}
            {!loading && !(showPagination ? page : rows).length && (
                <div
                    className="rt-tr"
                    css={css`
                        padding: 10px;
                    `}
                >
                    {_localisation.noDataText}
                </div>
            )}
        </TableWrapper>
    );
};

Table.defaultProps = {
    checkboxPositionIndex: 0,
    itemSize: 65,
    menuFirst: false,
    rowMenuOptions: [],
};

Table.propTypes = {
    loadMoreItems: PropTypes.func,
    data: PropTypes.array,
    loading: PropTypes.bool,
    fetchingMore: PropTypes.bool,
    localisation: PropTypes.object,
    columns: PropTypes.arrayOf(TableColumPropType).isRequired,
    className: PropTypes.string,
    /** getRowProps is defined in the react-table api, maybe differenciate this from that //DS getExternalRowProps? */
    getRowProps: PropTypes.func,
    showFooter: PropTypes.bool,
    hooks: PropTypes.func,

    /* If the table is searchable or can be filtered */
    isSearchable: PropTypes.bool,
    searchTerm: PropTypes.string,

    /* Row order */
    isRowDragEnabled: PropTypes.bool,
    isRowsClickable: PropTypes.bool,
    droppableProps: PropTypes.object,
    useCustomDragAndDropContext: PropTypes.bool,

    /* Column order */
    isColumnDragEnabled: PropTypes.bool,
    onColumnOrderChange: PropTypes.func,
    columnOrder: PropTypes.array,

    /* Selectable props */
    selectable: PropTypes.bool,
    disableDisplaySelectableCheckbox: PropTypes.bool,
    initialSelectedValues: PropTypes.array,
    onSelectedValueChange: PropTypes.func,
    checkboxPositionIndex: PropTypes.number,

    /* Pagination props */
    showPagination: PropTypes.bool,
    defaultPageSize: PropTypes.number,
    keyField: PropTypes.string,

    /* Infinite scrolling props */
    infiniteScroll: PropTypes.bool,
    totalCount: PropTypes.number,
    itemSize: PropTypes.number,
    defaultItemSize: PropTypes.number,

    /* Sorting props */
    defaultSorted: PropTypes.array,
    disableSort: PropTypes.bool,
    onSortingChange: PropTypes.func,

    /* Menu options */
    rowMenuFirst: PropTypes.bool,
    menuComponent: PropTypes.func,
    rowMenuOptions: PropTypes.array,

    error: PropTypes.string,
    tableStyles: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.array,
        PropTypes.func,
    ]),
    onReorderData: PropTypes.func,

    /*
        Provide for displaying nestad table rows. 
        Should be a object with rowIds as keys and value as an array of objects
     */
    nestedData: PropTypes.objectOf(PropTypes.array),
    nestedTableProps: PropTypes.object,
    maxNestingLevel: PropTypes.number,

    // expnad props
    hasExpand: PropTypes.bool,
};

export default React.memo(Table);
