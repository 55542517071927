import styled from '@emotion/styled';

const SliderContainer = styled.div`
    display: flex;
    align-items: center;
    height: 16px;
`;

const SliderRange = styled.input`
    all: unset;
    -webkit-appearance: none;
    height: 4px;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.primaryColor40};
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 16px;
        aspect-ratio: 1/1;
        background-color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;
    }

    &::-moz-range-thumb {
        height: 16px;
        aspect-ratio: 1/1;
        background-color: ${({ theme }) => theme.colors.primaryColor};
        border-radius: 50%;
        cursor: pointer;
    }
`;

export interface SliderProps {
    min?: number;
    step?: number;
    max?: number;
    value: number;
    setValue: (value: number) => void;
}

function Slider({
    min = 1,
    step = 1,
    value = 2,
    max = 3,
    setValue,
}: SliderProps) {
    return (
        <SliderContainer>
            <SliderRange
                type="range"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={e => setValue(Number(e.target.value))}
            />
        </SliderContainer>
    );
}

export default Slider;
