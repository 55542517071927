import {
    ColorHue,
    ThemePalette,
} from '../themes/theme-mode';
import { ColorHueVariables } from '@mspecs/shared-utils/src/styles/color-variables';

export const colorsThemeSet: Record<
    ThemePalette,
    Record<ColorHueVariables, ColorHue>
> = {
    default: {
        [ColorHueVariables.primaryColor]: ColorHue.blue,
        [ColorHueVariables.contrastBg]: ColorHue.blue,
        [ColorHueVariables.accent]: ColorHue.accent,
        [ColorHueVariables.green]: ColorHue.green,
        [ColorHueVariables.pending]: ColorHue.orange,
        [ColorHueVariables.red]: ColorHue.red,
        [ColorHueVariables.highlightingBg]: ColorHue.lightYellow,
        [ColorHueVariables.textPrimary]: ColorHue.black,
        [ColorHueVariables.bgSecondary]: ColorHue.white,
    },
    indigo: {
        [ColorHueVariables.primaryColor]: ColorHue.green,
        [ColorHueVariables.contrastBg]: ColorHue.green,
        [ColorHueVariables.accent]: ColorHue.mediumCyan,
        [ColorHueVariables.green]: ColorHue.lightYellow,
        [ColorHueVariables.pending]: ColorHue.mediumSeaGreen,
        [ColorHueVariables.red]: ColorHue.red,
        [ColorHueVariables.highlightingBg]: ColorHue.lightYellow,
        [ColorHueVariables.textPrimary]: ColorHue.white,
        [ColorHueVariables.bgSecondary]: ColorHue.green,
    },
    lacricia: {
        [ColorHueVariables.primaryColor]: ColorHue.mediumBlue,
        [ColorHueVariables.contrastBg]: ColorHue.mediumBlue,
        [ColorHueVariables.accent]: ColorHue.mediumSlateBlue,
        [ColorHueVariables.green]: ColorHue.lightYellow,
        [ColorHueVariables.pending]: ColorHue.mediumSeaGreen,
        [ColorHueVariables.red]: ColorHue.red,
        [ColorHueVariables.highlightingBg]: ColorHue.lightYellow,
        [ColorHueVariables.textPrimary]: ColorHue.blue,
        [ColorHueVariables.bgSecondary]: ColorHue.blue,
    },
};
