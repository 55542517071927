import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from '@emotion/styled';
import { ITheme } from '../../types';
import { faShieldCheck as faShieldCheckSolid } from '@fortawesome/pro-solid-svg-icons';
import { faShieldBlank as faShieldCheckOutline } from '@fortawesome/pro-regular-svg-icons';

const StyledIcon = styled(FontAwesomeIcon, {
    shouldForwardProp: prop => !['isVerified'].includes(prop),
})<{ isVerified?: boolean } & { theme?: ITheme }>`
    font-size: 1.2rem;
    margin: 0 10px;
    color: ${({ theme, isVerified }) =>
        isVerified ? theme.colors.successColor : theme.colors.textSecondary};
`;

const VerfiedShieldIcon = ({ isVerified }: { isVerified: boolean }) => {
    return (
        <StyledIcon
            icon={isVerified ? faShieldCheckSolid : faShieldCheckOutline}
            isVerified={isVerified}
        />
    );
};

export default VerfiedShieldIcon;
