import styled from '@emotion/styled';
import { Flex, FlexProps } from '@rebass/grid/emotion';

export interface IStackContainer extends FlexProps {
    gap?: number;
    grow?: boolean;
}

export const StackContainer = styled(Flex)<IStackContainer>`
    gap: ${({ gap }) => gap || 0}px;
    flex-grow: ${({ grow }) => (grow ? 1 : 0)};
`;
