import { makeVar, useReactiveVar } from '@apollo/client';
import { IThemeStore } from './types';
import { ThemePalette, ThemeMode } from './theme-mode';
import { useEffect } from 'react';

const THEME_KEY = 'theme-mode';
const storedTheme = localStorage.getItem(THEME_KEY);
// for some reason it does not support persist mode
// links: https://github.com/apollographql/apollo-cache-persist/issues/361
const theme = makeVar<IThemeStore>(
    storedTheme
        ? JSON.parse(storedTheme)
        : {
              mode: ThemeMode.light,
              theme: ThemePalette.default,
          }
);

const broadcastChannel = new BroadcastChannel('theme-sync');

export function useAppTheme() {
    const currentTheme = useReactiveVar(theme);

    const changeTheme = (value: IThemeStore) => {
        const newTheme = { ...currentTheme, ...value };
        theme(newTheme);
        localStorage.setItem(THEME_KEY, JSON.stringify(newTheme));
        broadcastChannel.postMessage(newTheme);
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            if (event.data) {
                theme(event.data);
            }
        };
        // to make sure theme/mode changes are reflected in all tabs
        broadcastChannel.addEventListener('message', handleMessage);

        return () => {
            broadcastChannel.removeEventListener('message', handleMessage);
        };
    }, []);

    return { theme: currentTheme, changeTheme };
}
