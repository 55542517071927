import Slider, { SliderProps } from './slider';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const ZoomButton = styled.button`
    background-color: transparent;
    border: none;
    margin: 0 10px;
    padding: 5px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        color: ${({ theme }) => theme.colors.primaryColor};
    }

    &:focus {
        outline: none;
    }
`;

export interface ZoomSliderProps extends SliderProps {}

function ZoomSlider({
    setValue,
    min = 1,
    step = 1,
    value = 2,
    max = 3,
}: ZoomSliderProps) {
    return (
        <Container>
            <ZoomButton onClick={() => setValue(Math.max(min, value - 1))}>
                <FontAwesomeIcon icon={faMinus} />
            </ZoomButton>
            <Slider
                max={max}
                min={min}
                setValue={setValue}
                step={step}
                value={value}
            />
            <ZoomButton onClick={() => setValue(Math.min(max, value + 1))}>
                <FontAwesomeIcon icon={faPlus} />
            </ZoomButton>
        </Container>
    );
}

export default ZoomSlider;
