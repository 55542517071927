import styled from '@emotion/styled';
import { faGripDotsVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledIcon = styled(FontAwesomeIcon)`
    color: ${({ theme }) => theme.colors.gray120};
    font-size: 20px;
`;

const DragIcon = props => <StyledIcon {...props} icon={faGripDotsVertical} />;

export default DragIcon;
