import PropTypes from 'prop-types';
import { formatDate } from '../../../../shared-utils/src/date-utils';

// TODO: Add localisation
export default function DisplayDate(props) {
    return <span>{props.children ? formatDate(props.children) : ''}</span>;
}

DisplayDate.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.string,
    ]),
};
