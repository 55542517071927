import { CellProps } from 'react-table';
import { TableCellRenderers as cellRenderers } from '../../cell-renderers';
import headerRenderers from '../../header-renderers';
export const COLUMN_WIDTH_SMALL = 90;
export const COLUMN_WIDTH_MEDIUM = 120;

export interface IColumn<T extends object> {
    accessor: string;
    Header: string;
    width?: number;
    maxWidth?: number;
    disableSort?: boolean;
    isEnum?: boolean;
    exportHeader?: string;
    style?: object;
    Cell?: (cell: CellProps<T>) => React.ReactNode | undefined;
    Footer?: any;
    isCentered?: boolean;
}

type IColumnCreatorArgs<T extends object> = Omit<
    IColumn<T>,
    'accessor' | 'Header'
> & {
    label?: string;
    headerText?: string;
};
type IResolverArgs<T extends object> = [string, IColumnCreatorArgs<T>];

interface IRadioArgs<T extends object> extends IColumnCreatorArgs<T> {
    onClick: (row: any) => void;
}
interface IImageArgs<T extends object> extends IColumnCreatorArgs<T> {
    height?: number;
}

interface ICheckboxArgs<T extends object> extends IColumnCreatorArgs<T> {
    getValue?: (row: any) => boolean;
    onClick: (row: any) => void;
}

interface ISettingsArgs<T extends object> extends IColumnCreatorArgs<T> {
    getOptions?: (cell: CellProps<T>) => any[];
}

interface ITextBadgeArgs<T extends object> extends IColumnCreatorArgs<T> {
    valueMapper?: (value: any) => string;
}
interface IMoneyArgs<T extends object> extends IColumnCreatorArgs<T> {
    suffix?: (row: { original: any }) => string;
}

interface IStickyOverview<T extends object> extends IColumnCreatorArgs<T> {
    getTextValue: (row: any) => React.ReactNode;
}

const createColumn = <T extends object>(
    accessor: string,
    { label, ...props }: IColumnCreatorArgs<T> = {}
) => {
    const column: IColumn<T> = {
        accessor,
        Header: label || ' ', // Converts to no header in table component
        ...props,
    };
    if (column.maxWidth) {
        column.width = column.maxWidth;
    }
    return column;
};

const columnResolvers = <T extends object>(cbFn = createColumn<T>) => ({
    default: cbFn,
    textBadge: (...args: [string, ITextBadgeArgs<T>]) => ({
        ...cbFn(...args),
        Cell: cellRenderers.textBadge,
    }),
    bool: (...args: IResolverArgs<T>) => ({
        maxWidth: COLUMN_WIDTH_SMALL,
        ...cbFn(...args),
        Cell: cellRenderers.bool,
    }),
    checkbox: (...args: [string, ICheckboxArgs<T>]) => ({
        maxWidth: COLUMN_WIDTH_SMALL,
        ...cbFn(...args),
        Cell: cellRenderers.checkbox,
    }),
    date: (...args: IResolverArgs<T>) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.date,
    }),
    datetime: (...args: IResolverArgs<T>) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.datetime,
    }),
    enum: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        isEnum: true,
        Cell: cellRenderers.enum,
    }),
    fieldsSeparatedByComma: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        Cell: cellRenderers.fieldsSeparatedByComma,
    }),
    file: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        Cell: cellRenderers.file,
    }),
    image: (...args: [string, IImageArgs<T>]) => ({
        ...cbFn(...args),
        disableSort: true,
        style: {
            maxWidth: '200px',
        },
        Cell: cellRenderers.image,
    }),
    profileImage: (...args: IResolverArgs<T>) => ({
        disableSort: true,
        width: 36,
        ...cbFn(...args),
        Cell: cellRenderers.profileImage,
    }),
    money: (...args: [string, IMoneyArgs<T>]) => ({
        maxWidth: COLUMN_WIDTH_MEDIUM,
        ...cbFn(...args),
        Cell: cellRenderers.money,
    }),
    personName: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        Cell: cellRenderers.personName,
    }),
    radio: (...args: [string, IRadioArgs<T>]) => ({
        maxWidth: COLUMN_WIDTH_SMALL,
        ...cbFn(...args),
        Cell: cellRenderers.radio,
    }),
    settings: (...args: [string, ISettingsArgs<T>]) => ({
        width: 65,
        disableSort: true,
        ...cbFn(...args),
        Cell: cellRenderers.settings,
    }),
    switch: (...args: IResolverArgs<T>) => ({
        maxWidth: 60,
        disableSort: true,
        ...cbFn(...args),
        Cell: cellRenderers.switch,
    }),
    stickyOverview: (...args: [string, IStickyOverview<T>]) => ({
        width: 300,
        exportHeader: args[1].headerText,
        sticky: 'left',
        ...cbFn(...args),
        Header: headerRenderers.stickyOverview,
        Cell: cellRenderers.stickyOverview,
    }),
    translated: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        Cell: cellRenderers.translated,
    }),
    verified: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        Cell: cellRenderers.verified,
    }),
    objectTypeIcon: (...args: IResolverArgs<T>) => ({
        ...cbFn(...args),
        maxWidth: 60,
        Cell: cellRenderers.objectTypeIcon,
    }),
});

type ColumnnCreatorProcessor<T extends object> = (
    col: IColumn<T>,
    accessor: string
) => IColumn<T>;

export default function columnCreator<T extends object>(
    processors: ColumnnCreatorProcessor<T>[] = []
) {
    return columnResolvers<T>((accessor, props) =>
        processors.reduce(
            (col, processor) => processor(col, accessor),
            createColumn<T>(accessor, props)
        )
    );
}

export const columnSizes = {
    small: <T extends object>(col: IColumn<T>) => ({
        ...col,
        maxWidth: COLUMN_WIDTH_SMALL,
    }),
    medium: <T extends object>(col: IColumn<T>) => ({
        ...col,
        maxWidth: COLUMN_WIDTH_MEDIUM,
    }),
};
