import { css } from '@emotion/react';
import { TEXT_STYLES } from '../typograph/typograph-components';
import { SubTableRowWrapper } from './table-wrapper/sub-table/sub-table-row-component';
export default theme => css`
    display: flex;
    position: relative;
    flex-direction: column;

    * {
        box-sizing: border-box;
    }

    .rt-table {
        background: ${theme.colors.bgSecondary};
        flex: auto 1;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        border: 2px solid ${theme.colors.borderPrimary};
        border-radius: ${theme.borderRadius.default};
        overflow: hidden;
    }
    .rt-table.--clickable-rows {
        .rt-tbody {
            .rt-tr {
                cursor: pointer;

                &:hover {
                    .rt-td {
                        background-color: ${theme.colors.primaryColor5};
                    }
                }
            }
        }
    }

    .rt-thead {
        user-select: none;
        position: sticky;
        top: 0px;
        z-index: 1;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow: auto;
        min-height: 40px;

        ::-webkit-scrollbar {
            display: none;
        }

        .rt-th,
        .rt-td {
            padding: 5px 10px;
            line-height: normal;
            position: relative;
            transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            font-weight: 400;

            &:last-child {
                border-right: 0;
            }
        }

        .rt-td {
            box-shadow: inset 0 0 0 0 transparent;
        }

        .rt-th {
            ${TEXT_STYLES.helpingTextMedium(theme)};
            height: 40px;
            font-weight: 500;
            background-color: ${theme.colors.bgSecondary};
            color: ${theme.colors.textSecondary};
            span {
                font-weight: normal;
            }
            border-bottom: 1px solid ${theme.colors.borderSecondary};
            padding: 3px 10px;
            line-height: 1.3;
        }

        .rt-th.--sortable {
            cursor: pointer;
            &.--is-sorted {
                color: ${theme.colors.textPrimary};
                > svg {
                    color: ${theme.colors.textPrimary};
                }
            }
            &:hover {
                color: ${theme.colors.textPrimary};
                > svg {
                    color: ${theme.colors.textPrimary};
                }
            }
        }
    }

    .rt-scrollable {
        overflow: auto;
    }

    .rt-table.--is-overflowing-x {
        .rt-tbody,
        .rt-thead {
            [data-sticky-td] {
                background: ${theme.colors.bgPrimary};
            }
            [data-sticky-last-left-td] {
                box-shadow: 5px 4px 10px -5px rgba(204, 204, 204, 0.7);
                border-right: 0.5px solid ${theme.colors.borderSecondary};
                max-width: 45vw;
            }

            [data-sticky-first-right-td] {
                box-shadow: 5px 4px 10px -5px rgba(204, 204, 204, 0.7);
                border-right: 0.5px solid ${theme.colors.borderSecondary};
                max-width: 45vw;
            }
        }
    }
    @media (min-width: ${theme.responsiveBreakpoints.s}) {
        .rt-table.--is-overflowing-y {
            .rt-thead {
                margin-right: 10px; // to create space for scrollbar, to keep scroll sync
            }
        }
    }

    .rt-table.--is-filling-body {
        .rt-tr-group:last-of-type {
            border-color: transparent;
            .rt-td {
                border-color: transparent; // need the height, but not the border
            }
        }
    }

    .rt-tbody {
        flex: 99999 1 auto;
        display: flex;
        flex-direction: column;
        overflow: auto;
        background: ${theme.colors.bgPrimary};
        @media (min-width: ${theme.responsiveBreakpoints.xl}) {
            * {
                scrollbar-width: auto;
            }
        }

        .rt-tr-group {
            border-bottom: solid 1px rgba(0, 0, 0, 0.05);
            .rt-full {
                width: 100%;
                justify-content: center;
            }
        }

        .rt-tr {
            .rt-td {
                border-bottom: 1px solid ${theme.colors.borderSecondary};
                min-height: 46px;

                &-content {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                }
            }
        }

        .rt-td > div {
            width: 100%;
        }

        .rt-td:last-child {
            border-right: 0;
        }
        position: relative;
    }

    .rt-tr {
        display: flex;
        flex: 1 0 auto;
        height: max-content;
        background-color: ${theme.colors.bgPrimary};

        &.-odd {
            background-color: ${theme.colors.bgPrimary};
        }
    }
    .rt-tr.rt-rt-selected .rt-td {
        background-color: ${theme.colors.primaryColor5};
    }
    .rt-th,
    .rt-td {
        ${'' /* Align cell contents to the middle */}
        display: flex;
        align-items: center;
        justify-content: flex-start;

        padding: 5px 10px;
        transition: 0.3s ease;
        transition-property: width, min-width, padding, opacity;
    }
    .rt-td > span {
        padding-left: 3px;
        width: 100%;
    }

    .rt-tfoot {
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${theme.colors.borderPrimary};
        .rt-th {
            padding: 1rem 0.75rem;
        }
        .rt-td:last-child {
            border-right: 0;
        }
    }

    .-pagination {
        z-index: 1;

        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
        padding: 3px;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        input,
        select {
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #fff;
            padding: 5px 7px;
            font-size: inherit;
            border-radius: 3px;
            font-weight: normal;
        }
        .-btn {
            appearance: none;
            display: block;
            width: 100%;
            height: 100%;
            border: 0;
            border-radius: 3px;
            padding: 6px;
            font-size: 1em;
            color: rgba(0, 0, 0, 0.6);
            background: rgba(0, 0, 0, 0.1);
            transition: all 0.1s ease;
            cursor: pointer;
        }
        .-btn[disabled] {
            opacity: 0.5;
            cursor: default;
        }
        .-btn:not([disabled]):hover {
            background: rgba(0, 0, 0, 0.3);
            color: #fff;
        }
        .-previous,
        .-next {
            flex: 1;
            text-align: center;
        }
        .-center {
            flex: 1.5;
            text-align: center;
            margin-bottom: 0;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-around;
        }
        .-pageInfo {
            display: inline-block;
            margin: 3px 10px;
            white-space: nowrap;
        }
        .-pageJump {
            display: inline-block;
        }
        .-pageJump input {
            width: 70px;
            text-align: center;
        }
        .-pageSizeOptions {
            margin: 3px 10px;
        }
    }
    .rt-noData {
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
        z-index: 1;
        pointer-events: none;
        padding: 20px;
        color: rgba(0, 0, 0, 0.5);
    }
    .-loading {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(255, 255, 255, 0.8);
        transition: all 0.3s ease;
        z-index: -1;
        opacity: 0;
        pointer-events: none;

        > div {
            position: absolute;
            display: block;
            text-align: center;
            width: 100%;
            top: 50%;
            left: 0;
            font-size: 15px;
            color: rgba(0, 0, 0, 0.6);
            transform: translateY(-52%);
            transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        }
    }
    .-loading.-active {
        opacity: 1;
        z-index: 2;
        pointer-events: all;
    }
    .-loading.-active > div {
        transform: translateY(50%);
    }
    .break-word {
        word-break: break-word;
    }
    .hidden {
        display: none;
    }
`;

export const borderlessNestedTableRowsStyle = theme => css`
    .rt-table.--has-nested-data {
        .rt-tbody {
            .rt-tr-group:has(${SubTableRowWrapper}) {
                .rt-tr .rt-td {
                    border-color: transparent;
                }

                &:not(:last-of-type) {
                    // Target only last row of the nested table to have border and not if table group is last
                    > div:last-of-type > div:last-of-type .rt-tr .rt-td {
                        border-color: ${theme.colors.borderSecondary};
                    }
                }
            }
            .rt-tr-group:last-of-type {
                border-bottom: none;
                .rt-tr .rt-td {
                    border-color: transparent;
                }
            }
        }
    }
`;
