import { RadioButton } from '../../input';

interface IRadioCellRendererProps {
    column: {
        getValue: (row: object) => boolean;
        onClick: (row: object) => void;
        isReadOnly: (row: object) => boolean;
    };
    row: object;
    value: boolean;
}
const RadioCellRenderer = ({ column, row, value }: IRadioCellRendererProps) => {
    const checked = column?.getValue?.(row) ?? value;

    return (
        <RadioButton
            checked={checked}
            onChange={() => column?.onClick?.(row)}
            disabled={column?.isReadOnly?.(row) ?? false}
            handleClick={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.stopPropagation()
            }
        />
    );
};

export default RadioCellRenderer;
