import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button, { BUTTON_TYPES } from './button-component';

// Styled component for IconButtonToolbar
const IconButtonToolbarContainer = styled(Button)`
    background: ${({ theme }) => theme.colors.white};
    border: none;
    width: 34px;
    height: 34px;
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    padding: 0;
    color: ${({ theme }) => theme.colors.textPrimary};
    border: 2px solid transparent;

    svg {
        height: 20px;
        aspect-ratio: 1/1;
    }

    &:active,
    :focus {
        background: ${({ theme }) => theme.colors.primaryColor20};
    }

    &:active:focus {
        border: 2px solid ${({ theme }) => theme.colors.primaryColor20};
    }
`;

export const IconButtonToolbar = ({ children, ...rest }) => {
    return (
        <IconButtonToolbarContainer
            {...rest}
            buttonType={BUTTON_TYPES.PRIMARY_LIGHT}
        >
            {children}
        </IconButtonToolbarContainer>
    );
};

IconButtonToolbar.propTypes = {
    children: PropTypes.node,
};
