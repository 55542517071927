import React from 'react';

// could not find match in fontawesome, so using svg from design
function RotateIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18.2718 8.41458C17.1669 7.30967 15.7481 6.70447 14.3017 6.59901L14.3042 6.60152V8.65063C14.3017 8.98461 14.1711 9.30102 13.935 9.53707C13.4454 10.0267 12.6518 10.0267 12.1622 9.53707L9.23164 6.60654C8.89765 6.27255 8.89765 5.73517 9.23164 5.40118L12.1622 2.47065C12.6518 1.98097 13.4454 1.98097 13.935 2.47065C14.1711 2.7067 14.3042 3.02562 14.3042 3.35709L14.3017 4.89141C16.1851 4.99688 18.0383 5.77032 19.4772 7.20922C22.5835 10.3155 22.5835 15.3529 19.4772 18.4592C17.1148 20.8216 13.6356 21.3874 10.7383 20.1566L12.1667 18.8541C14.2691 19.4407 16.6184 18.9073 18.2718 17.2539C20.7127 14.813 20.7127 10.8554 18.2718 8.41458ZM10.2589 17.9497C9.96875 17.7453 9.69207 17.5134 9.43253 17.2539C8.4356 16.2569 7.84799 15.0114 7.66467 13.7182C7.59938 13.2511 7.16746 12.9297 6.70038 12.9949C6.23331 13.0602 5.91188 13.4922 5.97717 13.9592C6.21071 15.604 6.96154 17.1936 8.22717 18.4592C8.49053 18.7226 8.76777 18.9636 9.05653 19.1823L10.2589 17.9497ZM12.5991 7.56329L12.5991 4.44443L11.0397 6.00386L12.5991 7.56329Z"
                fill="currentColor"
            />
            <path
                d="M7.22959 8.82244C7.61348 8.42539 8.23589 8.42539 8.61977 8.82244L13.1542 13.5123C13.5381 13.9094 13.5381 14.5531 13.1542 14.9502L8.61977 19.6401C8.23589 20.0371 7.61348 20.0371 7.22959 19.6401L2.69514 14.9502C2.31125 14.5531 2.31125 13.9094 2.69514 13.5123L7.22959 8.82244Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.92468 10.6197L4.43287 14.2313L7.92468 17.8428L11.4165 14.2313L7.92468 10.6197ZM8.61977 8.82244C8.23589 8.42539 7.61348 8.42539 7.22959 8.82244L2.69514 13.5123C2.31125 13.9094 2.31125 14.5531 2.69514 14.9502L7.22959 19.6401C7.61348 20.0371 8.23589 20.0371 8.61977 19.6401L13.1542 14.9502C13.5381 14.5531 13.5381 13.9094 13.1542 13.5123L8.61977 8.82244Z"
                fill="currentColor"
            />
        </svg>
    );
}

export default RotateIcon;
