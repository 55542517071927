import {
    and,
    formatIs,
    isStringControl,
    optionIs,
    or,
    rankWith,
} from '@jsonforms/core';
import { DatePicker } from '../../../input';
import { getCellProps, withInputControlHoc } from './cell-utils';

function DatetimeCell(props: ICellRendererProps) {
    const { path, handleChange } = props;

    const cellProps = getCellProps(props);
    const baseProps = {
        ...cellProps,
        onChange: (value: string) => {
            handleChange(path, value);
        },
        stackedLabel: true,
        schemaFormat: 'datetime',
        dateFormat: 'yyyy-MM-dd HH:mm',
        validationFun: false, // Disable validation for now as validator from shared-utils is throwing error
    };

    return <DatePicker {...baseProps} />;
}

export const tester = rankWith(
    3,
    and(
        isStringControl,
        or(formatIs('date-time'), optionIs('format', 'date-time'))
    )
);

export default { renderer: withInputControlHoc(DatetimeCell), tester };
