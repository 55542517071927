import styled from '@emotion/styled';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { faTrash, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@mspecs/shared-utils';
import { LoadingBar } from '../../../animations';
import { AnimationLine } from '../../../animations/loading-bar-component';
import { BUTTON_TYPES, IconButton } from '../../../button';
import { BodyTextRegular } from '../../../typograph/typograph-components';
import { FileUploadPropType } from './multi-file-upload-prop-types';

const FileItem = styled(Flex)`
    flex-direction: column;
    padding-top: ${({ theme }) => theme.spacing.default};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.borderPrimary}`};
`;
const FileInfo = styled(Flex)`
    align-items: center;
    gap: ${({ theme }) => theme.spacing.default};
`;
const FileImageWrapper = styled.div`
    display: flex;
    min-width: 40px;
    justify-content: center;
`;
const FileImage = styled.img`
    border-radius: ${({ theme }) => theme.borderRadius.default};
    max-width: 40px;
    max-height: 34px;
`;
const FileName = styled(BodyTextRegular)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;

}`;

const FileProgess = styled(LoadingBar)`
    padding: ${({ theme }) => theme.spacing.small} 0px;

    ${AnimationLine} {
        width: 100%;
        border-radius: ${({ theme }) => theme.borderRadius.default};
    }
`;

const RemoveButton = styled(IconButton)`
    margin-left: auto;
    color: ${({ theme }) => theme.colors.textPrimary};
`;

const ErrorContainer = styled(Flex)`
    align-items: center;
`;
const ErrorIconWrapper = styled(Flex)`
    width: 34px;
    justify-content: center;
`;
const ErrorIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.errorColor};
`;
const ErrorText = styled(BodyTextRegular)`
    color: ${({ theme }) => theme.colors.textSecondary};
    font-style: italic;
    white-space: nowrap;
`;

const MultiFileUploadListItem = ({ file, onRemove }) => {
    const ref = useRef();
    const { t } = useTranslate();

    useEffect(() => {
        if (file.isLoading && ref?.current) {
            ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
            });
        }
    }, [file.isLoading, ref]);

    const isIdle = !file.isLoading && !file.isError && !file.isSuccess;

    return (
        <FileItem key={file.identifier} ref={ref}>
            <FileInfo>
                <FileImageWrapper>
                    <FileImage
                        src={file.objectUrl}
                        height="34"
                        alt={file.name}
                    />
                </FileImageWrapper>
                <FileName>{file.name}</FileName>
                {file.isError && (
                    <ErrorContainer>
                        <ErrorText>{t('IMAGE_UPLOAD_GENERIC_ERROR')}</ErrorText>
                        <ErrorIconWrapper>
                            <ErrorIcon icon={faExclamationCircle} />
                        </ErrorIconWrapper>
                    </ErrorContainer>
                )}
                {!file.isError && (
                    <RemoveButton
                        hiddenLabel="REMOVE"
                        icon={file.isLoading || isIdle ? faXmark : faTrash}
                        onClick={onRemove}
                        buttonType={BUTTON_TYPES.PRIMARY_TRANSPARENT}
                    />
                )}
            </FileInfo>
            <FileProgess
                idle={!file.isLoading}
                completed={!file.isLoading && (file.isError || file.isSuccess)}
                error={file.isError}
            />
        </FileItem>
    );
};

MultiFileUploadListItem.propTypes = {
    file: FileUploadPropType.isRequired,
    onRemove: PropTypes.func.isRequired,
};

export default MultiFileUploadListItem;
