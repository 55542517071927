import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { Flex } from '@rebass/grid/emotion';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { compose, branch } from 'recompose';
import IconButton from '../../button/icon-button/icon-button-component';
import { InputField } from '.';
import { withFinalFormField } from '../../../utils/hoc';

const StyledIconButton = styled(IconButton)`
    margin-left: 2px;
    position: absolute;
    top: ${({ hasLabel }) => (hasLabel ? 30 : 0)}px;
    right: -50px;
`;

const SecretValueInput = ({ name, value, label, ...rest }) => {
    const [showValue, setShowValue] = useState(false);

    return (
        <>
            <Flex flexDirection={'row'} width={1}>
                <InputField
                    label={label}
                    name={name}
                    value={value}
                    {...rest}
                    type={showValue ? 'text' : 'password'}
                />
                <StyledIconButton
                    onClick={() => {
                        setShowValue(!showValue);
                    }}
                    icon={showValue ? faEyeSlash : faEye}
                    title="SHOW_VALUE"
                    hiddenLabel="SHOW_VALUE"
                    disabled={!value}
                    hasLabel={label}
                />
            </Flex>
        </>
    );
};

SecretValueInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
};

export default compose(
    branch(({ isFormField }) => isFormField, withFinalFormField)
)(SecretValueInput);

// export default SecretValueInput;
