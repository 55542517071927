import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HiddenContent from '../../styling/hidden-content-component';
import { BUTTON_TYPES } from '../button-component';
import { translate } from '@mspecs/shared-utils';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import {
    StyledIconButton as StyledIconButtonExtend,
    IconLabelText,
} from './icon-button-styled';
import { ButtonSize } from '../button-size';
import { IconButtonMode } from './icon-button-types';
import { StyledIconButton } from './icon-button-legacy-styled';

export interface IIconButton {
    hiddenLabel?: string;
    title?: string;
    label?: string;
    className?: string;
    icon?: IconProp | any;
    iconSize?: SizeProp;
    // Legacy support
    small?: boolean;
    large?: boolean;
    // New size
    size?: ButtonSize;
    reverse?: boolean;
    isRound?: boolean;
    type?: string;
    onClick?: () => void;
    style?: React.CSSProperties;
    buttonType?: (typeof BUTTON_TYPES)[keyof typeof BUTTON_TYPES];
    [key: string]: any;
}

interface IIconButtonOverridden extends IIconButton {
    t: (text: string) => string;
}

const IconButton = ({
    icon = null,
    isRound,
    type = 'button',
    hiddenLabel,
    title = '',
    style,
    buttonType,
    iconSize,
    size = ButtonSize.Medium,
    reverse,
    label,
    t,
    ...rest
}: IIconButtonOverridden) => {
    const isFontAwesome = icon?.icon && icon?.prefix;
    const { large, small } = rest;
    size = small ? ButtonSize.Small : large ? ButtonSize.Large : size;
    const mode = label
        ? IconButtonMode.rectangel
        : isRound
        ? IconButtonMode.rounded
        : IconButtonMode.square;

    const labelRender = label && <IconLabelText>{t(label)}</IconLabelText>;
    return (
        <StyledIconButtonExtend
            style={style}
            title={t(title)}
            type={type}
            buttonType={
                buttonType !== undefined
                    ? buttonType
                    : BUTTON_TYPES.PRIMARY_LIGHT
            }
            isRound={isRound}
            mode={mode}
            size={size}
            data-type="icon-button"
            {...rest}
        >
            {reverse && labelRender}
            {isFontAwesome ? (
                <FontAwesomeIcon icon={icon} size={iconSize} />
            ) : (
                icon
            )}
            {hiddenLabel && <HiddenContent>{t(hiddenLabel)}</HiddenContent>}
            {!reverse && labelRender}
        </StyledIconButtonExtend>
    );
};

// legacy support
export { StyledIconButton };
export default translate(IconButton) as React.ComponentType<IIconButton>;
