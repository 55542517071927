import styled from '@emotion/styled';
import { IconButton } from '../../../button';

export const IconButtonTolBar = styled(IconButton)`
    color: ${({ theme }) => theme.colors.textPrimary};

    :hover,
    :focus {
        background: ${({ theme }) => theme.colors.primaryColor10};
        border-radius: ${({ theme }) => theme.borderRadius.medium};
    }
`;
